import * as React from 'react';
export const DynamicSearchMapContext = React.createContext({
  setHighlightedPropertyId: () => undefined
});
export const useDynamicSearchMapContext = () => React.useContext(DynamicSearchMapContext);
export const DynamicSearchMapContextProvider = ({
  children
}) => {
  const [highlightedPropertyId, setHighlightedPropertyIdInternal] = React.useState();
  const setHighlightedPropertyId = React.useCallback(id => {
    setHighlightedPropertyIdInternal(id);
  }, []);
  return React.createElement(DynamicSearchMapContext.Provider, {
    value: {
      highlightedPropertyId,
      setHighlightedPropertyId
    }
  }, children);
};