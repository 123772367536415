import { Listings } from '__generated__/api';
import { object, string } from 'valibot';
// Display ads have arbitrary breakpoints that do not align with bernie
export const DisplayAd = {
  SIDERAIL_R1: {
    uci: 'R1'
  },
  SIDERAIL_R2: {
    uci: 'R2',
    className: 'sticky-top__with-navbar'
  },
  SIDERAIL_TEST_R2: {
    uci: 'R2_TEST',
    className: 'sticky-top__with-navbar'
  },
  SIDERAIL_CONTROL_R2: {
    uci: 'R2_CONTROL',
    className: 'sticky-top__with-navbar'
  },
  FOOTER_CM6: {
    uci: 'CM6'
  },
  FOOTER_CM4: {
    uci: 'CM4'
  },
  FOOTER_CM3: {
    uci: 'CM3'
  },
  FOOTER_CM2: {
    uci: 'CM2'
  },
  FOOTER_CM1: {
    uci: 'CM1'
  }
};
export const MesoEventDetailSchema = object({
  code: string(),
  uci: string()
});
export const ADS_ONLY_SELECTION = [{
  id: 'listings',
  value: Listings.ADS_ONLY
}];