import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { MapPreview } from 'src/common/components/search/map/map-preview';
import { useViewTrackerContext } from 'src/common/stores/contexts/viewTracker';
import { isMobile } from 'src/common/utils/is-mobile';
import { useClickstreamAnalytics } from '@shared-ui/clickstream-analytics-context';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { SortAndFiltersViewType } from '@shared-ui/retail-sort-and-filter-lodging';
import { ViewExtraLarge, ViewLarge, ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { Filterbar } from './filterbar';
import { ResultsSubHeader } from './results-sub-header/results-sub-header';
import { ShoppingSearchListingHeaderWrapper } from './shopping-search-listing-header-wrapper';
import { UniversalSortFilterWrapper } from './sort-filter/universal-sort-filter';
export const ResultsHeader = inject('searchResults', 'multiItem', 'context')(observer(({
  searchResults,
  withMapButton,
  withFilterButton,
  multiItem,
  context
}) => {
  const l10n = useLocalization();
  const isPackageShoppingPath = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  const {
    exposure: xLOBLodgingMWebRetestExposure,
    logExperiment: logXLOBLodgingMWebRetest
  } = useExperiment('Quick_Filters_xLOB_Lodging_mWeb_Retest');
  if (Boolean(xLOBLodgingMWebRetestExposure) && isMobile(context)) {
    logXLOBLodgingMWebRetest(xLOBLodgingMWebRetestExposure);
  }
  if (!searchResults) {
    return null;
  }
  const searchResultsHeaderWrapperRef = React.useRef(null);
  const {
    isLoading,
    loyaltyMessagingModel,
    resultMessages,
    shoppingSearchListingHeader = []
  } = searchResults;
  const spacingOptions = shoppingSearchListingHeader.length > 0 ? {
    margin: {
      blockstart: 'three'
    }
  } : {};
  const resultsSubHeaderProps = {
    resultMessages,
    isLoading,
    l10n,
    loyaltyMessagingModel,
    spacingOptions
  };
  const buttonsProps = {
    dialogTitle: l10n.formatText('hotels.searchResults.filtersDialog.title'),
    withMapButton,
    withFilterButton,
    isPackageShoppingPath
  };
  const headerComponent = React.createElement(React.Fragment, null, React.createElement(ButtonsParent, Object.assign({}, buttonsProps)), React.createElement(UniversalSortFilterWrapper, {
    view: SortAndFiltersViewType.APPLIED_FILTERS
  }), React.createElement(ShoppingSearchListingHeaderWrapper, null), React.createElement(ResultsSubHeader, Object.assign({}, resultsSubHeaderProps, {
    isQuickAccessFiltersActive: false
  })));
  const headerComponentWithFilterBar = React.createElement(React.Fragment, null, React.createElement(Filterbar, {
    templateComponent: {
      config: {
        view: SortAndFiltersViewType.FILTER_BAR,
        bexQuickFiltersEnabled: true,
        filterBarSpacing: {
          padding: {
            small: {
              blockend: 'four'
            }
          }
        }
      }
    }
  }), React.createElement(ShoppingSearchListingHeaderWrapper, null), React.createElement(ResultsSubHeader, Object.assign({}, resultsSubHeaderProps, {
    isQuickAccessFiltersActive: true
  })));
  return React.createElement(ErrorBoundary, {
    label: "hotel.search.tools"
  }, React.createElement(EGDSSpacing, {
    margin: {
      blockstart: 'one'
    }
  }, React.createElement("section", {
    "data-stid": "results-header",
    ref: searchResultsHeaderWrapperRef
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(Experiment, {
    name: "Quick_Filters_xLOB_Lodging_mWeb_Retest",
    logExposure: false
  }, React.createElement(ExperimentControl, null, headerComponent), React.createElement(ExperimentVariant, {
    bucket: 1
  }, isPackageShoppingPath ? headerComponentWithFilterBar : React.createElement(React.Fragment, null, React.createElement(ShoppingSearchListingHeaderWrapper, null), React.createElement(ResultsSubHeader, Object.assign({}, resultsSubHeaderProps, {
    isQuickAccessFiltersActive: true
  })))))), React.createElement(ViewMedium, null, headerComponent)))));
}));
export const Buttons = ({
  withFilterButton,
  withMapButton,
  isPackageShoppingPath
}) => {
  const viewTracker = useViewTrackerContext();
  const tracker = useClickstreamAnalytics();
  const {
    exposure: priceSliderExperimentExposure,
    logExperiment: logPriceSliderExperiment
  } = useExperiment('Improve_Price_Slider_on_mweb');
  const {
    exposure: filterRemovalExperimentExposure,
    logExperiment: logFilterRemovalExperiment
  } = useExperiment('Filter_Removal_at_destination_level_based_on_supply_mweb_apps');
  const logExperimentExposures = () => {
    logPriceSliderExperiment(priceSliderExperimentExposure);
    if (!isPackageShoppingPath) {
      logFilterRemovalExperiment(filterRemovalExperimentExposure);
    }
  };
  return React.createElement(EGDSSpacing, {
    "data-stid": "tools__actions"
  }, React.createElement(EGDSLayoutFlex, null, withFilterButton && React.createElement(EGDSLayoutFlexItem, {
    grow: 1,
    shrink: 1,
    minWidth: '0x',
    "data-stid": "filters-toggle-button"
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'three'
    }
  }, React.createElement("div", null, React.createElement(EGDSLayoutFlex, null, React.createElement(EGDSLayoutFlexItem, {
    grow: 2
  }, React.createElement(UniversalSortFilterWrapper, {
    view: SortAndFiltersViewType.SORT_AND_FILTERS,
    onOpenDialog: logExperimentExposures
  })))))), withMapButton && React.createElement(EGDSLayoutFlexItem, {
    grow: 1,
    shrink: 1,
    minWidth: '0x',
    "data-stid": "map-preview-button"
  }, React.createElement(EGDSSpacing, {
    margin: {
      inline: 'one'
    }
  }, React.createElement("div", null, React.createElement(MapPreview, {
    viewTracker: viewTracker,
    tracker: tracker
  }))))));
};
const ButtonsParent = ({
  dialogTitle,
  withFilterButton,
  withMapButton,
  isPackageShoppingPath
}) => React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(Buttons, {
  dialogTitle: dialogTitle,
  withFilterButton: true,
  withMapButton: true,
  isPackageShoppingPath: isPackageShoppingPath
})), React.createElement(ViewLarge, null, React.createElement(React.Fragment, null)), React.createElement(ViewExtraLarge, null, React.createElement(Buttons, {
  dialogTitle: dialogTitle,
  withMapButton: withMapButton,
  withFilterButton: withFilterButton,
  isPackageShoppingPath: isPackageShoppingPath
})));