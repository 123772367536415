import { createElement, Fragment } from 'react';
export const MultiContextProvider = ({
  contextPairs,
  children
}) => {
  if (!contextPairs || !Array.isArray(contextPairs) || !contextPairs[0]) {
    return createElement(Fragment, null, children);
  }
  const {
    context,
    value
  } = contextPairs[0];
  return createElement(context.Provider, {
    value
  }, createElement(MultiContextProvider, {
    contextPairs: contextPairs.slice(1)
  }, children));
};
MultiContextProvider.displayName = 'MultiContextProvider';