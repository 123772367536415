var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { Layer } from 'bernie-view';
import { DayName, EGDSNewDatePicker } from '@egds/react-core/date-picker';
import { withLocalization } from '@shared-ui/localization-context';
import { onClickScrollToTarget } from 'utils/smooth-scroll';
// @ts-ignore
let NewDateField = class NewDateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      footerText: undefined,
      showDatePicker: false
    };
    this.datePickerAnalytics = {};
    this.focusButton = React.createRef();
    this.focusDateFieldInput = this.focusDateFieldInput.bind(this);
    this.onMount = this.onMount.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.open = this.open.bind(this);
    this.save = this.save.bind(this);
  }
  componentDidMount() {
    const {
      registerFocusMethod,
      uiPage
    } = this.props;
    registerFocusMethod === null || registerFocusMethod === void 0 ? void 0 : registerFocusMethod('endDate', this.focusDateFieldInput);
    this.setState({
      mounted: true
    });
    this.disposerUiPageOpenDatePickerEvent = reaction(() => uiPage === null || uiPage === void 0 ? void 0 : uiPage.openNewDatePickerEvent, openNewDatePickerEvent => {
      if (openNewDatePickerEvent) {
        this.setState({
          showDatePicker: true
        });
      }
    }, {
      delay: 200
    });
  }
  componentWillUnmount() {
    if (this.disposerUiPageOpenDatePickerEvent) {
      this.disposerUiPageOpenDatePickerEvent();
    }
  }
  open(dateType, isMobile) {
    const {
      history,
      location
    } = this.props;
    if (isMobile && history && location) {
      updateSearch({
        history,
        location,
        newParams: {
          pwaDialog: `datepicker-${dateType}`
        }
      });
    } else {
      onClickScrollToTarget({
        scrollTo: 0
      });
    }
    this.setState({
      showDatePicker: true
    });
  }
  save(start, end, isMobile) {
    const {
      endDate,
      l10n,
      startDate,
      updateModels
    } = this.props;
    const {
      formatDate
    } = l10n;
    const formattedEnd = formatDate(end, {
      raw: 'yyyy-MM-dd'
    });
    const formattedStart = formatDate(start, {
      raw: 'yyyy-MM-dd'
    });
    // prevents submitting the datepicker when no new dates are selected
    if (endDate !== formattedEnd || startDate !== formattedStart) {
      updateModels(formattedStart, formattedEnd);
    }
    this.dismiss(isMobile, false);
  }
  focusDateFieldInput() {
    const {
      dateFieldProps
    } = this.props;
    const {
      endInvalid,
      startInvalid
    } = dateFieldProps;
    if (startInvalid) {
      this.focusButton = this.startRef && this.startRef.current;
    }
    if (endInvalid) {
      this.focusButton = this.endRef && this.endRef.current;
    }
    // manage focus back to the trigger
    if (this.focusButton && typeof this.focusButton.focus === 'function') {
      this.focusButton.focus();
    }
  }
  dismiss(isMobile, noUpdate) {
    var _a;
    if (isMobile) {
      (_a = this.props.history) === null || _a === void 0 ? void 0 : _a.goBack();
    }
    if (!noUpdate) {
      this.setState({
        showDatePicker: false
      });
    }
  }
  onMount(start, end) {
    this.startRef = start;
    this.endRef = end;
  }
  render() {
    const {
      analytics,
      buttonText,
      context,
      dateFieldProps,
      endDate,
      midnightBookingOffsetHours,
      numDaysBookableInAdvance,
      startDate,
      l10n
    } = this.props;
    const {
      mounted,
      showDatePicker
    } = this.state;
    const firstDayOfWeek = parseInt(DayName[l10n.getWeekData().firstDay.toUpperCase()], 10);
    this.datePickerAnalytics = {
      closeAnalytics: analytics.setupTracker(':ChangeDatesClose', 'Close Date Picker', null),
      doneAnalytics: analytics.setupTracker(':ChangeDatesDone', 'Apply Date Picker', null),
      nextPageAnalytics: analytics.setupTracker(':NextMonthClick', 'Next Month Click', null),
      previousPageAnalytics: analytics.setupTracker(':PreviousMonthClick', 'Previous Month Click', null)
    };
    let enhancedDateFieldProps = Object.assign(Object.assign({}, dateFieldProps), {
      showDatePicker,
      endAnalytics: analytics.setupTracker(':ChangeDatesCheckOut', 'Open Date Picker'),
      startAnalytics: analytics.setupTracker(':ChangeDatesCheckIn', 'Open Date Picker')
    });
    if (!mounted) {
      enhancedDateFieldProps = Object.assign(Object.assign({}, enhancedDateFieldProps), {
        endPlaceholder: context === null || context === void 0 ? void 0 : context.localDateFormat,
        startPlaceholder: context === null || context === void 0 ? void 0 : context.localDateFormat
      });
    }
    const dialogWrapper = (children, dateType) => React.createElement(QueryRoute, {
      query: {
        pwaDialog: `datepicker-${dateType}`
      }
    }, React.createElement(Layer, {
      id: `home-datepicker-${dateType}`,
      onDismiss: this.dismiss
    }, children));
    const datePickerProps = {
      analytics: this.datePickerAnalytics,
      buttonText,
      dateFieldProps: enhancedDateFieldProps,
      daysBookableInAdvance: numDaysBookableInAdvance,
      dialogWrapper,
      doubleMonths: true,
      endDate,
      footerText: this.state.footerText,
      midnightBookingOffset: midnightBookingOffsetHours,
      onDismissCB: this.dismiss,
      onMountCB: this.onMount,
      onOpenCB: this.open,
      onSubmitCB: this.save,
      startDate,
      firstDayOfWeek
    };
    return React.createElement(EGDSNewDatePicker, Object.assign({}, datePickerProps));
  }
};
NewDateField.defaultProps = {
  endDate: '',
  startDate: '',
  l10n: null
};
NewDateField = __decorate([withRouter
// @ts-ignore
, withLocalization, inject('analytics', 'context', 'currentHotel', 'uiPage'), observer], NewDateField);
export { NewDateField };