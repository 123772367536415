import * as React from 'react';
import { EGDSCheckbox } from '@egds/react-core/checkbox';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { useLocalization } from '@shared-ui/localization-context';
const FlightNonStopCheckbox = ({
  modelKey,
  model,
  updateModel
}) => {
  const {
    formatText
  } = useLocalization();
  const handleChange = event => {
    if (typeof updateModel === 'function') {
      const {
        checked
      } = event.target;
      updateModel({
        target: {
          name: modelKey,
          value: String(checked),
          track: true
        }
      });
    }
  };
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: "all"
  }, React.createElement("div", null, React.createElement(EGDSCheckbox, {
    id: modelKey,
    name: modelKey,
    value: model.directFlights,
    checked: model.directFlights === 'true',
    onChange: handleChange
  }, formatText('multiItem.searchWizard.directFlightsOnly'))));
};
const FormFieldBoundComponent = FormFieldBinding(FlightNonStopCheckbox);
export { FormFieldBoundComponent as FlightNonStopCheckbox };