import * as React from 'react';
import { EGDSExpandoLink } from '@egds/react-core/expando';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSHeading } from '@egds/react-core/text';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { getBexApiDateTimeFromLocKey } from 'components/multi-item/bex-api-date-time-to-time-loc-key';
import { CarPickUpDropOffTimeSelect } from 'components/multi-item/search-wizard/car-pickup-dropoff-time-select';
import { DateUtils } from 'utils/date-utils';
const getTimeInMinutes = (timeField = '') => {
  const dateTimeFromLocKey = getBexApiDateTimeFromLocKey(timeField);
  const minutesInHour = 60;
  return dateTimeFromLocKey.hour * minutesInHour + dateTimeFromLocKey.minute;
};
const CarPickupDropOffTimeRange = ({
  updateModel,
  updateModelCallback,
  model,
  validators,
  modelKey,
  registerValidator
}) => {
  const l10n = useLocalization();
  const dropOffRef = React.createRef();
  const [invalidTextState, setInvalidTextState] = React.useState(false);
  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);
  const {
    pickUpTime,
    dropOffTime,
    startDate,
    endDate
  } = model;
  const invalidDropOffErrorMessage = l10n.formatText('multiItem.searchWizard.dropOffErrorMsg');
  const dateUtils = React.useMemo(() => new DateUtils(l10n), [l10n]);
  const isInvalidText = () => {
    const isInvalid = timeRangeInvalid();
    setInvalidTextState(isInvalid);
  };
  React.useEffect(() => {
    isInvalidText();
    return () => isInvalidText();
  }, [updateModel, startDate, endDate, pickUpTime, dropOffTime]);
  React.useEffect(() => {
    if (registerValidator) {
      const timeRangeValidator = () => {
        const isInvalid = timeRangeInvalid();
        setInvalidTextState(isInvalid);
        if (isInvalid) {
          return {
            invalidDropOffErrorMessage,
            focus: () => {
              var _a;
              return (_a = dropOffRef === null || dropOffRef === void 0 ? void 0 : dropOffRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
          };
        }
        return null;
      };
      registerValidator(timeRangeValidator);
    }
  }, [registerValidator]);
  const getDropOffTimeInMinutes = () => {
    if (!model.startDate || !model.endDate) return 0;
    const daysBetween = dateUtils.getDaysBetween(model.startDate, model.endDate);
    const minutesInDay = 24 * 60;
    const dropOffTime = getTimeInMinutes(model.dropOffTime);
    return daysBetween * minutesInDay + dropOffTime;
  };
  const getPickUpTimeInMinutes = () => getTimeInMinutes(model.pickUpTime);
  const timeRangeInvalid = () => {
    if (!model.startDate || !model.endDate) {
      return false;
    }
    if (!model.pickUpTime || !model.dropOffTime) {
      return true;
    }
    return getDropOffTimeInMinutes() - getPickUpTimeInMinutes() < 120;
  };
  const getFormattedDate = rawDate => {
    try {
      return l10n.formatDateString(rawDate, {
        skeleton: 'MMMd'
      }) || '';
    } catch (e) {
      return '';
    }
  };
  const commonProps = {
    updateModelCallback,
    model,
    modelKey,
    validators,
    onChange: updateModel,
    l10n
  };
  const timeSelect = React.createElement(EGDSLayoutGrid, {
    columns: 2,
    space: "two"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement(CarPickUpDropOffTimeSelect, Object.assign({
    label: l10n.formatText('multiItem.searchWizard.pickUpTime'),
    id: "pickUpTime",
    value: pickUpTime !== null && pickUpTime !== void 0 ? pickUpTime : '',
    formattedDate: getFormattedDate(startDate !== null && startDate !== void 0 ? startDate : '')
  }, commonProps))), React.createElement(EGDSLayoutGridItem, null, React.createElement(CarPickUpDropOffTimeSelect, Object.assign({
    label: l10n.formatText('multiItem.searchWizard.dropOffTime'),
    id: "dropOffTime",
    invalid: invalidTextState ? invalidDropOffErrorMessage : '',
    value: dropOffTime !== null && dropOffTime !== void 0 ? dropOffTime : '',
    formattedDate: getFormattedDate(endDate !== null && endDate !== void 0 ? endDate : ''),
    domRef: dropOffRef
  }, commonProps))));
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: {
      small: 1,
      medium: 3
    }
  }, React.createElement("div", null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, React.createElement(EGDSSpacing, {
    margin: {
      block: 'three'
    }
  }, React.createElement("hr", null)), React.createElement(EGDSHeading, {
    tag: "h4",
    size: 6
  }, React.createElement(LocalizedText, {
    message: "multiItem.searchWizard.mobile.carLabel"
  })), timeSelect)), React.createElement(ViewMedium, null, React.createElement(EGDSExpandoLink, {
    size: "medium",
    expandoTitle: l10n.formatText('multiItem.searchWizard.pickUpDropOffToggle'),
    isVisible: isExpandoVisible,
    "data-stid": "expand-multiitem-search-wizard-car-fields",
    onToggle: () => setIsExpandoVisible(!isExpandoVisible)
  }, timeSelect)))));
};
const FormFieldBoundComponent = FormFieldBinding(CarPickupDropOffTimeRange);
export { FormFieldBoundComponent as CarPickupDropOffTimeRange };