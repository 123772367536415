import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { ProgressIndicator } from '@shared-ui/multi-item-progress-indicator';
export const MitHeader = inject('multiItem', 'analytics', 'page')(observer(({
  multiItem,
  analytics,
  page
}) => {
  var _a;
  if (!(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath())) {
    return null;
  }
  /**
   * Temp solution to not deduplicate progress indicator query on SRP and PDP packages.
   * https://jira.expedia.biz/browse/WTR-6017
   */
  let priceToken = null;
  if (((_a = page === null || page === void 0 ? void 0 : page.pageName) === null || _a === void 0 ? void 0 : _a.toUpperCase().indexOf('INFOSITE')) === -1) {
    priceToken = '';
  }
  const multiItemSessionId = multiItem.multiItemSessionId;
  const errorCallback = () => {
    analytics === null || analytics === void 0 ? void 0 : analytics.trackEvent('MIT.PROGRESS.INDICATOR.ERROR', 'RFRR Action Event', analytics.omnitureData, null, true);
  };
  const changeLinkCallBack = (rfrr, linkName, event) => {
    analytics === null || analytics === void 0 ? void 0 : analytics.trackEvent(rfrr, linkName, analytics.omnitureData, event, true);
  };
  return React.createElement(ErrorBoundary, {
    label: "progress-indicator"
  }, React.createElement(EGDSSpacing, {
    padding: {
      small: {
        blockstart: 'three',
        inlinestart: 'three'
      },
      medium: {
        blockstart: 'one'
      },
      large: {
        blockstart: 'three'
      }
    }
  }, React.createElement("div", null, React.createElement(ProgressIndicator, {
    inputs: {
      multiItemSessionId: multiItemSessionId || null,
      journeyContinuationId: null,
      multiItemPriceToken: priceToken,
      lockupPrice: null,
      savingsPrice: null,
      originalBookingId: null,
      additionalChangeQueryParams: null
    },
    scrimColor: "PRIMARY",
    showLoadingState: true,
    changeLinkClickCallback: changeLinkCallBack,
    errorCallback: errorCallback
  }))));
}));
MitHeader.displayName = 'MitHeader';