import * as React from 'react';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSRadioButton, EGDSRadioButtonGroup } from '@egds/react-core/radio-button';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { useClickTracker } from '@shared-ui/clickstream-analytics-context';
import { useLocalization } from '@shared-ui/localization-context';
const EXCLUDE_PETS = 'EXCLUDE_PETS';
const INCLUDE_PETS = 'INCLUDE_PETS';
export const PetsRadioButtonGroup = ({
  enabled,
  onEnable,
  onDisable
}) => {
  const l10n = useLocalization();
  const track = useClickTracker();
  const [value, setValue] = React.useState(enabled ? INCLUDE_PETS : EXCLUDE_PETS);
  const handleRadioChange = event => {
    const eventTarget = event.target.value;
    setValue(eventTarget);
    track(`HOT.SR.TravelersField.${eventTarget}`, `Travelers Field ${eventTarget}`);
    eventTarget === INCLUDE_PETS ? onEnable() : onDisable();
  };
  return React.createElement(EGDSSpacing, {
    padding: {
      blockend: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(EGDSRadioButtonGroup, {
    legendLabel: l10n.formatText('hotels.roomPicker.pets.label'),
    onChange: handleRadioChange,
    group: "petsIncluded",
    selected: value
  }, React.createElement(EGDSLayoutFlex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSText, null, l10n.formatText('hotels.roomPicker.pets.title'))), React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSLayoutFlex, null, React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSRadioButton, {
    id: EXCLUDE_PETS,
    value: EXCLUDE_PETS
  }, l10n.formatText('hotels.roomPicker.pets.exclude'))), React.createElement(EGDSLayoutFlexItem, null, React.createElement(EGDSRadioButton, {
    id: INCLUDE_PETS,
    value: INCLUDE_PETS
  }, l10n.formatText('hotels.roomPicker.pets.include')))))))));
};