import * as React from 'react';
// If focusing a div or heading, ensure it has `tabIndex={0}` to make it focusable.
const refs = {};
const RefContext = React.createContext(refs);
export const useRefProvider = (...keys) => {
  const context = React.useContext(RefContext);
  return keys.map(key => {
    const existingValue = context[key];
    return existingValue ? existingValue : context[key] = React.createRef();
  });
};
export const RefProvider = ({
  children
}) => React.createElement(RefContext.Provider, {
  value: refs
}, children);
RefProvider.displayName = 'RefProvider';