import * as React from 'react';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { useStores } from 'src/common/stores/use-stores';
import { ShortlistToggleProvider } from '@shared-ui/trip-planning-travelpulse';
export const ShortlistProvider = ({
  children
}) => {
  const {
    uiPage
  } = useStores();
  const dispatchHandler = ({
    message
  }) => {
    uiPage === null || uiPage === void 0 ? void 0 : uiPage.showNotification(message);
    liveAnnounce(message, Level.POLITE);
  };
  return React.createElement(ShortlistToggleProvider, {
    dispatchHandler: dispatchHandler
  }, children);
};