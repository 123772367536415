var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
import { action, computed, observable } from 'mobx';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { convertRoomChildrenToString } from 'src/common/utils/room-utils';
export class RoomPickerModel {
  constructor({
    adults,
    children
  } = {}, l10n, context, isPackageShoppingPath = false, petsIncluded = false) {
    /* room data structure:
      {
        adults: Number,
        children: [
          Number, Number ... // each number represents AGE
        ]
      }
    */
    this.rooms = [];
    this.l10n = l10n;
    this.context = context;
    this.isPackageShoppingPath = isPackageShoppingPath;
    this.petsIncluded = petsIncluded;
    this.setupRooms(adults, children);
  }
  get initialProps() {
    return {
      adults: this.initAdults,
      children: this.initChildren
    };
  }
  get finalProps() {
    const adults = this.rooms.map(room => room.adults).filter(adult => adult > 0).join(',');
    const children = this.rooms
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    .map((room, index) => convertRoomChildrenToString(index + 1, room.children)).filter(child => child !== '').join(',');
    return {
      adults,
      children
    };
  }
  get counts() {
    return this.rooms.reduce((obj, room) => {
      obj.guests += room.adults + room.children.length;
      return obj;
    }, {
      guests: 0,
      rooms: this.rooms.length
    });
  }
  get displaySummary() {
    const {
      rooms,
      guests
    } = this.counts;
    const adjustedGuests = guests > RoomPickerModel.MAX_GUESTS ? RoomPickerModel.MAX_GUESTS : guests;
    return this.l10n.formatText(this.displaySummaryKey, rooms, adjustedGuests);
  }
  get displaySummaryKey() {
    if (isVrbo(this.context)) {
      return this.petsIncluded ? RoomPickerModel.VRBO_DISPLAY_SUMMARY_KEY_WITH_PETS : RoomPickerModel.VRBO_DISPLAY_SUMMARY_KEY;
    }
    return RoomPickerModel.DISPLAY_SUMMARY_KEY;
  }
  get roomCount() {
    return this.rooms.length;
  }
  get disableAddRoom() {
    if (this.isPackageShoppingPath && this.counts.guests >= 6) {
      return true;
    }
    if (this.initialRoomCount && this.rooms.length < this.initialRoomCount) {
      return false;
    }
    return this.rooms.length >= RoomPickerModel.MAX_ROOM_COUNT || this.counts.guests >= RoomPickerModel.MAX_GUESTS;
  }
  get enableInfantSeatSelection() {
    return this.rooms.some(room => room.children.some(childAge => childAge <= 1));
  }
  includePets() {
    this.petsIncluded = true;
  }
  excludePets() {
    this.petsIncluded = false;
  }
  addRoom() {
    this.rooms.push({
      adults: 1,
      children: []
    });
  }
  removeRoom(room) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    this.rooms = this.rooms.filter(rm => rm !== room);
  }
  changeAdultsForRoom(room, count) {
    const temp = room.adults;
    room.adults = count;
    if (this.counts.guests > RoomPickerModel.MAX_GUESTS) {
      room.adults = temp;
    }
  }
  changeChildrenForRoom(room, count, isInfant = false) {
    let diff = this.infantAwareDiff(room, count, isInfant);
    if (diff > 0) {
      for (diff; diff > 0; diff--) {
        const type = isInfant || !this.isPackageShoppingPath ? RoomPickerModel.DEFAULT_SA_CHILD_AGE : RoomPickerModel.DEFAULT_MULTI_ITEM_CHILD_AGE;
        room.children.push(type);
      }
    } else if (diff < 0) {
      this.infantAwareSplice(room, diff, isInfant);
    }
    if (this.counts.guests > RoomPickerModel.MAX_GUESTS) {
      room.children.pop();
    }
  }
  changeChildAge(roomIndex, childIndex, age, isInfant = false) {
    this.rooms = observable(this.rooms.map((observableRoom, roomI) => {
      if (childIndex > -1 && childIndex < observableRoom.children.length && roomI === roomIndex) {
        let trackedIndex = -1;
        for (let index = 0; index < observableRoom.children.length; index++) {
          const value = observableRoom.children[index];
          if (value !== undefined && (isInfant && value < 2 || !isInfant && value >= 2) || !this.isPackageShoppingPath) {
            // eslint-disable-next-line no-plusplus
            trackedIndex++;
          }
          if (trackedIndex === childIndex) {
            observableRoom.children[index] = age;
            break;
          }
        }
      }
      return observableRoom;
    }));
  }
  reset() {
    this.setupRooms(this.initAdults, this.initChildren);
  }
  // private helpers
  setupRooms(adultsText, childrenText) {
    var _a;
    const adults = adultsText && adultsText.length > 0 ? adultsText.split(',') : [];
    const childrenRooms = this.convertChildrenTextToRooms(childrenText);
    const numberOfRooms = Math.max(adults.length, childrenRooms.length);
    const rooms = [];
    for (let i = 0; i < numberOfRooms; i++) {
      // note: parseInt() can return NaN
      const roomAdults = i < adults.length ? parseInt((_a = adults[i]) !== null && _a !== void 0 ? _a : '', 10) : 0;
      const roomChildren = childrenRooms[i] ? childrenRooms[i] : [];
      rooms.push({
        adults: isNaN(roomAdults) ? 0 : roomAdults,
        children: roomChildren !== null && roomChildren !== void 0 ? roomChildren : []
      });
    }
    this.initAdults = adultsText !== null && adultsText !== void 0 ? adultsText : '';
    this.initChildren = childrenText !== null && childrenText !== void 0 ? childrenText : '';
    this.rooms.replace(rooms);
    this.initialRoomCount = rooms.length;
  }
  infantAwareDiff(room, count, isInfant) {
    if (this.isPackageShoppingPath) {
      const ageGroup = room.children.filter(age => isInfant ? age < 2 : age >= 2).length;
      return count - ageGroup;
    }
    return count - room.children.length;
  }
  infantAwareSplice(room, diff, isInfant) {
    if (this.isPackageShoppingPath) {
      // find the last of type and remove it until our diff is 0.
      for (let i = room.children.length - 1; i > -1; i--) {
        const age = room.children[i];
        if (age < 2 && isInfant || age >= 2 && !isInfant) {
          room.children.splice(i, 1);
          // eslint-disable-next-line no-plusplus
          diff++;
        }
        if (diff === 0) {
          break;
        }
      }
    } else {
      room.children.splice(room.children.length + diff, -diff);
    }
  }
  convertChildrenTextToRooms(childrenText) {
    const items = childrenText && childrenText.length > 0 ? childrenText.split(',') : [];
    return items.reduce((rooms, item) => {
      var _a;
      const roomAge = item.split('_');
      if (!roomAge[0] || !roomAge[1]) {
        return rooms; // invalid format sent down
      }
      let roomNumber = parseInt(roomAge[0], 10);
      const age = parseInt(roomAge[1], 10);
      if (isNaN(roomNumber) || isNaN(age)) {
        return rooms; // invalid data type for room_age
      }
      roomNumber -= 1; // turn to 0-indexed for rooms array
      if (rooms[roomNumber]) {
        (_a = rooms[roomNumber]) === null || _a === void 0 ? void 0 : _a.push(age);
      } else {
        rooms[roomNumber] = [age];
      }
      return rooms;
    }, []);
  }
}
RoomPickerModel.DISPLAY_SUMMARY_KEY = 'hotels.roomPicker.roomSummary';
RoomPickerModel.VRBO_DISPLAY_SUMMARY_KEY = 'hotels.roomPicker.roomSummary.singleUnit';
RoomPickerModel.VRBO_DISPLAY_SUMMARY_KEY_WITH_PETS = 'hotels.roomPicker.roomSummary.singleUnit.withPets';
RoomPickerModel.DEFAULT_SA_CHILD_AGE = 0;
RoomPickerModel.DEFAULT_MULTI_ITEM_CHILD_AGE = 2;
RoomPickerModel.MAX_ROOM_COUNT = 8;
RoomPickerModel.MAX_GUESTS = 14;
// Only used by MultiItem
RoomPickerModel.determineMaxGuests = (currentSectionGuests, totalGuests) => currentSectionGuests + 6 - totalGuests;
__decorate([observable], RoomPickerModel.prototype, "rooms", void 0);
__decorate([observable], RoomPickerModel.prototype, "petsIncluded", void 0);
__decorate([computed], RoomPickerModel.prototype, "finalProps", null);
__decorate([computed], RoomPickerModel.prototype, "counts", null);
__decorate([computed], RoomPickerModel.prototype, "displaySummary", null);
__decorate([computed], RoomPickerModel.prototype, "displaySummaryKey", null);
__decorate([computed], RoomPickerModel.prototype, "roomCount", null);
__decorate([computed], RoomPickerModel.prototype, "disableAddRoom", null);
__decorate([computed], RoomPickerModel.prototype, "enableInfantSeatSelection", null);
__decorate([action], RoomPickerModel.prototype, "includePets", null);
__decorate([action], RoomPickerModel.prototype, "excludePets", null);
__decorate([action], RoomPickerModel.prototype, "addRoom", null);
__decorate([action], RoomPickerModel.prototype, "removeRoom", null);
__decorate([action], RoomPickerModel.prototype, "changeAdultsForRoom", null);
__decorate([action], RoomPickerModel.prototype, "changeChildrenForRoom", null);
__decorate([action], RoomPickerModel.prototype, "changeChildAge", null);
__decorate([action], RoomPickerModel.prototype, "reset", null);