import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateSearch } from 'bernie-core';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { getSharedUIListingsInputsWithSearchId } from 'src/common/stores/adapters';
import { useStores } from 'src/common/stores/use-stores';
import { updateCategorySearchQueryParam } from 'src/common/utils/query-params-util';
import { ShoppingSearchListingHeader } from '@shared-ui/lodging-property-listing';
import { useLodgingURLParamsToQueryInputs } from '@shared-ui/retail-search-tools-product';
export const ShoppingSearchListingHeaderWrapper = ({
  spacingOptions
}) => {
  const {
    context,
    experiment,
    searchCriteria,
    searchResults,
    analyticsSearch,
    pageLayout,
    analytics,
    multiItem
  } = useStores();
  const location = useLocation();
  const history = useHistory();
  const isMultiItem = multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath();
  const listingsQueryInputs = useLodgingURLParamsToQueryInputs();
  const searchInputs = listingsQueryInputs ? getSharedUIListingsInputsWithSearchId(listingsQueryInputs, context, analyticsSearch, location, pageLayout, searchCriteria, experiment) : undefined;
  const handleCategoryCarouselImageCardClick = React.useCallback(categoryImageCard => {
    analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analytics, experiment)({
      history,
      location,
      newParams: updateCategorySearchQueryParam(categoryImageCard, location),
      replace: false
    });
  }, [analyticsSearch, analytics, experiment, history, location, updateSearch, updateCategorySearchQueryParam]);
  const hasValidShoppingSearchListingHeader = (searchResults === null || searchResults === void 0 ? void 0 : searchResults.shoppingSearchListingHeader) && (searchResults === null || searchResults === void 0 ? void 0 : searchResults.shoppingSearchListingHeader.length) > 0;
  if (!hasValidShoppingSearchListingHeader || isMultiItem || !(searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.categorySearch) || !searchInputs) {
    return null;
  }
  return React.createElement(EGDSSpacing, Object.assign({}, spacingOptions), React.createElement("div", null, React.createElement(ShoppingSearchListingHeader, {
    data: {
      shoppingSearchListingHeader: searchResults.shoppingSearchListingHeader
    },
    inputs: searchInputs,
    propertySearchListingsPwa: searchResults.propertySearchListings,
    onImageCardClickCallback: handleCategoryCarouselImageCardClick
  })));
};
ShoppingSearchListingHeaderWrapper.displayName = 'ShoppingSearchListingHeaderWrapper';