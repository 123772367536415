import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateSearch } from 'bernie-core';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { parse } from 'query-string';
import { useStores } from 'src/common/stores/use-stores';
import { normalizeQueryParams } from 'src/common/utils/query-params-util';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { LineOfBusinessDomain, OneKeyBanner, PageLocation } from '@shared-ui/retail-loyalty-one-key-banner';
import { generateSearchContextInput, packageTypeMap } from 'stores/adapters';
export const OneKeyBannerToggle = React.memo(({
  analyticsSearch,
  analytics,
  experiment
}) => {
  const {
    multiItem,
    searchResults
  } = useStores();
  const history = useHistory();
  const location = useLocation();
  const l10n = useLocalization();
  const searchCriteria = searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria;
  const handleClick = React.useCallback(isChecked => {
    analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analytics, experiment)({
      history,
      location,
      newParams: {
        useRewards: String(isChecked)
      },
      replace: true
    });
    setTimeout(() => liveAnnounce(l10n.formatText(isChecked ? 'common.pointsApplied' : 'common.pointsRemoved'), Level.POLITE), 500);
  }, [analytics, analyticsSearch, experiment, history, l10n, location]);
  if (!searchCriteria) return null;
  const queryParams = parse(history.location.search);
  const packageType = multiItem === null || multiItem === void 0 ? void 0 : multiItem.getPackageType();
  const normalizedQueryParams = normalizeQueryParams(queryParams);
  const {
    mdpdtl,
    gclid,
    mdpcid
  } = normalizedQueryParams;
  const marketingInfo = Object.assign(Object.assign(Object.assign({}, mdpdtl && {
    metaDistributionPartnersDetails: mdpdtl
  }), gclid && {
    googleClickIdentifier: gclid
  }), mdpcid && {
    metaDistributionPartnersChannelIdentifier: mdpcid
  });
  const inputs = {
    useLoyaltyCurrency: searchCriteria.useRewards === 'true',
    pageLocation: PageLocation.SEARCH_RESULTS,
    packageType: packageType && packageTypeMap[packageType] || null,
    packageLOB: packageType ? LineOfBusinessDomain.LODGING : null,
    marketing: marketingInfo,
    lineOfBusiness: packageType ? LineOfBusinessDomain.PACKAGES : LineOfBusinessDomain.LODGING,
    searchContext: generateSearchContextInput(searchCriteria)
  };
  return React.createElement(Experiment, {
    name: (multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) ? 'TSDP_OneKey_PB_SRP_Web' : 'eg_one_key_client'
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'two'
    }
  }, React.createElement("div", {
    "data-stid": "onekeybanner-toggle"
  }, React.createElement(OneKeyBanner, {
    inputs: inputs,
    switchHandler: handleClick,
    showBorder: true,
    skipSsr: true
  })))));
});
OneKeyBannerToggle.displayName = 'OneKeyBannerToggle';