import * as React from 'react';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { ExperienceTemplateRenderer } from 'experience-template-renderer-react';
import { any, number, object, optional, safeParse, string, union } from 'valibot';
export const EGDSComponent = ({
  templateComponent
}) => {
  var _a;
  const {
    children,
    config
  } = templateComponent;
  const configSchema = optional(object({
    type: string(),
    grow: optional(number()),
    direction: optional(string()),
    colSpan: optional(number()),
    padding: optional(any()),
    margin: optional(any()),
    maxWidth: optional(union([string(), number()]))
  }));
  const result = safeParse(configSchema, config);
  if (!result.success || !children || children.length === 0) {
    return null;
  }
  const {
    type,
    grow,
    direction,
    colSpan,
    padding,
    margin,
    maxWidth
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  const templateRenderer = React.createElement(ExperienceTemplateRenderer, {
    templateComponents: children
  });
  switch (type) {
    case 'EGDSLayoutFlex':
      return React.createElement(EGDSLayoutFlex, {
        direction: direction
      }, templateRenderer);
    case 'EGDSLayoutFlexItem':
      return React.createElement(EGDSLayoutFlexItem, {
        grow: grow,
        maxWidth: maxWidth
      }, React.createElement("div", null, templateRenderer));
    case 'EGDSLayoutGridItem':
      return React.createElement(EGDSLayoutGridItem, {
        colSpan: colSpan
      }, React.createElement("div", null, templateRenderer));
    case 'EGDSSpacing':
      return React.createElement(EGDSSpacing, {
        padding: padding,
        margin: margin
      }, React.createElement("div", null, templateRenderer));
    default:
      return null;
  }
};