import * as React from 'react';
import { ClientLogger } from 'bernie-client';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { Qualtrics } from '@shared-ui/customer-qualtrics';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
const logger = ClientLogger.getLoggerWithIdentifier('pwa.customer.qualtrics.experiments');
const onInit = (zoneId, experimentName) => {
  const message = `Qualtrics startup zoneId: ${zoneId} ${experimentName ? `experimentName: ${experimentName}` : ''}`;
  logger.logEvent(new SystemEvent(SystemEventLevel.INFO, 'INIT_QUALTRICS'), message);
};
export const QualtricsExperiments = () => React.createElement(Experiment, {
  name: "EGTNL_Lodging_PWA_Qualtrics"
}, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(Qualtrics, {
  skipSsr: true,
  onInit: zoneId => {
    onInit(zoneId);
  }
})));
QualtricsExperiments.displayName = 'QualtricsExperiments';