import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSToast } from '@egds/react-core/toast';
export const Notification = inject('uiPage')(observer(({
  uiPage
}) => {
  React.useEffect(() => () => uiPage === null || uiPage === void 0 ? void 0 : uiPage.resetNotification(), [uiPage]);
  if (!uiPage || !uiPage.notifications) {
    return null;
  }
  return React.createElement(React.Fragment, null, React.createElement(ErrorBoundary, {
    label: "notifications.shopping-pwa"
  }, uiPage.notifications.show && React.createElement(EGDSToast, {
    show: uiPage.notifications.show
  }, uiPage.notifications.message)));
}));