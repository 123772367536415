var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { default as LazyLoad } from 'react-lazyload';
import { EGDSCardPlaceholder } from '@egds/react-core/cards';
/**
 * This component simplified lazy loading - @jullberg
 */
export const SimpleLazyLoadWrapper = React.forwardRef((_a, ref) => {
  var {
      children,
      placeholder = React.createElement(EGDSCardPlaceholder, null)
    } = _a,
    props = __rest(_a, ["children", "placeholder"]);
  return React.createElement(LazyLoad, Object.assign({
    once: true,
    placeholder: placeholder
  }, props, {
    ref: ref
  }), children);
});