import * as React from 'react';
import { EGDSCheckbox } from '@egds/react-core/checkbox';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { useLocalization } from '@shared-ui/localization-context';
const PartialStayCheckbox = ({
  updateModel,
  model,
  modelKey
}) => {
  const {
    formatText
  } = useLocalization();
  const handleChange = event => {
    if (typeof updateModel === 'function') {
      const {
        checked
      } = event.target;
      updateModel({
        target: {
          name: 'partialStay',
          value: String(checked),
          track: true
        }
      });
    }
  };
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: "all"
  }, React.createElement("div", null, React.createElement(EGDSCheckbox, {
    id: modelKey,
    name: modelKey,
    value: String(model.isPartialStay),
    checked: model.isPartialStay,
    onChange: handleChange
  }, formatText('multiItem.searchWizard.partialStay.text'))));
};
const FormFieldBoundComponent = FormFieldBinding(PartialStayCheckbox);
export { FormFieldBoundComponent as PartialStayCheckbox };