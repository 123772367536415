import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useViewTrackerContext } from 'src/common/stores/contexts/viewTracker';
import { any, boolean, nativeEnum, object, optional, safeParse } from 'valibot';
import { useClickstreamAnalytics } from '@shared-ui/clickstream-analytics-context';
import { SortAndFiltersViewType } from '@shared-ui/retail-sort-and-filter-lodging';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { MapPreview } from './map/map-preview';
import { UniversalSortFilterWrapper } from './sort-filter/universal-sort-filter';
export const Filterbar = ({
  templateComponent
}) => {
  var _a;
  const configSchema = optional(object({
    view: optional(nativeEnum(SortAndFiltersViewType)),
    bexQuickFiltersEnabled: optional(boolean()),
    filterBarSpacing: optional(any()),
    carouselEdgeItemsPadding: optional(any())
  }));
  const result = safeParse(configSchema, templateComponent.config);
  if (!result.success) {
    return null;
  }
  const {
    view,
    bexQuickFiltersEnabled,
    filterBarSpacing,
    carouselEdgeItemsPadding
  } = (_a = result.output) !== null && _a !== void 0 ? _a : {};
  const buttonProps = {
    dialogView: view,
    bexQuickFiltersEnabled
  };
  return React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(Buttons, Object.assign({
    withMapButton: true
  }, buttonProps, {
    filterBarSpacing: filterBarSpacing,
    carouselEdgeItemsPadding: carouselEdgeItemsPadding
  }))), React.createElement(ViewLarge, null, React.createElement(Buttons, Object.assign({
    withMapButton: false
  }, buttonProps, {
    filterBarSpacing: filterBarSpacing,
    carouselEdgeItemsPadding: carouselEdgeItemsPadding
  }))));
};
Filterbar.displayName = 'Filterbar';
const Buttons = ({
  withMapButton,
  dialogView,
  bexQuickFiltersEnabled,
  filterBarSpacing,
  carouselEdgeItemsPadding
}) => {
  const viewTracker = useViewTrackerContext();
  const tracker = useClickstreamAnalytics();
  const mapTrigger = withMapButton && React.createElement("div", {
    "data-stid": "map-trigger"
  }, React.createElement(MapPreview, {
    viewTracker: viewTracker,
    tracker: tracker,
    dialogView: dialogView,
    bexQuickFiltersEnabled: bexQuickFiltersEnabled
  }));
  const queryValues = new URLSearchParams(useLocation().search);
  return React.createElement(React.Fragment, null, queryValues.get('pwaOverlay') !== 'map' && React.createElement("div", {
    "data-stid": "filterbar"
  }, React.createElement(UniversalSortFilterWrapper, {
    view: SortAndFiltersViewType.FILTER_BAR,
    filterBarSpacing: filterBarSpacing,
    carouselEdgeItemsPadding: carouselEdgeItemsPadding
  })), mapTrigger);
};