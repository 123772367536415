import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSPageLoader } from '@egds/react-core/loader';
export const AnimatedProgressIndicator = inject('uiPage')(observer(({
  uiPage
}) => {
  React.useEffect(() => () => uiPage === null || uiPage === void 0 ? void 0 : uiPage.hideProgressIndicator(), [uiPage]);
  return React.createElement(ErrorBoundary, {
    label: "animatedProgressIndicator.shopping-pwa"
  }, React.createElement(EGDSPageLoader, {
    visible: uiPage && uiPage.displayProgressIndicator
  }));
}));