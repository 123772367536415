/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export var EgRecsPlacementIds;
(function (EgRecsPlacementIds) {
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID"] = "142";
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID_EXPEDIA"] = "162";
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID_HOTELS"] = "163";
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID_VRBO"] = "166";
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID_COMET"] = "165";
  EgRecsPlacementIds["PROPERTY_RECS_PLACEMENT_ID_COMET_EXPEDIA"] = "167";
  EgRecsPlacementIds["SPONSORED_PROPERTIES_CAROUSEL_PLACEMENT_ID"] = "190";
})(EgRecsPlacementIds || (EgRecsPlacementIds = {}));
export var EgRecsConfigIds;
(function (EgRecsConfigIds) {
  EgRecsConfigIds["EXPEDIA_FH_PACKAGE_TYPE"] = "5d719f0e-fa12-45b3-929c-3c27e3151af4";
  EgRecsConfigIds["EXPEDIA_FHC_PACKAGE_TYPE"] = "ab96882e-098b-4ec0-af2b-e89410cff37f";
  EgRecsConfigIds["EXPEDIA_HC_PACKAGE_TYPE"] = "42f58cbd-3b9b-43be-80b0-0ad57396af1d";
  EgRecsConfigIds["HOTELS_LODGING_PACKAGE_TYPE"] = "f5e69be8-82f4-11ee-b962-0242ac120002";
  EgRecsConfigIds["COMET_FH_PACKAGE_TYPE"] = "5d719f0e-fa12-45b3-929c-3c27e3151af4";
  EgRecsConfigIds["COMET_FHC_PACKAGE_TYPE"] = "ab96882e-098b-4ec0-af2b-e89410cff37f";
  EgRecsConfigIds["COMET_HC_PACKAGE_TYPE"] = "42f58cbd-3b9b-43be-80b0-0ad57396af1d";
})(EgRecsConfigIds || (EgRecsConfigIds = {}));