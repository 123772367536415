import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { useStores } from 'src/common/stores/use-stores';
import { ResultsAccessibilityHeadingRegion } from 'components/layout/pages/results-accessibility-heading-region';
import { SearchPageName } from 'constants/pages';
export const BodyRegion = ({
  pageHeader,
  templateContent,
  inlineMargin = 'six'
}) => {
  const {
    multiItem,
    page
  } = useStores();
  const usePropertyListingQuery = !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) && (page === null || page === void 0 ? void 0 : page.pageName) === SearchPageName.SA;
  const header = pageHeader && React.createElement("header", null, React.createElement("h1", {
    className: "is-visually-hidden"
  }, pageHeader));
  const mainRegion = React.createElement("div", null, usePropertyListingQuery ? React.createElement(ResultsAccessibilityHeadingRegion, null) : header, React.createElement("div", {
    className: "main-region"
  }, React.createElement(ErrorBoundary, {
    label: "hotel.masterPage.template"
  }, templateContent)));
  return React.createElement(EGDSSpacing, {
    margin: {
      medium: {
        inline: inlineMargin
      }
    }
  }, mainRegion);
};
BodyRegion.displayName = 'BodyRegion';