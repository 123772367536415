import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { ExperienceTemplateRenderer } from 'experience-template-renderer-react';
import { ViewExtraLarge, Viewport } from '@shared-ui/viewport-context';
export const SideRailRegion = ({
  templateComponent
}) => {
  const {
    children
  } = templateComponent;
  if (!children || children.length === 0) {
    return null;
  }
  return React.createElement(EGDSLayoutFlexItem, {
    minWidth: 160,
    maxWidth: 160
  }, React.createElement("div", {
    className: "side-rail"
  }, React.createElement(Viewport, null, React.createElement(ViewExtraLarge, null, React.createElement(ErrorBoundary, {
    label: "hotel.masterPage.sideRail"
  }, children && React.createElement(ExperienceTemplateRenderer, {
    templateComponents: children
  }))))));
};