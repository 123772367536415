var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSButtonSize, EGDSFloatingFullWidthButton, EGDSTertiaryButton } from '@egds/react-core/button';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSLink } from '@egds/react-core/link';
import { EGDSRadioButton, EGDSRadioButtonGroup } from '@egds/react-core/radio-button';
import { EGDSScrim, EGDSScrimType } from '@egds/react-core/scrim';
import { EGDSSheet, EGDSSheetContent } from '@egds/react-core/sheet';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { EGDSToolbar } from '@egds/react-core/toolbar';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { withLocalization } from '@shared-ui/localization-context';
import { MultiItemRoom } from 'components/common/room-picker/internals/multi-item-room';
import { RoomPickerModel } from 'components/common/room-picker/room-picker-model';
import { PetsRadioButtonGroup } from './internals/pets-radio-button-group';
import { Room } from './internals/room';
const SubmitButtonTracked = ClickTracker(EGDSFloatingFullWidthButton);
const TertiaryButtonTracked = ClickTracker(EGDSTertiaryButton);
var InfantSeating;
(function (InfantSeating) {
  InfantSeating["IN_SEAT"] = "seat";
  InfantSeating["ON_LAP"] = "lap";
})(InfantSeating || (InfantSeating = {}));
let RoomPicker = class RoomPicker extends React.Component {
  constructor(props) {
    super(props);
    this.focusOnOpen = React.createRef();
    this.lastRoomHeader = React.createRef();
    const {
      l10n,
      context,
      isPackageShoppingPath,
      petsIncluded,
      infantsInSeats
    } = props;
    this.state = {
      dialogShow: false,
      selection: new RoomPickerModel(props, l10n, context, isPackageShoppingPath, petsIncluded),
      justAddedRoom: false,
      justRemovedRoom: false,
      infantsInSeats
    };
    this.onIncludePets = this.onIncludePets.bind(this);
    this.onExcludePets = this.onExcludePets.bind(this);
    this.onAddRoom = this.onAddRoom.bind(this);
    this.onChildAgeUpdated = this.onChildAgeUpdated.bind(this);
    this.onRemoveRoom = this.onRemoveRoom.bind(this);
    this.onRoomAdultsChanged = this.onRoomAdultsChanged.bind(this);
    this.onRoomChildrenChanged = this.onRoomChildrenChanged.bind(this);
    this.onRoomInfantsChanged = this.onRoomInfantsChanged.bind(this);
    this.onInfantAgeUpdated = this.onInfantAgeUpdated.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetLiveAnnounce = this.resetLiveAnnounce.bind(this);
  }
  componentWillMount() {
    this.updateStateWithProps(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateStateWithProps(nextProps);
  }
  componentDidMount() {
    this.setState({
      dialogShow: true
    });
  }
  componentDidUpdate() {
    const {
      l10n
    } = this.props;
    const {
      selection
    } = this.state;
    const {
      roomCount
    } = selection;
    if (this.state.justAddedRoom) {
      liveAnnounce(l10n.formatText('hotels.roomPicker.roomAdded.liveAnnounce', roomCount));
      this.setFocusOnLastRoomHeader();
      this.setState({
        justAddedRoom: false
      });
    }
    if (this.state.justRemovedRoom) {
      liveAnnounce(l10n.formatText('hotels.roomPicker.roomRemoved.liveAnnounce', roomCount));
      this.setFocusOnLastRoomHeader();
      this.setState({
        justRemovedRoom: false
      });
    }
  }
  setFocusOnLastRoomHeader() {
    if (this.lastRoomHeader.current && typeof this.lastRoomHeader.current.focus === 'function') {
      this.lastRoomHeader.current.focus();
    }
  }
  updateStateWithProps(props) {
    const {
      adults,
      children
    } = this.state.selection.initialProps;
    if (adults !== props.adultsString || children !== props.childrenString) {
      this.state.selection.setupRooms(props.adultsString, props.childrenString);
    }
  }
  render() {
    var _a, _b;
    const {
      l10n,
      context
    } = this.props;
    const {
      dialogShow,
      selection
    } = this.state;
    const {
      disableAddRoom,
      displaySummary,
      rooms,
      enableInfantSeatSelection
    } = selection;
    const toolbarTitle = this.props.isPackageShoppingPath ? l10n.formatText('hotels.travelers.multiitem.dialog.label') : l10n.formatText('hotels.roomPicker.title');
    const infantSeatChanged = event => this.onInfantSeatingChanged(event);
    const toolbarAnalytics = (_a = this.props.analytics) === null || _a === void 0 ? void 0 : _a.setupTracker(':ChangeRoomGuest', 'Close Room Picker');
    const submitActionText = this.props.buttonText ? this.props.buttonText : l10n.formatText('hotels.roomPicker.doneButton.label');
    const submitButtonText = React.createElement(React.Fragment, null, submitActionText, React.createElement("span", {
      className: "is-visually-hidden"
    }, l10n.formatText('hotels.roomPicker.doneButton.a11yLabel')));
    const showInfantSeatingOptions = this.props.isPackageShoppingPath && ((_b = this.props.multiItem) === null || _b === void 0 ? void 0 : _b.isFlightPackage()) && enableInfantSeatSelection;
    return React.createElement(EGDSSheet, {
      type: "centered",
      isVisible: dialogShow,
      triggerRef: null
    }, React.createElement(EGDSToolbar, {
      toolbarTitle: toolbarTitle,
      navigationContent: {
        onClick: this.onDismiss,
        navIconLabel: l10n.formatText('hotels.roomPicker.cancelButton.a11yLabel'),
        onClickAnalytics: toolbarAnalytics,
        navButtonRef: this.focusOnOpen
      }
    }), React.createElement(EGDSSheetContent, null, React.createElement("section", {
      className: "uitk-scroll-vertical"
    }, React.createElement(EGDSSpacing, {
      padding: {
        blockstart: 'half',
        blockend: 'twelve',
        inline: 'four'
      }
    }, React.createElement("div", null, rooms.map((room, roomIndex) => this.getRoomComponent(roomIndex, room, selection)), !isVrbo(context) && React.createElement(EGDSSpacing, {
      padding: {
        blockstart: 'four',
        blockend: 'twelve'
      }
    }, React.createElement("div", null, React.createElement(EGDSText, {
      align: "right"
    }, React.createElement(TertiaryButtonTracked, {
      "data-stid": "add-room",
      linkName: "Add room",
      onClick: this.onAddRoom,
      disabled: disableAddRoom,
      rfrr: ":AddRoom"
    }, l10n.formatText('hotels.roomPicker.addRoomButton.label'))))), showInfantSeatingOptions && React.createElement("div", {
      className: "airlineChildSeating"
    }, React.createElement("fieldset", null, React.createElement("legend", null, React.createElement(EGDSText, {
      size: 300,
      weight: "bold"
    }, l10n.formatText('hotels.roomPicker.multiitem.infantSeating.label'))), React.createElement(EGDSRadioButtonGroup, {
      group: "ChildSeating",
      onChange: infantSeatChanged,
      selected: this.state.infantsInSeats === '1' ? InfantSeating.IN_SEAT : InfantSeating.ON_LAP
    }, React.createElement(EGDSSpacing, {
      padding: {
        inlineend: 'six'
      }
    }, React.createElement("div", null, React.createElement(EGDSRadioButton, {
      id: "ChildOnLap",
      value: 'lap'
    }, l10n.formatText('hotels.roomPicker.multiitem.infantSeating.inlap.label')))), React.createElement(EGDSSpacing, {
      padding: {
        inlineend: 'six'
      }
    }, React.createElement("div", null, React.createElement(EGDSRadioButton, {
      id: "ChildInSeat",
      value: 'seat'
    }, l10n.formatText('hotels.roomPicker.multiitem.infantSeating.inseat.label'))))))), showInfantSeatingOptions && React.createElement("div", null, React.createElement(EGDSLink, {
      size: "small",
      className: "airlineAgeRules"
    }, React.createElement("a", {
      href: "/p/info-main/h_fw_default?rhtx=HTX_FLTSRCH_RT#travs",
      id: "airline-age-rules",
      target: "_blank",
      rel: "noopener"
    }, l10n.formatText('hotels.roomPicker.multiitem.airlineRules.text'), React.createElement(EGDSIcon, {
      id: "airline-age-rules-opens-in-new",
      name: "open_in_new",
      title: "Opens in a new window"
    })))), isVrbo(context) && React.createElement(PetsRadioButtonGroup, {
      enabled: this.state.selection.petsIncluded,
      onEnable: this.onIncludePets,
      onDisable: this.onExcludePets
    }), React.createElement(EGDSSpacing, {
      padding: {
        blockstart: 'two',
        blockend: 'six'
      }
    }, React.createElement("div", null)))))), React.createElement(EGDSScrim, {
      type: EGDSScrimType.BUTTON
    }, React.createElement(SubmitButtonTracked, {
      isFullWidth: true,
      size: EGDSButtonSize.LARGE,
      "data-stid": "apply-room-picker",
      linkName: "Apply Room Picker",
      onClick: this.onSubmit,
      rfrr: ":ChangeRoomGuestDone",
      subText: displaySummary
    }, submitButtonText)));
  }
  onIncludePets() {
    this.state.selection.includePets();
  }
  onExcludePets() {
    this.state.selection.excludePets();
  }
  onRoomAdultsChanged(room, e) {
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num)) {
      this.state.selection.changeAdultsForRoom(room, num);
    }
  }
  onRoomChildrenChanged(room, e) {
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num)) {
      this.state.selection.changeChildrenForRoom(room, num);
    }
  }
  onRoomInfantsChanged(room, e) {
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num)) {
      this.state.selection.changeChildrenForRoom(room, num, true);
    }
  }
  onAddRoom() {
    this.state.selection.addRoom();
    this.setState({
      justAddedRoom: true
    });
  }
  onRemoveRoom(room) {
    this.state.selection.removeRoom(room);
    this.setState({
      justRemovedRoom: true
    });
  }
  onChildAgeUpdated(roomIndex, childIndex, target) {
    const {
      analytics,
      l10n
    } = this.props;
    const age = target.value;
    this.state.selection.changeChildAge(roomIndex, childIndex, age);
    target.focus();
    liveAnnounce(l10n.formatText('hotels.roomPicker.childUpdated.liveAnnounce', childIndex, age), Level.POLITE);
    if (analytics && typeof analytics.trackEvent === 'function') {
      const roomNum = roomIndex + 1;
      const childNum = childIndex + 1;
      analytics.trackEvent(`:ROOM.${roomNum}.Child.${childNum}.AGE.${age}`, 'Child age update', null);
    }
  }
  onInfantAgeUpdated(roomIndex, childIndex, target) {
    const {
      analytics,
      l10n
    } = this.props;
    const age = target.value;
    this.state.selection.changeChildAge(roomIndex, childIndex, age, true);
    target.focus();
    liveAnnounce(l10n.formatText('hotels.roomPicker.childUpdated.liveAnnounce', childIndex, age), Level.POLITE);
    if (analytics && typeof analytics.trackEvent === 'function') {
      const roomNum = roomIndex + 1;
      const childNum = childIndex + 1;
      analytics.trackEvent(`:ROOM.${roomNum}.Infant.${childNum}.AGE.${age}`, 'Infant age update', null);
    }
  }
  onInfantSeatingChanged(event) {
    const {
      analytics
    } = this.props;
    const seatingChoice = event.target.value;
    this.setState({
      infantsInSeats: seatingChoice === InfantSeating.ON_LAP ? '0' : '1'
    });
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent(`:Infant.Seating.${seatingChoice}`, 'Infant seating update', null);
    }
  }
  resetLiveAnnounce() {
    liveAnnounce('', Level.ASSERTIVE);
  }
  onDismiss() {
    const {
      onDismiss
    } = this.props;
    this.state.selection.reset();
    this.resetLiveAnnounce();
    if (onDismiss) {
      onDismiss();
    }
  }
  onSubmit() {
    const {
      onSave
    } = this.props;
    if (onSave) {
      const {
        finalProps,
        displaySummary,
        petsIncluded
      } = this.state.selection;
      onSave(finalProps.adults, finalProps.children, displaySummary, petsIncluded, this.state.infantsInSeats);
    }
    this.onDismiss();
  }
  getRoomComponent(roomIndex, room, selection) {
    if (this.props.isPackageShoppingPath) {
      return React.createElement(MultiItemRoom, {
        analytics: this.props.analytics,
        key: roomIndex,
        l10n: this.props.l10n,
        headerRef: this.lastRoomHeader,
        onChildAgeUpdated: this.onChildAgeUpdated,
        onRoomAdultsChanged: this.onRoomAdultsChanged,
        onRoomChildrenChanged: this.onRoomChildrenChanged,
        onRoomInfantsChanged: this.onRoomInfantsChanged,
        onInfantAgeUpdated: this.onInfantAgeUpdated,
        onRemoveRoom: this.onRemoveRoom,
        room: room,
        roomIndex: roomIndex,
        selection: selection
      });
    }
    return React.createElement(Room, {
      context: this.props.context,
      analytics: this.props.analytics,
      key: roomIndex,
      l10n: this.props.l10n,
      headerRef: this.lastRoomHeader,
      onChildAgeUpdated: this.onChildAgeUpdated,
      onRoomAdultsChanged: this.onRoomAdultsChanged,
      onRoomChildrenChanged: this.onRoomChildrenChanged,
      onRemoveRoom: this.onRemoveRoom,
      room: room,
      roomIndex: roomIndex,
      selection: selection
    });
  }
};
RoomPicker.defaultRoomProps = {
  adultsString: '2',
  childrenString: ''
};
__decorate([action], RoomPicker.prototype, "onIncludePets", null);
__decorate([action], RoomPicker.prototype, "onExcludePets", null);
__decorate([action], RoomPicker.prototype, "onRoomAdultsChanged", null);
__decorate([action], RoomPicker.prototype, "onRoomChildrenChanged", null);
__decorate([action], RoomPicker.prototype, "onRoomInfantsChanged", null);
__decorate([action], RoomPicker.prototype, "onAddRoom", null);
__decorate([action], RoomPicker.prototype, "onRemoveRoom", null);
__decorate([action], RoomPicker.prototype, "onChildAgeUpdated", null);
__decorate([action], RoomPicker.prototype, "onInfantAgeUpdated", null);
__decorate([action], RoomPicker.prototype, "onInfantSeatingChanged", null);
RoomPicker = __decorate([withLocalization, inject('currentHotel', 'context', 'multiItem'), observer], RoomPicker);
export { RoomPicker };