import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { getSharedUIListingsInputsWithSearchId } from 'src/common/stores/adapters';
import { useStores } from 'src/common/stores/use-stores';
import { ResultsHeading as SharedUIResultsHeading } from '@shared-ui/lodging-property-listing';
import { useLodgingURLParamsToQueryInputs } from '@shared-ui/retail-search-tools-product';
export const ResultsAccessibilityHeading = ({
  criteriaChanged,
  isHiddenFromScreenReader
}) => {
  const {
    context,
    experiment,
    searchCriteria,
    searchResults,
    analyticsSearch,
    pageLayout
  } = useStores();
  const location = useLocation();
  const listingsQueryInputs = useLodgingURLParamsToQueryInputs();
  const searchInputs = listingsQueryInputs ? getSharedUIListingsInputsWithSearchId(listingsQueryInputs, context, analyticsSearch, location, pageLayout, searchCriteria, experiment) : undefined;
  const [inputs, setInputs] = useState(searchInputs);
  useEffect(() => {
    if (criteriaChanged) {
      setInputs(searchInputs);
    }
  }, [criteriaChanged]);
  if (!context || !searchCriteria || !searchResults || !analyticsSearch) {
    return null;
  }
  const resultData = {
    summary: {
      resultHeading: searchResults.resultsHeading
    }
  };
  return inputs ? React.createElement(SharedUIResultsHeading, {
    data: resultData,
    propertySearchListingsPwa: searchResults === null || searchResults === void 0 ? void 0 : searchResults.propertySearchListings,
    inputs: inputs,
    isHiddenFromScreenReader: isHiddenFromScreenReader
  }) : null;
};
ResultsAccessibilityHeading.displayName = 'ResultsAccessibilityHeading';