var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import * as getFormData from 'get-form-data';
import { parse, stringify } from 'query-string';
export function Form(_a) {
  var {
      handleChange,
      onSubmit,
      replace,
      wrappedFormRef,
      'data-stid': dataStid,
      children
    } = _a,
    validFormData = __rest(_a, ["handleChange", "onSubmit", "replace", "wrappedFormRef", 'data-stid', "children"]);
  const history = useHistory();
  const handleFormChange = evt => {
    if (typeof handleChange === 'function') {
      handleChange(evt);
    }
  };
  const handleSubmit = evt => {
    onSubmitOverride(evt, onSubmit, replace);
  };
  const onSubmitOverride = (event, onSubmit, replace) => {
    const {
      action
    } = validFormData;
    const formData = getFormData(event.target);
    ensureCorrectTypes(formData);
    if (onSubmit) {
      onSubmit(event, formData);
    }
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
    const queryString = stringify(formData);
    const queryParams = parse(history.location.search);
    const pwaOverlay = queryParams.pwaOverlay ? `&pwaOverlay=${queryParams.pwaOverlay}` : '';
    const submitUrl = `${action}?${queryString}${pwaOverlay}`;
    if (replace) {
      history.replace(submitUrl);
    } else {
      history.push(submitUrl);
    }
  };
  /*
    Fix known issue with get-form-data 1.2.5 library where hidden fields for element with multiple same-named inputs
    gets changed into an array. https://unpkg.com/browse/get-form-data@1.2.4/lib/index.js#L108
    Since we have all form elements defined as hidden type between the search-form and sort/filter form we run into this
    issue for radio button type elements.
  */
  const ensureCorrectTypes = formData => {
    const elementsToCorrect = [{
      key: 'infantsInSeats'
    }];
    elementsToCorrect.forEach(({
      key
    }) => {
      var _a, _b;
      const elements = formData[key];
      if (Array.isArray(elements) && ((_a = formData[key]) === null || _a === void 0 ? void 0 : _a[0])) {
        formData[key] = (_b = formData[key]) === null || _b === void 0 ? void 0 : _b[0];
      }
    });
  };
  return React.createElement("form", Object.assign({}, validFormData, {
    onChange: handleFormChange,
    onSubmit: handleSubmit,
    ref: wrappedFormRef,
    "data-stid": dataStid
  }), children);
}
Form.displayName = 'Form';