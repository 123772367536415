import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PackageType } from 'models/multi-item/multi-item-types';
import { MesoDisplayAd } from './meso-display-ad';
import { DisplayAd } from 'constants/ads';
import { InfositePageName } from 'constants/pages';
export const MesoFooter = inject('advertising')(observer(({
  advertising
}) => {
  const isPackagePath = pageName => {
    if (!pageName) return false;
    return Object.values(PackageType).some(packageName => InfositePageName[packageName] === pageName);
  };
  const HSRFooter = () => React.createElement(ErrorBoundary, {
    label: "meso.footer"
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'six'
    }
  }, React.createElement(EGDSLayoutGrid, {
    justifyItems: "center"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM1))), React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM2))), React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM3))))));
  const HISFooter = ({
    pageName
  }) => React.createElement(ErrorBoundary, {
    label: "meso.footer"
  }, React.createElement(EGDSSpacing, {
    padding: {
      block: 'four'
    }
  }, React.createElement(EGDSLayoutGrid, {
    justifyItems: "center"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM1))), React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM2))), React.createElement(EGDSLayoutGridItem, null, React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM3))), React.createElement(EGDSLayoutGridItem, null, isPackagePath(pageName) ? React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM4)) : React.createElement(MesoDisplayAd, Object.assign({}, DisplayAd.FOOTER_CM6))))));
  return Object.values(InfositePageName).some(val => val === (advertising === null || advertising === void 0 ? void 0 : advertising.triggeredPageName)) ? React.createElement(HISFooter, {
    pageName: advertising === null || advertising === void 0 ? void 0 : advertising.triggeredPageName
  }) : React.createElement(HSRFooter, null);
}));