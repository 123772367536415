import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateSearch } from 'bernie-core';
import { LineSkeletonType, ResultsSubHeader } from '@shared-ui/lodging-property-listing';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
export const ProductShoppingCriteriaInputResultsSubHeaderWrapper = () => {
  const productShoppingCriteriaInput = useProductShoppingCriteria();
  const history = useHistory();
  const location = useLocation();
  const inputs = {
    productShoppingCriteriaInput,
    destination: {
      regionName: null,
      regionId: null,
      coordinates: null,
      pinnedPropertyId: null,
      propertyIds: null,
      mapBounds: null
    },
    shoppingContext: {
      multiItem: null
    },
    returnPropertyType: false,
    criteria: null,
    dateRange: null,
    filters: null,
    marketing: null,
    propertyShopOptions: null,
    rooms: null,
    searchIntent: null,
    searchPagination: null,
    sort: null
  };
  const onChange = value => {
    updateSearch({
      history,
      location,
      newParams: {
        useRewards: String(value)
      },
      replace: true
    });
  };
  return React.createElement(ResultsSubHeader, {
    inputs: inputs,
    onRewardsToggleChange: onChange,
    switchPositionOfToggleOnEPS: false,
    skeletonLayout: [LineSkeletonType.Secondary, LineSkeletonType.Primary]
  });
};