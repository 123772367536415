import { isFromAkamaiImageManager } from 'src/common/utils/is-from-akamai-image-manager';
export var ImageQuality;
(function (ImageQuality) {
  ImageQuality["MEDIUM"] = "medium";
  ImageQuality["MEDIUM_HIGH"] = "mediumHigh";
  ImageQuality["HIGH"] = "high";
})(ImageQuality || (ImageQuality = {}));
const getHeroImageFromCardLink = (lodgingCard, propertyDetailsQueryCsrVariant) => {
  var _a, _b, _c;
  if (propertyDetailsQueryCsrVariant !== 2 || lodgingCard.__typename === 'GenericDiscoveryCard' || ((_a = lodgingCard.cardLink) === null || _a === void 0 ? void 0 : _a.resource.__typename) !== 'HttpURI') return '';
  const params = new URLSearchParams(lodgingCard.cardLink.resource.value.split('?')[1]);
  return (_c = (_b = params.get('pdpImageUrls')) === null || _b === void 0 ? void 0 : _b.split(',')[0]) !== null && _c !== void 0 ? _c : '';
};
export const preloadPdpHeroImage = (lodgingCard, deviceProps, akamaiSmartCroppingVariant = 0, mWebLargerImageVariant = 0, propertyDetailsQueryCsrVariant = 0) => {
  var _a, _b;
  const heroImageUrlFromCardLink = getHeroImageFromCardLink(lodgingCard, propertyDetailsQueryCsrVariant);
  if (heroImageUrlFromCardLink) {
    const img = new Image();
    img.src = heroImageUrlFromCardLink;
    return img;
  }
  if (lodgingCard.__typename === 'LodgingCard') {
    const media = (_b = (_a = lodgingCard.mediaSection.gallery) === null || _a === void 0 ? void 0 : _a.media[0]) === null || _b === void 0 ? void 0 : _b.media;
    if ((media === null || media === void 0 ? void 0 : media.__typename) === 'Image' && isFromAkamaiImageManager(media.url)) {
      const img = new Image();
      img.src = getPdpHeroImageUrl(media.url, deviceProps, akamaiSmartCroppingVariant, mWebLargerImageVariant);
      return img;
    }
  }
  return null;
};
const buildFcropImageUrl = (url, width, height, qualityParameter) => {
  const quality = qualityParameter ? `&q=${qualityParameter}` : '';
  return `${url.origin}${url.pathname}?impolicy=fcrop&w=${width}&h=${height}&p=0.5${quality}`;
};
export const getPdpHeroImageUrl = (mediaUrl, {
  isMobile,
  isTablet
}, akamaiSmartCroppingVariant = 0, mWebLargerImageVariant = 0, matchSrpDimensionForNonDesktop = false) => {
  if (matchSrpDimensionForNonDesktop && (isMobile || isTablet)) return mediaUrl;
  const url = new URL(mediaUrl);
  if (isMobile && mWebLargerImageVariant) {
    const width = 575;
    const height = 575;
    return `${url.origin}${url.pathname}?impolicy=resizecrop&rw=${width}&rh=${height}&ra=fill`;
  }
  if (akamaiSmartCroppingVariant) {
    let qualityParameter = null;
    const width = isMobile ? 575 : 608;
    const height = isMobile ? 323 : 342;
    switch (akamaiSmartCroppingVariant) {
      case 2:
        qualityParameter = ImageQuality.MEDIUM_HIGH;
        break;
      case 3:
        qualityParameter = ImageQuality.HIGH;
        break;
      default:
        qualityParameter = null;
        break;
    }
    return buildFcropImageUrl(url, width, height, qualityParameter);
  }
  let rw = 598;
  if (isTablet) {
    rw = 432;
  } else if (isMobile) {
    rw = 500;
  }
  return `${url.origin}${url.pathname}?impolicy=resizecrop&rw=${rw}&ra=fit`;
};