import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { ErrorBoundary, Layer } from 'bernie-view';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSTypeahead, ESSAdapter, TypeaheadRenderType } from '@egds/react-core/typeahead';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
const Origin = inject('analytics', 'context', 'multiItem')(observer(({
  context,
  model,
  multiItem,
  maxTypeaheadResults = 10,
  analytics,
  essAnalyticsLogger,
  registerValidator,
  updateModel
}) => {
  var _a;
  const history = useHistory();
  const location = useLocation();
  const {
    formatText
  } = useLocalization();
  let pristine = true;
  const fieldRef = React.createRef();
  const renderTypeaheadDialog = children => React.createElement(QueryRoute, {
    query: {
      pwaDialog: 'typeahead'
    }
  }, React.createElement(Layer, {
    id: "home-typeahead",
    onDismiss: dismissTypeahead
  }, children));
  const dismissTypeahead = renderType => {
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent('DismissOriginTypeahead', 'Origin Typeahead Modal Dismissed', undefined);
    }
    if (renderType === TypeaheadRenderType.DIALOG) {
      history.goBack();
    }
    if ((fieldRef === null || fieldRef === void 0 ? void 0 : fieldRef.current) && typeof fieldRef.current.focusTypeaheadInput === 'function') {
      fieldRef.current.focusTypeaheadInput();
    }
  };
  const openTypeahead = renderType => {
    if (renderType === TypeaheadRenderType.DIALOG) {
      updateSearch({
        history,
        location,
        newParams: {
          pwaDialog: 'typeahead'
        }
      });
    }
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent(':ChangeOrigin', 'User searching Origin', {});
    }
  };
  const handleUpdate = () => selection => {
    const {
      data,
      term
    } = selection;
    const userHistory = data && data.category === 'USERHISTORY';
    if (typeof updateModel === 'function') {
      pristine = false;
      updateModel({
        target: {
          name: 'origin',
          value: term,
          track: true,
          userHistory
        }
      });
    }
  };
  const formValidator = () => {
    pristine = false;
    return validate();
  };
  const validate = () => {
    if (pristine) {
      return;
    }
    const origin = model && model.origin;
    const focus = (fieldRef === null || fieldRef === void 0 ? void 0 : fieldRef.current) && typeof fieldRef.current.focusTypeaheadInput === 'function' && fieldRef.current.focusTypeaheadInput;
    if (!origin) {
      return {
        text: formatText('hotels.searchWizard.multiitem.validation.invalidOrigin'),
        focus
      };
    }
  };
  if (registerValidator) {
    registerValidator(formValidator);
  }
  const multiItemEssAdapterConfig = {
    dest: false,
    essLogger: essAnalyticsLogger,
    guid: context && context.deviceId,
    inputId: 'hotels-origin',
    locale: context && context.locale,
    maxResults: maxTypeaheadResults,
    siteId: context && context.site && context.site.id,
    client: 'Packages.Search',
    features: 'nearby_airport|ta_hierarchy|consistent_display',
    lob: 'PACKAGES',
    subLob: multiItem === null || multiItem === void 0 ? void 0 : multiItem.getPackageType(),
    personalize: false,
    regionType: 863,
    packageType: multiItem === null || multiItem === void 0 ? void 0 : multiItem.getPackageType()
  };
  const defaultTypeaheadProps = {
    allowExactMatch: true,
    label: formatText('hotels.searchWizard.multiitem.origin'),
    icon: 'place',
    invalid: (_a = validate()) === null || _a === void 0 ? void 0 : _a.text,
    name: 'origin',
    onClick: openTypeahead,
    onDismiss: dismissTypeahead,
    renderDialog: renderTypeaheadDialog,
    renderType: TypeaheadRenderType.ADAPTIVE,
    stid: 'trip-origin',
    value: model && model.origin,
    ref: fieldRef,
    onUpdate: handleUpdate()
  };
  return React.createElement(ErrorBoundary, {
    label: "Origin.typeahead"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null, React.createElement(Experiment, {
    name: "Remove_POI_and_Neighborhood_from_flight_and_package_typeahead"
  }, React.createElement(ExperimentControl, null, React.createElement(EGDSTypeahead, Object.assign({}, defaultTypeaheadProps, {
    adapter: new ESSAdapter(Object.assign({}, multiItemEssAdapterConfig))
  }))), React.createElement(ExperimentVariant, {
    bucket: 1
  }, React.createElement(EGDSTypeahead, Object.assign({}, defaultTypeaheadProps, {
    adapter: new ESSAdapter(Object.assign(Object.assign({}, multiItemEssAdapterConfig), {
      regionType: 839
    }))
  })))))));
}));
const FormFieldBoundComponent = FormFieldBinding(Origin);
export { FormFieldBoundComponent as Origin };