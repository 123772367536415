import { SearchClickstreamPageNameDetailed, SearchMapClickstreamPageNameDetailed } from '../../../constants/pages';
import { View } from '../../contexts/viewTracker';
export function changeView(tracker, viewTracker, nextView, packageType) {
  const {
    view,
    setView
  } = viewTracker;
  if (nextView !== view) {
    setView(nextView);
    const pageNameSet = nextView === View.LISTINGS ? SearchClickstreamPageNameDetailed : SearchMapClickstreamPageNameDetailed;
    const pageNameDetailed = packageType ? pageNameSet[packageType] : pageNameSet.SA;
    tracker.reset({
      data: {
        // eslint-disable-next-line camelcase
        experience: {
          page_name_detailed: pageNameDetailed
        }
      },
      config: {
        isEgClickstream: true
      }
    });
  }
}