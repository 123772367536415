import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutPosition } from '@egds/react-core/layout-position';
import { classNames } from '@egds/react-core/utils';
import { useRefProvider } from 'src/common/stores/contexts/ref-provider';
import { useExperiment } from '@shared-ui/experiment-context';
import { AdBanner } from '@shared-ui/retail-sponsored-content-ad-banner';
export const MesoDisplayAd = props => {
  useExperiment('MeSo_Remove_Eager_Fetch_on_client_side_ads_Web', true);
  let refIdentifier = 'meso-display-ad';
  if (props === null || props === void 0 ? void 0 : props.uci) {
    refIdentifier += `-${props.uci}`;
  }
  const [displayAd] = useRefProvider(refIdentifier);
  const adBanner = React.useMemo(() => {
    var _a;
    return React.createElement(AdBanner, {
      uci: (_a = props.uci) !== null && _a !== void 0 ? _a : '',
      displayAdRef: displayAd
    });
  }, []);
  const adSwapBanner = React.useMemo(() => {
    var _a;
    return React.createElement(AdBanner, {
      uci: (_a = props.uci) !== null && _a !== void 0 ? _a : '',
      displayAdRef: displayAd,
      adSwapEnabled: props.adSwapEnabled,
      isEnabled: props.isEnabled
    });
  }, [displayAd, props.isEnabled, props.adSwapEnabled, props.uci]);
  const className = classNames('ad-div', 'display-ad', props.className && {
    [props.className]: props.className
  });
  return React.createElement(ErrorBoundary, {
    label: "meso.display.ad"
  }, React.createElement(EGDSLayoutPosition, {
    type: "relative",
    className: className
  }, props.adSwapEnabled ? adSwapBanner : adBanner));
};
MesoDisplayAd.displayName = 'MesoDisplayAd';