export const getAdultsAndChildrenFromRooms = rooms => ({
  adults: getAdultsFromRooms(rooms),
  children: getChildrenFromRooms(rooms)
});
export const getAdultsFromRooms = rooms => rooms.map(room => room.adults).filter(adult => adult > 0).join(',');
export const getChildrenFromRooms = rooms => rooms.map((room, index) => {
  var _a;
  return convertRoomChildrenToString(index + 1, ((_a = room.children) === null || _a === void 0 ? void 0 : _a.map(child => child.age)) || []);
}).filter(child => child !== '').join(',');
export const convertRoomChildrenToString = (roomNumber, ages) => {
  const result = ages.map(age => `${roomNumber}_${age}`).join(',');
  return result;
};