/* eslint-disable max-lines */
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { Layer } from 'bernie-view';
import { EGDSFauxInput, EGDSTextInput } from '@egds/react-core/input';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSheetTransition } from '@egds/react-core/sheet';
import { PropertyFilterAmenity } from 'src/common/__generated__/api';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { LocalizedText, withLocalization } from '@shared-ui/localization-context';
import { ChildModel } from 'stores/models/child-model';
import { RoomModel } from 'stores/models/room-model';
import { RoomPicker } from 'components/common/room-picker/room-picker';
import { RoomPickerModel } from 'components/common/room-picker/room-picker-model';
import { FormFieldBinding } from './form-field-binding';
let Travelers = class Travelers extends React.Component {
  constructor(props) {
    var _a, _b;
    super(props);
    this.focusButton = React.createRef();
    this.fieldValues = state => {
      const {
        adults,
        children,
        infantsInSeats
      } = state;
      return React.createElement(React.Fragment, null, adults && React.createElement("input", {
        type: "hidden",
        name: "adults",
        value: adults,
        readOnly: true
      }), children && React.createElement("input", {
        type: "hidden",
        name: "children",
        value: children,
        readOnly: true
      }), infantsInSeats && React.createElement("input", {
        type: "hidden",
        name: "infantsInSeats",
        value: infantsInSeats,
        readOnly: true
      }));
    };
    const guestCount = (_b = (_a = this.props.model) === null || _a === void 0 ? void 0 : _a.guestCount) === null || _b === void 0 ? void 0 : _b.toString();
    this.state = {
      adults: guestCount,
      children: '',
      mounted: false,
      value: ''
    };
    this.isPackageShoppingPath = props.multiItem && props.multiItem.isPackageShoppingPath();
    this.roomPickerModel = new RoomPickerModel({
      adults: '',
      children: ''
    }, props.l10n, props.context, this.isPackageShoppingPath);
    this.convertRoomConfigToRoomModel = this.convertRoomConfigToRoomModel.bind(this);
    this.convertRoomModelToRoomConfig = this.convertRoomModelToRoomConfig.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.open = this.open.bind(this);
    this.openDatepicker = this.openDatepicker.bind(this);
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
    this.parseRooms = this.parseRooms.bind(this);
    this.updatePetsSelection = this.updatePetsSelection.bind(this);
    props.registerValidator && props.registerValidator(() => this.validate('children'));
    props.registerValidator && props.registerValidator(() => this.validate('adults'));
  }
  open() {
    const {
      analytics,
      history,
      location
    } = this.props;
    updateSearch({
      history,
      location,
      newParams: {
        pwaDialog: 'travelerPicks'
      }
    });
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent(':ChangeRoomGuest', 'Open Room Picker', null);
    }
  }
  openDatepicker() {
    const {
      analytics,
      history,
      location
    } = this.props;
    updateSearch({
      history,
      location,
      newParams: {
        pwaDialog: 'datepicker-start'
      }
    });
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent(':OpenDatepickerRoomGuest', 'Open Datepicker from Room Guest', null);
    }
  }
  save(adults, children, value, petsIncluded, infantsInSeats) {
    const {
      context,
      model,
      updateModel
    } = this.props;
    this.roomPickerModel.setupRooms(adults, children);
    const {
      finalProps,
      rooms
    } = this.roomPickerModel;
    if (typeof updateModel === 'function') {
      updateModel({
        target: {
          name: 'rooms',
          value: this.convertRoomConfigToRoomModel(rooms)
        }
      });
      if (infantsInSeats) {
        updateModel({
          target: {
            name: 'infantsInSeats',
            value: infantsInSeats
          }
        });
      }
      if (isVrbo(context)) {
        let amenities = [...model.amenities];
        const index = amenities.indexOf(PropertyFilterAmenity.PETS);
        if (petsIncluded && index < 0) {
          amenities.push(PropertyFilterAmenity.PETS);
        } else if (!petsIncluded && index > -1) {
          amenities = amenities.filter(amenity => amenity !== PropertyFilterAmenity.PETS);
        }
        updateModel({
          target: {
            name: 'amenities',
            value: amenities
          }
        });
      }
    }
    if (finalProps.adults && finalProps.children) {
      this.setState({
        adults: finalProps.adults,
        children: finalProps.children,
        value,
        infantsInSeats
      });
    }
  }
  dismiss() {
    this.props.history.goBack();
    // manage focus back to the trigger
    const focusButton = this.focusButton;
    if (focusButton.current && typeof focusButton.current.focus === 'function') {
      focusButton.current.focus();
    }
  }
  convertRoomModelToRoomConfig(rooms) {
    return rooms.map(room => {
      var _a;
      return {
        adults: room.adults,
        children: (_a = room.children) === null || _a === void 0 ? void 0 : _a.map(child => child.age)
      };
    });
  }
  convertRoomConfigToRoomModel(rooms) {
    return rooms.map(room => new RoomModel({
      adults: room.adults,
      children: room.children.map(child => new ChildModel({
        age: child
      }))
    }));
  }
  parseRooms(rooms) {
    if (!rooms) {
      return;
    }
    this.roomPickerModel.rooms = this.convertRoomModelToRoomConfig(rooms);
    const {
      displaySummary,
      finalProps
    } = this.roomPickerModel;
    return {
      adults: finalProps.adults,
      children: finalProps.children,
      value: displaySummary
    };
  }
  get petsIncluded() {
    var _a, _b;
    return ((_b = (_a = this.props.model) === null || _a === void 0 ? void 0 : _a.amenities) === null || _b === void 0 ? void 0 : _b.includes(PropertyFilterAmenity.PETS)) || false;
  }
  updatePetsSelection() {
    this.petsIncluded ? this.roomPickerModel.includePets() : this.roomPickerModel.excludePets();
  }
  componentDidMount() {
    const {
      rooms,
      infantsInSeats
    } = this.props.model;
    this.updatePetsSelection();
    this.setState(Object.assign(Object.assign({}, this.parseRooms(rooms)), {
      mounted: true,
      infantsInSeats
    }));
  }
  componentDidUpdate() {
    this.updatePetsSelection();
  }
  componentWillReceiveProps(nextProps) {
    const {
      rooms,
      infantsInSeats
    } = nextProps.model;
    this.setState(Object.assign(Object.assign({}, this.parseRooms(rooms)), {
      infantsInSeats
    }));
  }
  getLabel() {
    return this.isPackageShoppingPath ? 'hotels.travelers.multiitem.dialog.label' : 'hotels.search.form.travelers.label';
  }
  render() {
    const {
      id,
      name,
      placeholder,
      updateModel,
      hidden
    } = this.props;
    const {
      adults,
      mounted,
      value
    } = this.state;
    const idBtn = `${id}__btn`;
    const adjustedAdults = parseInt(adults, 10) > RoomPickerModel.MAX_GUESTS ? RoomPickerModel.MAX_GUESTS.toString() : adults;
    // If mounted, use summary, otherwise just use adults=
    const renderedValue = mounted ? value : adjustedAdults;
    const buttonText = this.props.buttonText;
    const renderLabel = () => React.createElement(React.Fragment, null, React.createElement(LocalizedText, {
      message: this.getLabel()
    }), React.createElement("span", {
      className: "is-visually-hidden"
    }, renderedValue));
    const inputClassNames = [];
    if (hidden) {
      return this.fieldValues(this.state);
    }
    if (mounted) {
      inputClassNames.push('is-hidden');
    }
    return React.createElement(React.Fragment, null, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null, React.createElement(EGDSTextInput, {
      id: id,
      name: mounted ? name : 'adults',
      className: inputClassNames.join(' '),
      label: renderLabel(),
      placeholder: placeholder,
      value: renderedValue,
      onChange: updateModel,
      "data-stid": "input-date",
      icon: "person",
      invalid: mounted && (this.validate('adults') || this.validate('children'))
    }, mounted && React.createElement(EGDSFauxInput, {
      domRef: this.focusButton,
      id: idBtn,
      type: "button",
      "data-stid": "open-room-picker",
      onClick: this.open
    }, React.createElement("span", {
      className: "is-visually-hidden"
    }, React.createElement(LocalizedText, {
      message: this.getLabel()
    }), ' '), renderedValue)))), mounted && this.fieldValues(this.state), React.createElement(QueryRoute, {
      query: {
        pwaDialog: 'travelerPicks'
      }
    }, React.createElement(EGDSSheetTransition, {
      isVisible: true
    }, React.createElement(Layer, {
      id: "home-travelerpicks",
      onDismiss: this.dismiss
    }, React.createElement(RoomPicker, {
      adultsString: this.state.adults,
      childrenString: this.state.children,
      petsIncluded: this.petsIncluded,
      infantsInSeats: this.state.infantsInSeats,
      onSave: this.save,
      onDismiss: this.dismiss,
      analytics: this.props.analytics,
      buttonText: buttonText,
      isPackageShoppingPath: this.isPackageShoppingPath
    })))));
  }
  validate(key) {
    const {
      l10n
    } = this.props;
    const {
      adults,
      children
    } = this.state;
    const {
      formatText
    } = l10n;
    const numAdults = adults.split(',').map(parseInt)
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    .reduce((a, b) => a + b);
    if (key === 'adults') {
      if (!adults) {
        return formatText('hotels.search.validation.invalidGuests.text');
      }
      if (numAdults <= 0) {
        return formatText('hotels.search.validation.invalidGuests.text');
      }
    }
    if (key === 'children') {
      if (!children) {
        return;
      }
      const childAges = children.split(',');
      const infantsArr = childAges.filter(age => {
        var _a;
        return parseInt((_a = age.split('_')[1]) !== null && _a !== void 0 ? _a : '', 10) < 2;
      });
      let message = childAges.length === 1 ? formatText('hotels.search.validation.missingChildAge.text') : formatText('hotels.search.validation.missingChildrenAges.text');
      let error = false;
      const childAgeOnly = [];
      childAges.forEach(child => {
        if (!child.match(/\d{1,2}_\d{1,2}/)) {
          // i.e. roomNumber_childAge, e.g. 1_4
          error = true;
        }
        const splitChild = child.split('_')[1];
        if (splitChild) {
          childAgeOnly.push(parseInt(splitChild, 10));
        }
      });
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const supervisors = childAgeOnly.filter(age => age >= 6).length + numAdults;
      if (this.isPackageShoppingPath && infantsArr.length > supervisors) {
        error = true;
        message = formatText('hotels.travelers.multiitem.validation.maxInfants');
      }
      return error ? message : undefined;
    }
  }
};
__decorate([computed], Travelers.prototype, "petsIncluded", null);
Travelers = __decorate([withRouter
// @ts-ignore
, withLocalization, FormFieldBinding, inject('analytics', 'context', 'multiItem'), observer], Travelers);
export { Travelers };