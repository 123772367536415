import { FlightCabinClass, FlightNonStopCheckbox, OriginWrapper, PartialStayCheckbox, PartialStayDateRange } from 'components/multi-item/search-wizard';
import { DateRange } from './date-range';
import { LocationWrapper } from './location-tnl-wrapper';
import { Travelers } from './travelers';
export const fieldMap = {
  origin: OriginWrapper,
  destination: LocationWrapper,
  dateRange: DateRange,
  rooms: Travelers,
  cabinClass: FlightCabinClass,
  directFlights: FlightNonStopCheckbox,
  partialStayCheckbox: PartialStayCheckbox,
  partialStayDateRange: PartialStayDateRange
};