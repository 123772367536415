import * as React from 'react';
import { EGDSSelect } from '@egds/react-core/input';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { StaticTimeSelectOptions } from './car-pickup-dropoff-time-options';
export const CarPickUpDropOffTimeSelect = React.memo(({
  label,
  id,
  invalid,
  value,
  formattedDate = '\u00A0',
  onChange,
  l10n,
  domRef = null
}) => React.createElement("div", {
  id: `${id}-label`,
  className: "multi-item-time-select"
}, React.createElement(EGDSSpacing, {
  padding: {
    block: 'two'
  }
}, React.createElement("div", null, React.createElement(EGDSText, {
  align: "left",
  size: 300
}, formattedDate))), React.createElement(EGDSSelect, {
  "aria-labelledby": `${id}-label`,
  icon: "access_time",
  name: id,
  label,
  value,
  invalid,
  domRef,
  onChange,
  id
}, React.createElement(StaticTimeSelectOptions, {
  l10n: l10n
}))));