export const getParentBrandFromBrand = brand => PARENT_BRAND_MAP[brand] || 'unknown';
const PARENT_BRAND_MAP = {
  airnewzealand: 'expedia',
  cheaptickets: 'expedia',
  ebookers: 'expedia',
  expedia: 'expedia',
  hawaiianairlines: 'expedia',
  hotwire: 'expedia',
  lastminute: 'expedia',
  mrjet: 'expedia',
  orbitz: 'expedia',
  travelocity: 'expedia',
  wotif: 'expedia',
  vrbo: 'vrbo',
  hotels: 'hotels',
  taap: 'taap'
};