import * as React from 'react';
import { useHistory } from 'react-router';
import { inject } from 'mobx-react';
import { handleFilterPillRemoval, handleRemoveAllFilters } from 'src/common/utils/property-listing-utils';
import { PropertyListing } from '@shared-ui/lodging-property-listing';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
export const ShoppingListingWrapper = inject('context', 'multiItem', 'analytics')(({
  context,
  multiItem,
  analytics
}) => {
  if (!context || !multiItem) return null;
  const history = useHistory();
  const onFilterPillRemoval = pill => handleFilterPillRemoval(pill, history);
  const onRemoveAllFilters = action => handleRemoveAllFilters(action, analytics, history);
  /**
   * Here is an example of how we'd use the hooks to get the search criteria for any component
   * and pass it to the Shared UI components that need it
   */
  const productShoppingCriteriaInput = useProductShoppingCriteria();
  const inputs = {
    productShoppingCriteriaInput,
    destination: {
      regionName: null,
      regionId: null,
      coordinates: null,
      pinnedPropertyId: null,
      propertyIds: null,
      mapBounds: null
    },
    shoppingContext: {
      multiItem: null
    },
    returnPropertyType: false,
    criteria: null,
    dateRange: null,
    filters: null,
    marketing: null,
    propertyShopOptions: null,
    rooms: null,
    searchIntent: null,
    searchPagination: null,
    sort: null
  };
  return React.createElement(PropertyListing, {
    inputs: inputs,
    onFilterPillRemoval: onFilterPillRemoval,
    onRemoveAllFilters: onRemoveAllFilters
  });
});