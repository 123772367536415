import * as React from 'react';
export function FormFieldBinding(WrappedComponent) {
  var _a;
  return _a = class extends React.Component {
    constructor() {
      super(...arguments);
      this.updateModel = event => {
        const {
          model,
          updateModelCallback
        } = this.props;
        const target = event && event.target;
        const name = target && target.name;
        const value = target && target.value;
        const userHistory = target && target.userHistory;
        const oldValue = model && model[name];
        // do not check for value
        // value could reset part of the model: '', undefined, null
        if (model && name) {
          // update state via mobx
          // @ts-ignore
          model[name] = value;
        }
        if (typeof updateModelCallback === 'function') {
          updateModelCallback(event);
        }
        if (target && target.track === true) {
          this.trackUpdate(name, value, oldValue, userHistory);
        }
      };
      this.registerValidator = validator => {
        const {
          validators
        } = this.props;
        if (validators) {
          validators.push(validator);
        }
      };
    }
    trackUpdate(name, value, oldValue, userHistory) {
      const {
        analytics
      } = this.props;
      const pristine = value === oldValue ? '.(pristine)' : '';
      const recentSearchesEvent = name === 'destination' && userHistory;
      if (analytics && typeof analytics.trackEvent === 'function') {
        if (recentSearchesEvent) {
          analytics.trackEvent(`:RecentSearches`, `${name} has changed from Typeahead Recent Searches`, null);
        } else {
          analytics.trackEvent(`.${name}.${value}${pristine}`, `${name} has changed`, null);
        }
      }
    }
    render() {
      return React.createElement(WrappedComponent, Object.assign({
        updateModel: this.updateModel,
        registerValidator: this.registerValidator
      }, this.props));
    }
  }, _a.wrappedComponent = WrappedComponent, _a;
}