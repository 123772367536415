/* eslint max-lines: "off" */
import { mergeQueryString } from 'bernie-core';
import { parse } from 'query-string';
import { ParamsKeys } from '@shared-ui/retail-search-tools-product';
import { SortAndFiltersCustomEventTypes, SortAndFiltersParamsKeys, SortAndFiltersRangeParamKey } from '@shared-ui/retail-sort-and-filter-lodging';
import { ClearableCruiseFilterParams, ClearableGTFilterParams, ProductShoppingCriteriaInputActionType } from '@shared-ui/shopping-search-criteria-providers';
import { clPropertyFilters, vrPropertyTypeFilters } from './universal-sort-filter-definitions';
const universalSortFilterKeys = [...Object.values(SortAndFiltersParamsKeys), ...Object.values(SortAndFiltersRangeParamKey), ...Object.values(ClearableCruiseFilterParams), ...Object.values(ClearableGTFilterParams)];
const universalSortFilterPriceKey = Object.values(SortAndFiltersRangeParamKey);
export const mergeQueryKey = key => name => !key.startsWith(name);
// keeps keys that are not universal sort and filter keys
const keepNonUniversalSortFilterKeys = key => !universalSortFilterKeys.includes(key);
// keeps keys other than universal sort and filter price key
const shouldRemoveUniversalSortFiltersPriceKey = key => !universalSortFilterPriceKey.includes(key);
// keeps keys that are not universal sort and filter keys and sort
const keepNonUniversalSortFilterKeysAndSort = key => !universalSortFilterKeys.includes(key) || key === SortAndFiltersParamsKeys.SORT;
const unboundedPriceRangeValue = -1;
const replaceQueryByName = (existingUrlQueryString, name, value) => {
  const regex = new RegExp(`${name}=[^&]+`);
  return existingUrlQueryString.replace(regex, `${name}=${value}`);
};
/**
 * When in Cruise LOB, override the default selection handler to support multiple typeahead selections
 */
export const getMultiTypeaheadProps = productShoppingCriteriaDispatch => ({
  onSelectionChange() {
    // Do nothing. onSelectionChange handler needs to be present to override default behavior
  },
  onMultiSelectClose(item, filterId, selections) {
    productShoppingCriteriaDispatch({
      type: ProductShoppingCriteriaInputActionType.MULTI_SELECT_CLOSE,
      filterId,
      selections
    });
  },
  onMultiSelectClearAll(filterId) {
    productShoppingCriteriaDispatch({
      type: ProductShoppingCriteriaInputActionType.MULTI_SELECT_CLEAR_ALL,
      filterId
    });
  }
});
// filter out AI Search Key
const shouldRemoveAISearchKey = key => key !== SortAndFiltersParamsKeys.AI_SEARCH_QUERY;
// eslint-disable-next-line complexity
export const buildUrlQueryString = ({
  name,
  value,
  existingUrlQueryString,
  stayOptionExperimentExposure,
  event,
  defaults
}) => {
  var _a, _b, _c;
  let resultingUrlQueryString;
  let newUrlQueryParameters;
  const eventTargetType = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.type;
  const eventTargetChecked = (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.checked;
  const urlQueryString = resetCategorySearch({
    existingUrlQueryString,
    name,
    value
  });
  const parsedUrlQueryString = parse(urlQueryString);
  switch (name) {
    case SortAndFiltersCustomEventTypes.CLEAR:
      // only set the regionId and neighborhood to default if there's a neighborhood filter selected
      if (defaults === null || defaults === void 0 ? void 0 : defaults.regionId) {
        newUrlQueryParameters = {
          [ParamsKeys.REGION_ID]: defaults.regionId,
          [SortAndFiltersParamsKeys.NEIGHBORHOOD]: defaults.regionId
        };
      } else {
        newUrlQueryParameters = {};
      }
      // takes the existing query string and filters out USF filters
      resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, keepNonUniversalSortFilterKeys);
      return resultingUrlQueryString;
    case SortAndFiltersCustomEventTypes.CLEAR_QUICK_FILTERS:
      {
        // takes the existing query string and filters out USF filters
        const params = new URLSearchParams(urlQueryString);
        value.forEach(field => {
          if (field.value === undefined) {
            for (const [key, value] of params.entries()) {
              if (value === field.key) {
                params.delete(key);
              }
            }
            params.delete(field.key);
          } else {
            const remainingKeyValues = params.getAll(field.key).filter(keyValue => keyValue !== field.value);
            params.delete(field.key);
            for (const remainingKeyValue of remainingKeyValues) params.append(field.key, remainingKeyValue);
          }
        });
        resultingUrlQueryString = params.toString();
        return resultingUrlQueryString;
      }
    case SortAndFiltersCustomEventTypes.CLEAR_FILTERS_APPLIED:
      {
        // takes the existing query string and filters out USF filters
        const params = new URLSearchParams(urlQueryString);
        value.forEach(field => {
          if (field.value === undefined) {
            params.delete(field.id);
          } else {
            const remainingKeyValues = params.getAll(field.id).filter(keyValue => keyValue !== field.value);
            params.delete(field.id);
            for (const remainingKeyValue of remainingKeyValues) params.append(field.id, remainingKeyValue);
          }
        });
        resultingUrlQueryString = params.toString();
        return resultingUrlQueryString;
      }
    case SortAndFiltersCustomEventTypes.RESTORE:
      return '';
    case SortAndFiltersParamsKeys.PROPERTY_NAME:
      newUrlQueryParameters = {
        [SortAndFiltersParamsKeys.HOTEL_NAME]: value.hotelName,
        [ParamsKeys.SELECTED]: value.selected
      };
      resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, shouldRemoveAISearchKey);
      return resultingUrlQueryString;
    case ParamsKeys.REGION_ID:
      newUrlQueryParameters = {
        [name]: value,
        [SortAndFiltersParamsKeys.NEIGHBORHOOD]: value
      };
      resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, shouldRemoveAISearchKey);
      return resultingUrlQueryString;
    case SortAndFiltersRangeParamKey.NIGHTLY_PRICE:
    case SortAndFiltersRangeParamKey.TOTAL_PRICE:
      {
        if (value.min === unboundedPriceRangeValue && value.max === unboundedPriceRangeValue) {
          // remove total price param from existingUrlQueryString
          newUrlQueryParameters = {
            [name]: undefined
          };
          resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters);
          return resultingUrlQueryString;
        }
        newUrlQueryParameters = {
          [name]: [value.min, value.max]
        }; // mergeQueryString's ParamOptions interface should allow string[] for the param value
        resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, mergeQueryKey(name));
        resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
        return resultingUrlQueryString;
      }
    case SortAndFiltersRangeParamKey.PRICE:
      {
        if (value.min === unboundedPriceRangeValue && value.max === unboundedPriceRangeValue) {
          resultingUrlQueryString = mergeQueryString(urlQueryString, {}, shouldRemoveUniversalSortFiltersPriceKey);
          resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
          return resultingUrlQueryString;
        }
        newUrlQueryParameters = {
          [name]: [value.min, value.max]
        }; // mergeQueryString's ParamOptions interface should allow string[] for the param value
        resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, mergeQueryKey(name));
        resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
        return resultingUrlQueryString;
      }
    case SortAndFiltersParamsKeys.NEIGHBORHOOD:
      {
        resultingUrlQueryString = removePoiFilter(urlQueryString);
        const shouldReplaceParam = parsedUrlQueryString ? 'neighborhood' in parsedUrlQueryString : false;
        if (shouldReplaceParam) {
          resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
          return replaceQueryByName(resultingUrlQueryString, name, value);
        }
        resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
        return resultingUrlQueryString.concat(`&${name}=${value}`);
      }
    case SortAndFiltersParamsKeys.STAY_OPTIONS_GROUP:
      {
        const params = new URLSearchParams(urlQueryString);
        const newParams = new URLSearchParams();
        if (value === 'hotels_option') {
          for (const [key, currentValue] of params.entries()) {
            if (!vrPropertyTypeFilters.has(currentValue)) {
              newParams.append(key, currentValue);
            }
          }
          if ((stayOptionExperimentExposure === null || stayOptionExperimentExposure === void 0 ? void 0 : stayOptionExperimentExposure.bucket) === 1 || (stayOptionExperimentExposure === null || stayOptionExperimentExposure === void 0 ? void 0 : stayOptionExperimentExposure.bucket) === 2) {
            newParams.delete('bedroomFilter');
          }
          newParams.set('stay_options_group', 'hotels_option');
        } else if (value === 'vacation_rentals_option') {
          for (const [key, currentValue] of params.entries()) {
            if (!clPropertyFilters.has(currentValue)) {
              newParams.append(key, currentValue);
            }
          }
          newParams.delete('hotel_brand');
          if ((stayOptionExperimentExposure === null || stayOptionExperimentExposure === void 0 ? void 0 : stayOptionExperimentExposure.bucket) === 1 || (stayOptionExperimentExposure === null || stayOptionExperimentExposure === void 0 ? void 0 : stayOptionExperimentExposure.bucket) === 2) {
            newParams.delete('mealPlan');
            newParams.delete('paymentType');
          }
          newParams.set('stay_options_group', 'vacation_rentals_option');
        } else if (value === '') {
          params.delete('stay_options_group');
          params.delete(SortAndFiltersParamsKeys.AI_SEARCH_QUERY);
          return params.toString();
        }
        newParams.delete(SortAndFiltersParamsKeys.AI_SEARCH_QUERY);
        return newParams.toString();
      }
    case SortAndFiltersParamsKeys.AI_SEARCH_QUERY:
      return mergeQueryString(existingUrlQueryString, {
        [name]: value
      }, keepNonUniversalSortFilterKeysAndSort);
    // hard code check to remove amenties = pets and house rules pets allowed
    // when pets allowed is removed from filter modal
    default:
      {
        // checkbox specific logic to support multi selection
        if (eventTargetType === 'checkbox') {
          // deselection (if "value" for given "name" found in the existing URL)
          let currentValue = (_c = parsedUrlQueryString[name]) !== null && _c !== void 0 ? _c : [];
          if (typeof currentValue === 'string') currentValue = [currentValue];
          if (!eventTargetChecked) {
            currentValue = Array.isArray(currentValue) ? currentValue.filter(current => current !== value) : [];
            newUrlQueryParameters = {
              [name]: currentValue
            };
            resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters, shouldRemoveAISearchKey);
            // check for amenities = pets
            const amenities = 'amenities';
            if (resultingUrlQueryString.includes(amenities)) {
              resultingUrlQueryString = resultingUrlQueryString.replace('amenities=PETS', '');
            }
            resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
            return resultingUrlQueryString;
          }
          // selection
          resultingUrlQueryString = mergeQueryString(urlQueryString, {});
          resultingUrlQueryString = resultingUrlQueryString.concat(`&${name}=${value}`);
          if (name === SortAndFiltersParamsKeys.MULTI_NEIGHBORHOOD_GROUP) {
            resultingUrlQueryString = removePoiFilter(resultingUrlQueryString);
          }
          resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
          return resultingUrlQueryString;
        }
        // everything else (i.e. a radio, stepper input)
        newUrlQueryParameters = {
          [name]: value
        };
        resultingUrlQueryString = mergeQueryString(urlQueryString, newUrlQueryParameters);
        resultingUrlQueryString = mergeQueryString(resultingUrlQueryString, {}, shouldRemoveAISearchKey);
        return resultingUrlQueryString;
      }
  }
};
const removePoiFilter = urlQueryString => {
  const params = new URLSearchParams(urlQueryString);
  params.delete(SortAndFiltersParamsKeys.POI);
  return params.toString();
};
const isNeighborhoodOrRegionIdFilter = name => {
  const keys = [SortAndFiltersParamsKeys.NEIGHBORHOOD, SortAndFiltersParamsKeys.NEIGHBORHOOD_GROUP, SortAndFiltersParamsKeys.MULTI_NEIGHBORHOOD_GROUP, ParamsKeys.REGION_ID];
  return keys.includes(name);
};
const isNeighborhoodFilterAppliedDuringClearEvent = (parsedExistingUrlQueryParams, {
  name,
  value
}) => {
  const isFieldMatchingNeighborhood = field => {
    const keyOrId = field.key || field.id;
    return isNeighborhoodOrRegionIdFilter(keyOrId);
  };
  // for simple clear event, value is not available, so we need to check the existing query string
  // if neighborhood filters are applied
  if (name === SortAndFiltersCustomEventTypes.CLEAR) {
    return parsedExistingUrlQueryParams.neighborhood || parsedExistingUrlQueryParams.neighborhood_group || parsedExistingUrlQueryParams.multi_neighborhood_group;
  }
  const isClearFiltersEvent = name === SortAndFiltersCustomEventTypes.CLEAR_QUICK_FILTERS || name === SortAndFiltersCustomEventTypes.CLEAR_FILTERS_APPLIED;
  return isClearFiltersEvent && (value === null || value === void 0 ? void 0 : value.some(isFieldMatchingNeighborhood));
};
const resetCategorySearch = ({
  existingUrlQueryString,
  name,
  value
}) => {
  const parsedExistingUrlQueryParams = parse(existingUrlQueryString);
  const isCategorySearchAndFilterApplied = 'categorySearch' in parsedExistingUrlQueryParams && isNeighborhoodOrRegionIdFilter(name);
  // Reset categorySearch param when neighborhood or RegionId filters are included/excluded from the search
  if (isCategorySearchAndFilterApplied || isNeighborhoodFilterAppliedDuringClearEvent(parsedExistingUrlQueryParams, {
    name,
    value
  })) {
    return mergeQueryString(existingUrlQueryString, {
      categorySearch: ''
    });
  }
  return existingUrlQueryString;
};
export const removeSelectedFiltersQuery = (nextSearchCriteria, existingUrlQueryString) => {
  let resultingUrlQueryString = '';
  const {
    ranges,
    selections
  } = nextSearchCriteria;
  const selection = selections === null || selections === void 0 ? void 0 : selections.find(Boolean);
  if ((selection === null || selection === void 0 ? void 0 : selection.id) === SortAndFiltersParamsKeys.NEIGHBORHOOD) {
    resultingUrlQueryString = buildUrlQueryString({
      name: ParamsKeys.REGION_ID,
      value: selection.value,
      existingUrlQueryString
    });
  } else {
    resultingUrlQueryString = buildUrlQueryString({
      name: SortAndFiltersCustomEventTypes.CLEAR_FILTERS_APPLIED,
      value: ranges !== null && ranges !== void 0 ? ranges : selections,
      existingUrlQueryString
    });
  }
  return resultingUrlQueryString;
};
/* When neighborhood filter is unselected from 'Your recent filters', trigger the click event for the same filter added to the filter pills
   This would fall back the search to the parent multicity which is handled in the pill removal event handler */
export const handleNeighborhoodPreviousFilter = (field, event, isTrustedEvent) => {
  var _a;
  if (isTrustedEvent && field && field.name === 'neighborhood' && ((_a = event.target) === null || _a === void 0 ? void 0 : _a.type) === 'checkbox' && !event.target.checked) {
    const domElement = document.getElementById(`${field.name}_${field.value}`);
    if (domElement) {
      domElement.click();
      return true;
    }
  }
  return false;
};
// Used to set additional URL params when a filter is changed
export const setUrlParamsOnFilterChange = (urlQueryString, newParams) => {
  const params = new URLSearchParams(urlQueryString);
  newParams.forEach(param => {
    params.set(param.name, param.value);
  });
  return params.toString();
};