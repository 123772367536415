import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { ShoppingSelectableCardState } from '../__generated__/api/types';
export default function useQueryParams() {
  return parse(useLocation().search);
}
export function normalizeQueryParams(queryParams) {
  return Object.fromEntries(Object.entries(queryParams).map(([key, value]) => [key.toLowerCase(), value]));
}
export const updateCategorySearchQueryParam = (categoryImageCard, location) => {
  const {
    state,
    id
  } = categoryImageCard;
  const newParams = new URLSearchParams(location.search);
  newParams.set('categorySearch', state === ShoppingSelectableCardState.SELECTED ? id : '');
  return newParams.toString();
};