var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
/* eslint-env browser */
import * as React from 'react';
import { comparer, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
// @ts-ignore
import * as debounce from 'lodash.debounce';
import { ClientLogger } from 'bernie-client';
import { merge } from 'bernie-core';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSButtonSize, EGDSPrimaryButton } from '@egds/react-core/button';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSText } from '@egds/react-core/text';
import { PackageType } from 'models/multi-item/multi-item-types';
import { EssClientLogger } from 'typeahead-client-logger';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { LocalizedText, withLocalization } from '@shared-ui/localization-context';
import { ViewLarge, ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { Form } from 'components/common/form';
import { CarPickupDropOffTimeRange } from 'components/multi-item/search-wizard/car-pickup-dropoff-time-range';
import { fieldMap } from './fields/all-fields';
import { getGridProps } from './grid-item-props';
const logger = ClientLogger.getLoggerWithIdentifier('pwa.component.searchform');
const {
  withoutKeys
} = merge;
const ButtonTracked = ClickTracker(EGDSPrimaryButton);
const PACAKAGE_DEBOUNCE_TIMEOUT = 2000;
const SA_DEBOUNCE_TIMEOUT = 200;
// NOTE: BFF may provide the keys in the future
export const alwaysIgnoredKeys = ['changedFilters', 'logger', 'comparePropertyIds', 'compareDialog'];
// @ts-ignore
let SearchForm = class SearchForm extends React.Component {
  constructor(props) {
    var _a, _b, _c, _d;
    super(props);
    this.validators = [];
    const model = props.model ? props.model.clone() : new SearchCriteriaModel({}, this.props.l10n);
    this.state = {
      model,
      submitDisabled: false,
      mounted: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getFormRef = this.getFormRef.bind(this);
    this.dispatchSubmitEvent = debounce(this.dispatchSubmitEvent.bind(this), ((_b = (_a = this.props.multiItem) === null || _a === void 0 ? void 0 : _a.multiItemContext) === null || _b === void 0 ? void 0 : _b.packageType) && !props.isMobile ? PACAKAGE_DEBOUNCE_TIMEOUT : SA_DEBOUNCE_TIMEOUT, {
      trailing: true
    });
    this.essAnalyticsLogger = new EssClientLogger(props.analytics, ClientLogger, (model || {}).destination, (_d = (_c = this.props.l10n) === null || _c === void 0 ? void 0 : _c.experimentStore) === null || _d === void 0 ? void 0 : _d.exposures);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.model !== this.props.model) {
      this.setState({
        model: nextProps.model ? nextProps.model.clone() : new SearchCriteriaModel({}, this.props.l10n)
      });
    }
  }
  componentDidMount() {
    this.setState({
      mounted: true
    });
  }
  get hiddenFields() {
    const {
      displayFields,
      ignoreFields
    } = this.props;
    const {
      model,
      mounted
    } = this.state;
    const excludedKeys = [];
    if (!mounted) {
      excludedKeys.push('latLong', 'regionId');
    }
    return Object.keys(withoutKeys([...alwaysIgnoredKeys, ...(ignoreFields !== null && ignoreFields !== void 0 ? ignoreFields : []), ...(displayFields !== null && displayFields !== void 0 ? displayFields : []), ...excludedKeys]).merge({}, model));
  }
  get modelDefaults() {
    const modelDefaults = SearchCriteriaModel && SearchCriteriaModel.DEFAULTS;
    return modelDefaults || {};
  }
  get displayFieldsActive() {
    const {
      model
    } = this.state;
    const {
      displayFields
    } = this.props;
    const displayFieldsActive = displayFields === null || displayFields === void 0 ? void 0 : displayFields.reduce((activeFields, fieldKey) => {
      // field name override support added to use existing model keys with a new displayField
      const value = model && model[fieldKey];
      const defaultValue = this.modelDefaults[fieldKey];
      // if the value and defaultValue are not the same, push fieldKey to activeFields
      if (!comparer.structural(value, defaultValue)) {
        activeFields.push(fieldKey);
      }
      return activeFields;
    }, []);
    return displayFieldsActive;
  }
  get displayFieldsActiveLength() {
    return this.displayFieldsActive && this.displayFieldsActive.length;
  }
  clearDisplayfields() {
    var _a;
    const model = this.state.model;
    // reset all active display fields
    (_a = this.displayFieldsActive) === null || _a === void 0 ? void 0 : _a.forEach(fieldKey => {
      const defaultValue = this.modelDefaults[fieldKey];
      if (model && fieldKey) {
        model[fieldKey] = defaultValue;
      }
      if (fieldKey === 'hotelName') {
        model.selected = '';
      }
    });
  }
  handleChange(event) {
    if (document && document.dispatchEvent && typeof document.dispatchEvent === 'function' && CustomEvent) {
      document.dispatchEvent(new CustomEvent('mesoSlotFilterUpdate', {
        detail: {
          type: 'slotFilter',
          editType: 'adding',
          filterData: event === null || event === void 0 ? void 0 : event.target
        }
      }));
    }
    // enable submit button after model change
    this.setState({
      submitDisabled: false
    });
  }
  dispatchSubmitEvent() {
    var _a;
    const event = new Event('submit', {
      cancelable: true
    });
    (_a = this.formRef) === null || _a === void 0 ? void 0 : _a.dispatchEvent(event);
  }
  handleSubmit(event) {
    var _a;
    const {
      onUpdateResults
    } = this.props;
    if (this.essAnalyticsLogger) {
      this.essAnalyticsLogger.logWizardSubmitEvent();
    }
    this.setState({
      submitDisabled: true
    });
    const errors = [];
    this.validators.forEach(validator => {
      const error = validator();
      if (error) {
        errors.push(error);
      }
    });
    if (errors.length > 0) {
      if (event && typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      const firstFocusableError = errors.find(error => error.focus);
      if (firstFocusableError) {
        firstFocusableError.focus();
      }
      this.setState({
        submitDisabled: false
      });
      if (logger && typeof logger.logEvent === 'function') {
        logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'SEARCH_FORM_HANDLE_SUBMIT_ERROR'), JSON.stringify(errors));
      }
    } else {
      (_a = this.props.analytics) === null || _a === void 0 ? void 0 : _a.resetAnalyticsForNewPage();
      if (typeof onUpdateResults === 'function') {
        onUpdateResults(event, this.state.model);
      }
    }
  }
  render() {
    var _a;
    const {
      displayFields,
      multiItem
    } = this.props;
    const {
      model,
      submitDisabled
    } = this.state;
    const packageType = (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _a === void 0 ? void 0 : _a.packageType;
    const defaultSubmitButtonProps = {
      submitDisabled
    };
    return React.createElement(Form, {
      method: "GET",
      action: "/Hotel-Search",
      "data-stid": "sidebar-search-form",
      noValidate: true,
      wrappedFormRef: this.getFormRef,
      onSubmit: this.handleSubmit
    }, React.createElement(Experiment, {
      name: "HoB_SQM_AA_TEST_1"
    }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
      bucket: 1
    })), React.createElement(Experiment, {
      name: "HoB_SQM_AA_Test_2"
    }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
      bucket: 1
    })), React.createElement(EGDSLayoutGrid, Object.assign({}, getGridProps(multiItem)), displayFields && displayFields.length > 0 && displayFields.map(field => {
      const displayField = React.createElement(DisplayField, {
        key: `displayField-${field}`,
        field: field,
        model: model,
        validators: this.validators,
        handleChange: this.handleChange
      });
      return displayField;
    }), this.hiddenFields && this.hiddenFields.length > 0 && this.hiddenFields.map(field => React.createElement(HiddenField, {
      key: `hidden_${field}`,
      field: field,
      model: model,
      validators: this.validators,
      handleChange: this.handleChange
    })), React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, packageType === PackageType.HC && React.createElement(CarPickupDropOffTimeRange, {
      model: this.state.model,
      modelKey: "multiItemCarTimeRange",
      updateModelCallback: this.handleChange,
      validators: this.validators
    }), React.createElement(DefaultSubmitButton, Object.assign({}, defaultSubmitButtonProps)))), React.createElement(ViewMedium, null, React.createElement(React.Fragment, null, React.createElement(DefaultSubmitButton, Object.assign({}, defaultSubmitButtonProps)), packageType === PackageType.HC && React.createElement(CarPickupDropOffTimeRange, {
      model: this.state.model,
      modelKey: "multiItemCarTimeRange",
      updateModelCallback: this.handleChange,
      validators: this.validators
    }))))));
  }
  getFormRef(ref) {
    this.formRef = ref;
  }
};
SearchForm.defaultProps = {
  displayFields: [],
  ignoreFields: [],
  provideDefaultSubmitButton: true,
  l10n: {}
};
__decorate([computed], SearchForm.prototype, "displayFieldsActive", null);
__decorate([computed], SearchForm.prototype, "displayFieldsActiveLength", null);
SearchForm = __decorate([withLocalization, inject('analytics', 'multiItem'), observer], SearchForm);
export { SearchForm };
export const DefaultSubmitButton = ({
  submitDisabled,
  packageType,
  showSubmitButtonIcon
}) => {
  const submitButtonProps = {
    type: 'submit',
    rfrr: 'FORM.SUBMIT',
    linkName: 'Submit search form',
    isFullWidth: true,
    size: EGDSButtonSize.LARGE,
    'data-stid': 'submit-hotel-search'
  };
  const buttonLabel = showSubmitButtonIcon ? React.createElement(EGDSIcon, {
    name: "search"
  }) : React.createElement(LocalizedText, {
    message: "hotels.search.form.submit.label"
  });
  const viewports = [React.createElement(ViewMedium, {
    key: "medium"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null, React.createElement(EGDSText, {
    align: "right"
  }, React.createElement(ButtonTracked, Object.assign({}, submitButtonProps), buttonLabel)))))];
  viewports.push(React.createElement(ViewSmall, {
    key: "small"
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null, React.createElement(ButtonTracked, Object.assign({}, submitButtonProps, {
    disabled: submitDisabled
  }), buttonLabel)))));
  if (packageType === PackageType.FH || packageType === PackageType.FHC) {
    viewports.push(React.createElement(ViewLarge, {
      key: "large"
    }, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null, React.createElement(ButtonTracked, Object.assign({}, submitButtonProps), buttonLabel)))));
  }
  return React.createElement(Viewport, null, viewports);
};
DefaultSubmitButton.displayName = 'DefaultSubmitButton';
export const HiddenField = ({
  field,
  model,
  validators,
  handleChange
}) => {
  /*
    Fields that need processing before it can be added to the form to be submitted
    e.g: rooms:Array<RoomModel> --> adults: string, children: string
    This processing should be delegated to the component itself.
   */
  const fieldKeysNeedMutation = ['rooms'];
  if (fieldKeysNeedMutation.includes(field)) {
    return React.createElement(DisplayField, {
      key: `displayField-${field}`,
      field: field,
      model: model,
      hidden: true,
      validators: validators,
      handleChange: handleChange
    });
  }
  const attributes = {
    type: 'hidden',
    name: field,
    value: model[field] || '',
    disabled: false
  };
  if (attributes.value === '' || typeof attributes.value.length === 'number' && attributes.value.length === 0) {
    attributes.disabled = true;
  }
  return React.createElement("input", Object.assign({}, attributes));
};
HiddenField.displayName = 'HiddenField';
export const DisplayField = ({
  field,
  model,
  validators,
  handleChange,
  isSubmitted,
  fetchDatePickerRates = undefined,
  essAnalyticsLogger = undefined,
  hidden = false
}) => {
  const SearchFormField = fieldMap[field];
  if (!SearchFormField) {
    return null;
  }
  return React.createElement(SearchFormField, {
    model: model,
    modelKey: field,
    validators: validators,
    updateModelCallback: handleChange,
    isSubmitted: isSubmitted,
    fetchDatePickerRates: fetchDatePickerRates,
    essAnalyticsLogger: essAnalyticsLogger,
    hidden: hidden
  });
};
DisplayField.displayName = 'DisplayField';