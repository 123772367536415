import * as React from 'react';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { useExperiment } from '@shared-ui/experiment-context';
import { SWPToggle } from '../../common/swp-toggle';
import { HeaderMessages } from './results-sub-header';
import { ResultsSubHeaderWrapper } from './results-sub-header-wrapper';
export const PointsToggleAndSortOrder = ({
  analyticsSearch,
  justifySelf,
  colEnd,
  isError,
  usePropertyListingQuery,
  localizedText,
  isLoyaltyPWPToggleEnabled,
  searchInputs,
  resultMessages,
  isLoading,
  loyaltyMessagingModel,
  searchCriteria,
  criteriaChanged,
  searchResults
}) => {
  const {
    exposure: gridViewExperimentExposure
  } = useExperiment('SRP_Grid_view_web', false);
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: 5,
    justifySelf: justifySelf,
    colEnd: colEnd,
    alignSelf: (gridViewExperimentExposure === null || gridViewExperimentExposure === void 0 ? void 0 : gridViewExperimentExposure.bucket) ? 'end' : undefined
  }, React.createElement("div", null, !isError && React.createElement(React.Fragment, null, usePropertyListingQuery ? React.createElement(ResultsSubHeaderWrapper, {
    analyticsSearch: analyticsSearch,
    searchInputs: searchInputs,
    searchResults: searchResults,
    localizedText: localizedText,
    switchPositionOfToggleOnEPS: isLoyaltyPWPToggleEnabled,
    criteriaChanged: criteriaChanged
  }) : React.createElement(React.Fragment, null, loyaltyMessagingModel && React.createElement(SWPToggle, {
    model: loyaltyMessagingModel,
    searchCriteria: searchCriteria,
    switchPositionOfToggleOnEPS: isLoyaltyPWPToggleEnabled
  }), React.createElement(HeaderMessages, {
    loading: isLoading,
    localizedText: localizedText,
    resultMessages: resultMessages
  })))));
};
PointsToggleAndSortOrder.displayName = 'PointsToggleAndSortOrder';