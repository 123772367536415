import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ProductType, ProductTypeProvider } from '@shared-ui/retail-product-type-providers';
import { LodgingQueryParamsProvider } from '@shared-ui/retail-search-tools-product';
import { ProductShoppingCriteriaProvider } from '@shared-ui/shopping-search-criteria-providers';
export function withLodgingQueryParamsProvider(WrappedComponent) {
  const LodgingQueryParamsProviderHOC = props => React.createElement(ProductTypeProvider, {
    productType: ProductType.Lodging
  }, React.createElement(ProductShoppingCriteriaProvider, null, React.createElement(LodgingQueryParamsProvider, {
    urlSearchParams: new URLSearchParams(useLocation().search)
  }, React.createElement(WrappedComponent, Object.assign({}, props)))));
  return LodgingQueryParamsProviderHOC;
}