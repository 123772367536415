var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-lines */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { QueryRoute, updateSearch } from 'bernie-core';
import { ErrorBoundary, Layer } from 'bernie-view';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSTypeahead, ESSAdapter, TypeaheadRenderType } from '@egds/react-core/typeahead';
import { withLocalization } from '@shared-ui/localization-context';
import { evaluateAndLogExperiment } from 'stores/utils';
import { getGridItemProps } from '../grid-item-props';
import { FormFieldBinding } from './form-field-binding';
export const RESET_FIELDS = ['poi', 'hotelName', 'group', 'neighborhood']; // fields to reset on location change
export const MULTI_ITEM_REGION_TYPE = 991;
export const MULTI_ITEM_REGION_TYPE_NO_HLR = 735;
// @ts-ignore
let Location = class Location extends React.Component {
  constructor(props) {
    super(props);
    this.pristine = true;
    this.timestamp = this.getCurrentTimestampInSeconds();
    this.dismissTypeahead = this.dismissTypeahead.bind(this);
    this.openTypeahead = this.openTypeahead.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.validate = this.validate.bind(this);
    this.formValidator = this.formValidator.bind(this);
    this.renderTypeaheadDialog = this.renderTypeaheadDialog.bind(this);
    this.fieldRef = React.createRef();
    props.registerValidator && props.registerValidator(this.formValidator);
  }
  renderTypeaheadDialog(children) {
    return React.createElement(QueryRoute, {
      query: {
        pwaDialog: 'typeahead'
      }
    }, React.createElement(Layer, {
      id: "home-typeahead",
      onDismiss: this.dismissTypeahead
    }, children));
  }
  dismissTypeahead(renderType) {
    const {
      analytics,
      history
    } = this.props;
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent('DismissDestinationTypeahead', 'Destination Typeahead Modal Dismissed', null);
    }
    if (renderType === TypeaheadRenderType.DIALOG) {
      history.goBack();
    }
    if (this.fieldRef && this.fieldRef.current && typeof this.fieldRef.current.focusTypeaheadInput === 'function') {
      this.fieldRef.current.focusTypeaheadInput();
    }
  }
  openTypeahead(renderType) {
    const {
      analytics,
      history,
      location
    } = this.props;
    if (renderType === TypeaheadRenderType.DIALOG) {
      updateSearch({
        history,
        location,
        newParams: {
          pwaDialog: 'typeahead'
        }
      });
    }
    if (analytics && typeof analytics.trackEvent === 'function') {
      analytics.trackEvent(':ChangeDestination', 'User searching Destination', null);
    }
  }
  handleUpdate() {
    return selection => {
      var _a, _b, _c, _d, _e, _f, _g;
      const {
        updateModel,
        l10n,
        multiItem
      } = this.props;
      const {
        formatDate
      } = l10n;
      const {
        data,
        term
      } = selection;
      const userHistory = data && data.category === 'USERHISTORY';
      const isMultiItem = multiItem && multiItem.isPackageShoppingPath();
      const regionId = (_a = data === null || data === void 0 ? void 0 : data.regionId) !== null && _a !== void 0 ? _a : '';
      const multiCityRegionId = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.hierarchyInfo) === null || _b === void 0 ? void 0 : _b.airport) === null || _c === void 0 ? void 0 : _c.multicity) !== null && _d !== void 0 ? _d : '';
      const ttlaValue = (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.hierarchyInfo) === null || _e === void 0 ? void 0 : _e.airport) === null || _f === void 0 ? void 0 : _f.airportCode) !== null && _g !== void 0 ? _g : '';
      this.timestamp = this.getCurrentTimestampInSeconds();
      if (typeof updateModel === 'function') {
        this.pristine = false;
        updateModel({
          target: {
            name: 'destination',
            value: term,
            track: true,
            userHistory
          }
        });
        updateModel({
          target: {
            name: 'latLong',
            value: data && data.location ? `${data.location.lat},${data.location.long}` : ''
          }
        });
        updateModel({
          target: {
            name: 'regionId',
            value: isMultiItem && !regionId ? multiCityRegionId : regionId
          }
        });
        if (ttlaValue && isMultiItem) {
          multiItem === null || multiItem === void 0 ? void 0 : multiItem.setTtla(ttlaValue);
        }
        updateModel({
          target: {
            name: 'selected',
            value: data && data.selected ? data.selected : ''
          }
        });
        if (data && data.startDate && data.endDate) {
          updateModel({
            target: {
              name: 'startDate',
              value: formatDate(new Date(data.startDate), {
                raw: 'yyyy-MM-dd'
              })
            }
          });
          updateModel({
            target: {
              name: 'endDate',
              value: formatDate(new Date(data.endDate), {
                raw: 'yyyy-MM-dd'
              })
            }
          });
        }
        RESET_FIELDS.forEach(name => updateModel({
          target: {
            name,
            value: undefined
          }
        }));
      }
    };
  }
  render() {
    var _a, _b, _c, _d, _e, _f;
    const {
      context,
      l10n,
      model,
      multiItem,
      maxTypeaheadResults,
      experiment,
      page
    } = this.props;
    const {
      formatText
    } = l10n;
    const destination = formatText('hotels.search.form.destination.label');
    const isMultiItem = multiItem && multiItem.isPackageShoppingPath();
    const isMultiRegionDisabled = isMultiItem && evaluateAndLogExperiment('PWA_Packages_Exclude_MultiRegion_Destination_Search', context, experiment, page) === 1;
    const defaultEssAdapterConfig = {
      client: 'Hotels.Search',
      dest: true,
      essLogger: this.props.essAnalyticsLogger,
      features: 'ta_hierarchy|postal_code|contextual_ta|google|consistent_display',
      guid: (_a = context === null || context === void 0 ? void 0 : context.deviceId) !== null && _a !== void 0 ? _a : '',
      inputId: 'hotels-destination',
      lob: 'HOTELS',
      locale: (_b = context === null || context === void 0 ? void 0 : context.locale) !== null && _b !== void 0 ? _b : '',
      maxResults: maxTypeaheadResults,
      personalize: true,
      regionType: 2047,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      siteId: ((_c = context === null || context === void 0 ? void 0 : context.site) === null || _c === void 0 ? void 0 : _c.id) || null,
      lastSearchLatLong: model === null || model === void 0 ? void 0 : model.latLong,
      lastSearchRegionId: model === null || model === void 0 ? void 0 : model.regionId,
      lastSearchElapseTime: this.timestamp ? this.getCurrentTimestampInSeconds() - this.timestamp : undefined
    };
    const multiItemEssAdapterConfig = Object.assign(Object.assign({}, defaultEssAdapterConfig), {
      client: 'Packages.Search',
      features: 'nearby_airport|ta_hierarchy|consistent_display',
      lob: 'PACKAGES',
      subLob: isMultiItem ? (_d = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _d === void 0 ? void 0 : _d.packageType : '',
      personalize: false,
      regionType: (model === null || model === void 0 ? void 0 : model.typeaheadRegionType) || (isMultiRegionDisabled ? MULTI_ITEM_REGION_TYPE_NO_HLR : MULTI_ITEM_REGION_TYPE),
      packageType: isMultiItem ? (_e = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _e === void 0 ? void 0 : _e.packageType : ''
    });
    const defaultTypeaheadProps = {
      allowExactMatch: true,
      label: destination,
      icon: 'place',
      invalid: this.validate() && ((_f = this.validate()) === null || _f === void 0 ? void 0 : _f.text),
      name: 'destination',
      onClick: this.openTypeahead,
      onDismiss: this.dismissTypeahead,
      renderDialog: this.renderTypeaheadDialog,
      renderType: TypeaheadRenderType.ADAPTIVE,
      stid: 'hotels-destination',
      value: model && model.destination,
      ref: this.fieldRef,
      onUpdate: this.handleUpdate()
    };
    const adapter = isMultiItem ? new ESSAdapter(Object.assign(Object.assign({}, multiItemEssAdapterConfig), {
      maxResults: multiItemEssAdapterConfig.maxResults || 0
    })) : new ESSAdapter(Object.assign(Object.assign({}, defaultEssAdapterConfig), {
      maxResults: defaultEssAdapterConfig.maxResults || 0
    }));
    return React.createElement(ErrorBoundary, {
      label: "location.typeahead"
    }, React.createElement(EGDSLayoutGridItem, Object.assign({}, getGridItemProps('location', multiItem)), React.createElement("div", null, React.createElement(EGDSTypeahead, Object.assign({}, defaultTypeaheadProps, {
      adapter: adapter
    })))));
  }
  getCurrentTimestampInSeconds() {
    return Math.floor(Date.now() / 1000);
  }
  formValidator() {
    this.pristine = false;
    return this.validate();
  }
  validate() {
    const {
      l10n,
      model
    } = this.props;
    const {
      formatText
    } = l10n;
    if (this.pristine) {
      return;
    }
    const destination = model && model.destination;
    const focus = this.fieldRef && this.fieldRef.current && typeof this.fieldRef.current.focusTypeaheadInput === 'function' && this.fieldRef.current.focusTypeaheadInput;
    if (!destination) {
      return {
        text: formatText('hotels.search.validation.invalidDestination.text'),
        focus
      };
    }
  }
};
Location.defaultProps = {
  maxTypeaheadResults: 10
};
Location = __decorate([withLocalization, withRouter, FormFieldBinding, inject('analytics', 'context', 'multiItem', 'experiment', 'page')], Location);
export { Location };