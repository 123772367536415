import * as React from 'react';
import { ClientLogger } from 'bernie-client';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { NewDateField } from 'src/common/components/common/search-form/fields/new-date-field';
import { useLocalization } from '@shared-ui/localization-context';
import { ViewLarge, Viewport } from '@shared-ui/viewport-context';
import { SearchCriteriaModel } from 'stores/models';
const logger = ClientLogger.getLoggerWithIdentifier('pwa.component.searchform');
const logDateRangeError = (startDateError, endDateError, startDate, endDate) => {
  logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'SEARCH_FORM_PARTIAL_STAY_DATE_RANGE_ERROR'), `${startDateError} | ${endDateError}`, `partialStayStartDate=${startDate} partialStayEndDate=${endDate}`);
};
const isValidDate = date => date && date.toString() !== 'Invalid Date';
const PartialStayDateRange = ({
  model,
  updateModel,
  registerValidator
}) => {
  const {
    formatText
  } = useLocalization();
  const [endDateFocus, setEndDateFocus] = React.useState(undefined);
  const [startDateFocus, setStartDateFocus] = React.useState(undefined);
  registerValidator === null || registerValidator === void 0 ? void 0 : registerValidator(() => validate('startDate'));
  registerValidator === null || registerValidator === void 0 ? void 0 : registerValidator(() => validate('endDate'));
  const validPartialStart = model.partialStayStartDate && isValidDate(new Date(model.partialStayStartDate));
  const validPartialEnd = model.partialStayEndDate && isValidDate(new Date(model.partialStayEndDate));
  const startDate = validPartialStart ? model.partialStayStartDate : model.startDate;
  const endDate = validPartialEnd ? model.partialStayEndDate : model.endDate;
  const handleChange = event => {
    if (typeof updateModel === 'function') {
      updateModel(event);
    }
  };
  const updateModels = (startDate, endDate) => {
    handleChange({
      target: {
        name: 'partialStayStartDate',
        value: startDate
      }
    });
    handleChange({
      target: {
        name: 'partialStayEndDate',
        value: endDate
      }
    });
  };
  const registerFocusMethod = (fieldName, focusMethod) => {
    if (fieldName === 'startDate') {
      setStartDateFocus(focusMethod);
    } else {
      setEndDateFocus(focusMethod);
    }
  };
  // eslint-disable-next-line complexity
  const validate = key => {
    const {
      partialStayStartDate,
      partialStayEndDate,
      startDate,
      endDate,
      isPartialStay
    } = model;
    // Dont validate if partial stay isnt selected
    if (!isPartialStay) {
      return;
    }
    const start = partialStayStartDate ? partialStayStartDate : startDate;
    const end = partialStayEndDate ? partialStayEndDate : startDate;
    if (key === 'startDate' && start && startDate) {
      const partialStart = new Date(start);
      const searchStartDate = new Date(startDate);
      const message = 'hotels.search.validation.invalidFromDate.text';
      if (!isValidDate(partialStart) && !model.isDateless) {
        return {
          text: formatText(message),
          focus: startDateFocus
        };
      }
      searchStartDate.setHours(0, 0, 0, 0);
      partialStart.setHours(0, 0, 0, 0);
      if (partialStart < searchStartDate) {
        return {
          text: formatText('hotels.search.validation.invalidPartialDates.text'),
          focus: endDateFocus
        };
      }
    }
    if (key === 'endDate' && start && end && endDate) {
      const searchEndDate = new Date(endDate);
      const partialStart = new Date(start);
      const partialEnd = new Date(end);
      const message = 'hotels.search.validation.invalidToDate.text';
      if (!isValidDate(partialEnd) && !model.isDateless) {
        return {
          text: formatText(message),
          focus: endDateFocus
        };
      }
      if (isValidDate(partialStart)) {
        if (partialEnd < partialStart) {
          return {
            text: formatText('hotels.search.validation.invalidDateRange.text'),
            focus: endDateFocus
          };
        }
        searchEndDate.setHours(0, 0, 0, 0);
        partialEnd.setHours(0, 0, 0, 0);
        partialStart.setHours(0, 0, 0, 0);
        if (partialEnd > searchEndDate) {
          return {
            text: formatText('hotels.search.validation.invalidPartialDates.text'),
            focus: endDateFocus
          };
        }
      }
    }
  };
  const startDateError = validate('startDate');
  const endDateError = validate('endDate');
  if (logger && typeof logger.logEvent === 'function' && (startDateError || endDateError) && startDate && endDate) {
    logDateRangeError(startDateError, endDateError, startDate, endDate);
  }
  const commonDateFieldProps = {
    startDate,
    endDate,
    onChange: handleChange,
    placeholder: formatText('hotels.search.form.toDate.placeholder'),
    numDaysBookableInAdvance: SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE,
    midnightBookingOffsetHours: SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS,
    buttonText: formatText('hotels.roomPicker.doneButton.label'),
    registerFocusMethod,
    isDateless: false,
    updateModels
  };
  const dateFieldProps = {
    endId: 'hotels-partial-stay-check-out',
    endInvalid: endDateError && endDateError.text,
    endLabel: formatText('hotels.search.form.toDateHyphen.label'),
    endName: 'partialStayEndDate',
    endPlaceholder: formatText('hotels.search.form.toDate.placeholder'),
    onChange: commonDateFieldProps.onChange,
    routerLinkWrapper: children => React.createElement(React.Fragment, null, children),
    startId: 'hotels-partial-stay-check-in',
    startInvalid: startDateError && startDateError.text,
    startLabel: formatText('hotels.search.form.fromDateHyphen.label'),
    startName: 'partialStayStartDate',
    startPlaceholder: formatText('hotels.search.form.toDate.placeholder')
  };
  const rfrr = {
    end: ':ChangePartialDatesCheckOut',
    start: ':ChangePartialDatesCheckIn'
  };
  return model.isPartialStay ? React.createElement(React.Fragment, null, React.createElement(EGDSLayoutGridItem, {
    colSpan: {
      small: 1,
      medium: 2
    }
  }, React.createElement("div", null, React.createElement(NewDateField, Object.assign({
    rfrr: rfrr,
    dateFieldProps: dateFieldProps
  }, commonDateFieldProps)))), React.createElement(Viewport, null, React.createElement(ViewLarge, null, React.createElement(EGDSLayoutGridItem, null, React.createElement("div", null))))) : null;
};
const FormFieldBoundComponent = FormFieldBinding(PartialStayDateRange);
export { FormFieldBoundComponent as PartialStayDateRange };