import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { updateSearch } from 'bernie-core';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSSwitch } from '@egds/react-core/switch';
import { EGDSText } from '@egds/react-core/text';
import { Level, liveAnnounce } from '@egds/react-core/utils';
import { useLocalization } from '@shared-ui/localization-context';
const ToggleSwitchTracked = ClickTracker(EGDSSwitch);
const SWPToggleComponent = observer(({
  searchCriteria,
  forceHistoryPushOnGoBack,
  history,
  location,
  model,
  switchPositionOfToggleOnEPS
}) => {
  const {
    formatText
  } = useLocalization();
  const handleClick = () => {
    updateSearch({
      history,
      location,
      newParams: {
        useRewards: String((searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.useRewards) !== 'true')
      },
      replace: true
    });
    if (forceHistoryPushOnGoBack && typeof forceHistoryPushOnGoBack === 'function') {
      forceHistoryPushOnGoBack();
    }
    if ((searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.useRewards) === 'true') {
      setTimeout(() => liveAnnounce(formatText('common.pointsRemoved'), Level.POLITE), 500);
    } else {
      setTimeout(() => liveAnnounce(formatText('common.pointsApplied'), Level.POLITE), 500);
    }
  };
  if (!model || !model.saveWithPointsActionMessage || !model.saveWithPointsMessage || !searchCriteria) {
    return null;
  }
  const switchPosition = switchPositionOfToggleOnEPS ? 'trailing' : 'leading';
  return React.createElement(EGDSSpacing, {
    padding: {
      block: 'two'
    }
  }, React.createElement("div", {
    "data-stid": "swp-toggle"
  }, React.createElement(EGDSText, {
    size: 300
  }, React.createElement(ToggleSwitchTracked, {
    id: "swpToggle",
    checked: searchCriteria.useRewards === 'true',
    onChange: handleClick,
    rfrr: `swpToggle: ${model.saveWithPointsActionMessage}`,
    linkName: 'rewards toggle',
    switchPosition: switchPosition
  }, model.saveWithPointsMessage))));
});
export const SWPToggle = withRouter(SWPToggleComponent);