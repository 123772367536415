import { ApolloLink } from '@apollo/client';
import { STORE_EVENTS } from 'stores/events/store-events';
import { getParentBrandFromBrand } from 'utils/brand-util';
class PropertyListingQueryApolloAnalyticsLinkClass extends ApolloLink {
  constructor(analytics, logger, analyticsSearch, productLine, brand, experiment) {
    super();
    this.analytics = analytics;
    this.logger = logger;
    this.analyticsSearch = analyticsSearch;
    this.productLine = productLine;
    this.brand = brand;
    this.experiment = experiment;
  }
  request(operation, forward) {
    return setContextHeaders(operation, forward, this.productLine, this.brand).map(response => {
      var _a, _b, _c, _d, _e, _f, _g;
      if ((operation.operationName === 'PropertyListingQuery' || operation.operationName === 'PropertySearchDynamicSearchMapQuery') && response) {
        const analyticsData = (_b = (_a = response.extensions) === null || _a === void 0 ? void 0 : _a.analytics) === null || _b === void 0 ? void 0 : _b[0];
        const shouldFetchSecondaryBatch = (_g = (_f = (_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.propertySearch) === null || _d === void 0 ? void 0 : _d.criteria) === null || _e === void 0 ? void 0 : _e.secondary) === null || _f === void 0 ? void 0 : _f.booleans) === null || _g === void 0 ? void 0 : _g.find(bool => bool.id === 'shouldFetchSecondaryBatch' && bool.value);
        if (analyticsData) {
          this.analyticsSearch.addPayload(analyticsData);
        }
        if (!shouldFetchSecondaryBatch && this.analytics && analyticsData) {
          this.analyticsSearch.publishPayload(this.analytics, this.experiment);
        } else if (!this.analytics || !analyticsData) {
          this.logger.logEvent(STORE_EVENTS.PROPERTY_LISTING_ANALYTICS_PUBLISH_ERROR, 'Unable to publish analytics for property listings due to missing analytics store or data');
        }
      }
      return response;
    });
  }
}
export const setContextHeaders = (operation, forward, productLine, brand) => {
  operation.setContext({
    headers: Object.assign(Object.assign({}, operation.getContext().headers), {
      'x-product-line': productLine,
      'x-parent-brand-id': getParentBrandFromBrand(brand)
    })
  });
  return forward(operation);
};
export const PropertyListingQueryApolloAnalyticsLink = (analytics, logger, analyticsSearch, productLine, brand, experiment) => new PropertyListingQueryApolloAnalyticsLinkClass(analytics, logger, analyticsSearch, productLine, brand, experiment);
export const LODGING_PRODUCT_LINE = 'lodging';
export const GROUND_TRANSPORTATION_PRODUCT_LINE = 'ground-transportation';
export const CRUISE_PRODUCT_LINE = 'cruise';
export const CAR_PRODUCT_LINE = 'car';