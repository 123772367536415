export const onClickScrollToTarget = target => {
  if (target && target.ref && typeof target.ref.scrollIntoView === 'function') {
    target.ref.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  } else if (target && typeof target.scrollTo !== 'undefined') {
    // 0 should be valid!
    window.scrollTo({
      top: target.scrollTo,
      left: 0,
      behavior: 'smooth'
    });
  }
  if (target && target.ref && typeof target.ref.focus === 'function') {
    target.ref.focus();
  }
};