import * as React from 'react';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSTertiaryButton } from '@egds/react-core/button';
import { EGDSLayoutGrid } from '@egds/react-core/layout-grid';
import { EGDSSpacing, EGDSSpacingHr } from '@egds/react-core/spacing';
import { EGDSStepInput } from '@egds/react-core/step-input';
import { EGDSHeading, EGDSText } from '@egds/react-core/text';
import { RoomPickerModel } from 'components/common/room-picker/room-picker-model';
import { ChildrenAges } from './children-ages';
const TertiaryButtonTracked = ClickTracker(EGDSTertiaryButton);
export const MultiItemRoom = ({
  analytics,
  roomIndex,
  headerRef,
  l10n,
  onChildAgeUpdated,
  onRoomAdultsChanged,
  onRoomChildrenChanged,
  onRemoveRoom,
  room,
  selection,
  onInfantAgeUpdated,
  onRoomInfantsChanged
}) => {
  const {
    roomCount
  } = selection;
  const stepInputAnalytics = analytics === null || analytics === void 0 ? void 0 : analytics.setupTracker(':Guest', 'Adjust guest count in change search');
  const handleChildAgeUpdated = (childIndex, target) => onChildAgeUpdated(roomIndex, childIndex, target);
  const handleInfantAgeUpdated = (childIndex, target) => onInfantAgeUpdated(roomIndex, childIndex, target);
  const adultsChanged = event => onRoomAdultsChanged(room, event);
  const childrenChanged = event => onRoomChildrenChanged(room, event);
  const infantsChanged = event => onRoomInfantsChanged(room, event);
  const roomRemoved = () => onRemoveRoom(room);
  const roomIndexHeader = roomIndex + 1;
  const isLastRoom = selection.roomCount === roomIndexHeader;
  const totalGuests = selection.counts.guests;
  const infants = room.children.filter(age => age < 2);
  const children = room.children.filter(age => age >= 2);
  return React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'three'
    }
  }, React.createElement("div", {
    key: `room-${roomCount}-${roomIndex}`,
    "data-stid": "room-count"
  }, React.createElement(EGDSHeading, {
    tag: "h3",
    domRef: isLastRoom ? headerRef : undefined
  }, React.createElement(EGDSText, {
    size: 200,
    weight: "medium"
  }, React.createElement("span", {
    "aria-hidden": "true"
  }, l10n.formatText('hotels.roomPicker.room.title', roomIndexHeader)))), React.createElement(EGDSStepInput, {
    label: l10n.formatText('hotels.roomPicker.adults.text'),
    incrementText: l10n.formatText('hotels.roomPicker.increaseAdults.text', roomCount),
    incrementId: `room-${roomCount}-${roomIndex}-adults-increase`,
    decrementText: l10n.formatText('hotels.roomPicker.decreaseAdults.text', roomCount),
    max: RoomPickerModel.determineMaxGuests(room.adults, totalGuests),
    decrementId: `room-${roomCount}-${roomIndex}-adults-decrease`,
    min: 1,
    value: room.adults,
    onChange: adultsChanged,
    analytics: stepInputAnalytics,
    id: `room-${roomCount}-${roomIndex}-adults`
  }), React.createElement(EGDSStepInput, {
    label: l10n.formatText('hotels.roomPicker.children.text'),
    incrementText: l10n.formatText('hotels.roomPicker.increaseChildren.text', roomCount),
    incrementId: `room-${roomCount}-${roomIndex}-children-increase`,
    decrementText: l10n.formatText('hotels.roomPicker.decreaseChildren.text', roomCount),
    decrementId: `room-${roomCount}-${roomIndex}-children-decrease`,
    description: l10n.formatText('hotels.roomPicker.multiitem.children.caption'),
    max: RoomPickerModel.determineMaxGuests(children.length, totalGuests),
    min: 0,
    value: children.length,
    onChange: childrenChanged,
    analytics: stepInputAnalytics,
    id: `room-${roomCount}-${roomIndex}-children`
  }), children.length > 0 && React.createElement(EGDSLayoutGrid, {
    columns: 2,
    space: "two"
  }, React.createElement("div", null, children.map((childAge, childIndex) => React.createElement(ChildrenAges, {
    childAge: childAge,
    childIndex: childIndex,
    key: childIndex,
    l10n: l10n,
    onChildAgeUpdated: handleChildAgeUpdated,
    room: room,
    roomIndex: roomIndex,
    selectLabel: l10n.formatText('hotels.roomPicker.childAgeInput.label', childIndex + 1),
    isInfant: false,
    minChildAge: 2,
    maxChildAge: 17
  })))), React.createElement(EGDSStepInput, {
    label: l10n.formatText('hotels.roomPicker.multiitem.infants.text'),
    incrementText: l10n.formatText('hotels.roomPicker.multiitem.increaseInfants', roomCount),
    incrementId: `room-${roomCount}-${roomIndex}-infants-increase`,
    decrementText: l10n.formatText('hotels.roomPicker.multiitem.decreaseInfants', roomCount),
    decrementId: `room-${roomCount}-${roomIndex}-infants-decrease`,
    description: l10n.formatText('hotels.roomPicker.multiitem.infants.caption'),
    max: RoomPickerModel.determineMaxGuests(infants.length, totalGuests),
    min: 0,
    value: infants.length,
    onChange: infantsChanged,
    analytics: stepInputAnalytics,
    id: `room-${roomCount}-${roomIndex}-infants`
  }), infants.length > 0 && React.createElement(EGDSLayoutGrid, {
    columns: 2,
    space: "two"
  }, React.createElement("div", null, infants.map((childAge, childIndex) => React.createElement(ChildrenAges, {
    childAge: childAge,
    childIndex: childIndex,
    key: childIndex,
    l10n: l10n,
    onChildAgeUpdated: handleInfantAgeUpdated,
    room: room,
    roomIndex: roomIndex,
    selectLabel: l10n.formatText('hotels.roomPicker.multiitem.infantAgeInput.label', childIndex + 1),
    isInfant: true,
    minChildAge: 0,
    maxChildAge: 1
  })))), roomIndex !== 0 && React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'four'
    }
  }, React.createElement(EGDSText, {
    align: "right"
  }, React.createElement("div", null, React.createElement(TertiaryButtonTracked, {
    "data-stid": "remove-room",
    linkName: "Remove room",
    onClick: roomRemoved,
    rfrr: ":RemoveRoom",
    "aria-label": l10n.formatText('hotels.roomPicker.removeRoomButton.a11yLabel', roomIndexHeader, roomCount)
  }, l10n.formatText('hotels.roomPicker.removeRoomButton.label'))))), React.createElement(EGDSSpacingHr, null)));
};