import * as React from 'react';
import { View, ViewTrackerContext } from './view-tracker-context';
// Mutable object created as an optimization to prevet updates when viewTracker is constantly set, and these fields wouldn't change anyway.
// Update these defaults as needed.
const stableViewTrackerRef = {
  setView: () => null,
  view: View.LISTINGS
};
export const ViewTrackerContextProvider = ({
  children,
  viewTracker
}) => {
  stableViewTrackerRef.setView = viewTracker.setView;
  stableViewTrackerRef.view = viewTracker.view;
  return React.createElement(ViewTrackerContext.Provider, {
    value: stableViewTrackerRef
  }, children);
};