import * as React from 'react';
import { EGDSSelect } from '@egds/react-core/input';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
export const ChildrenAges = ({
  minChildAge,
  maxChildAge,
  childAge,
  childIndex,
  l10n,
  onChildAgeUpdated,
  roomIndex,
  selectLabel,
  isInfant
}) => {
  const options = getChildrenAgeSelectOptions(minChildAge, maxChildAge, l10n);
  // Need access to variables in scope.
  const updatedAges = event => onChildAgeUpdated(childIndex, event.target);
  const elementName = isInfant ? `infant-${roomIndex}-${childIndex}` : `child-${roomIndex}-${childIndex}`;
  const elementId = isInfant ? `infant-age-${childIndex}` : `age-${childIndex}`;
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: 1
  }, React.createElement("div", null, React.createElement(EGDSSelect, {
    label: selectLabel,
    id: elementId,
    name: elementName,
    value: childAge.toString(),
    onChange: updatedAges
  }, options.map(option => React.createElement("option", {
    key: option.value,
    value: option.value
  }, option.name)))));
};
const getChildrenAgeSelectOptions = (minChildAge, maxChildAge, l10n) => {
  const options = [];
  for (let i = minChildAge; i <= maxChildAge; i++) {
    const name = i === 0 ? l10n.formatText('hotels.roomPicker.youngestChildAge.text') : i;
    options.push({
      value: `${i}`,
      name: `${name}`
    });
  }
  return options;
};