import { PackageType } from 'src/common/models/multi-item/multi-item-types';
export const getGridProps = multiItem => {
  if (isFlightPath(getPackageType(multiItem))) {
    return {
      columns: {
        small: 1,
        medium: 2,
        large: 3
      },
      space: 'two'
    };
  } else if (isCarPath(getPackageType(multiItem))) {
    return {
      columns: {
        small: 1,
        medium: 4,
        large: 6
      },
      space: 'two'
    };
  }
};
export const getGridItemProps = (fieldKey, multiItem) => {
  if (isFlightPath(getPackageType(multiItem))) {
    switch (fieldKey) {
      case 'dateRange':
        return {
          colSpan: {
            small: 1,
            medium: 2
          }
        };
      default:
        return {
          colSpan: 1
        };
    }
  } else if (isCarPath(getPackageType(multiItem))) {
    switch (fieldKey) {
      case 'dateRange':
        return {
          colSpan: {
            small: 1,
            medium: 2
          }
        };
      case 'location':
        return {
          colSpan: {
            small: 'all',
            medium: 4,
            large: 2
          }
        };
      default:
        return {};
    }
  }
};
const isFlightPath = packageType => packageType && [PackageType.FH, PackageType.FHC].includes(packageType);
const isCarPath = packageType => packageType && packageType === PackageType.HC;
const getPackageType = multiItem => {
  var _a;
  return (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _a === void 0 ? void 0 : _a.packageType;
};