var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable complexity */
import * as React from 'react';
import { inject } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { PackageType } from 'models/multi-item/multi-item-types';
import { withLocalization } from '@shared-ui/localization-context';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { getGridItemProps } from '../grid-item-props';
import { FormFieldBinding } from './form-field-binding';
import { NewDateField } from './new-date-field';
const logger = ClientLogger.getLoggerWithIdentifier('pwa.component.searchform');
let DateRange = class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endDateFocus: undefined,
      startDateFocus: undefined
    };
    this.handleChange = this.handleChange.bind(this);
    this.registerFocusMethod = this.registerFocusMethod.bind(this);
    this.validate = this.validate.bind(this);
    const {
      registerValidator
    } = props;
    registerValidator(() => this.validate('startDate'));
    registerValidator(() => this.validate('endDate'));
  }
  handleChange(event) {
    const {
      updateModel
    } = this.props;
    if (typeof updateModel === 'function') {
      updateModel(event);
    }
  }
  logDateRangeError(startDateError, endDateError, startDate, endDate) {
    logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'SEARCH_FORM_DATE_RANGE_ERROR'), `${startDateError} | ${endDateError}`, `startDate=${startDate} endDate=${endDate}`);
  }
  updateModels(startDate, endDate) {
    this.handleChange({
      target: {
        name: 'startDate',
        value: startDate
      }
    });
    this.handleChange({
      target: {
        name: 'endDate',
        value: endDate
      }
    });
  }
  render() {
    const {
      l10n,
      model,
      fetchDatePickerRates,
      multiItem
    } = this.props;
    const {
      formatText
    } = l10n;
    const startDate = model.startDate && this.isValidDate(new Date(model.startDate)) ? model.startDate : undefined;
    const endDate = model.endDate && this.isValidDate(new Date(model.endDate)) ? model.endDate : undefined;
    const buttonText = formatText('hotels.roomPicker.doneButton.label');
    const startDateError = this.validate('startDate');
    const endDateError = this.validate('endDate');
    const commonDateFieldProps = {
      startDate,
      endDate,
      placeholder: formatText('hotels.search.form.toDate.placeholder'),
      numDaysBookableInAdvance: SearchCriteriaModel.NUM_OF_DAYS_BOOKABLE_IN_ADVANCE,
      midnightBookingOffsetHours: SearchCriteriaModel.MIDNIGHT_BOOKING_OFFSET_IN_HOURS,
      buttonText,
      registerFocusMethod: this.registerFocusMethod,
      updateModels: this.updateModels.bind(this)
    };
    if (logger && typeof logger.logEvent === 'function' && (startDateError || endDateError)) {
      this.logDateRangeError(startDateError, endDateError, startDate, endDate);
    }
    const routerLinkWrapper = children => React.createElement(React.Fragment, null, children);
    const isFlightInSearch = multiItem && multiItem.getPackageType() && multiItem.getPackageType() !== PackageType.HC;
    const {
      startDateLabel,
      endDateLabel
    } = isFlightInSearch ? {
      startDateLabel: formatText('hotels.searchWizard.multiitem.departing.date'),
      endDateLabel: formatText('hotels.searchWizard.multiitem.returning.date')
    } : {
      startDateLabel: formatText('hotels.search.form.fromDateHyphen.label'),
      endDateLabel: formatText('hotels.search.form.toDateHyphen.label')
    };
    const dateFieldProps = {
      endId: 'hotels-check-out',
      endInvalid: endDateError && endDateError.text,
      endLabel: endDateLabel,
      endName: 'endDate',
      endPlaceholder: formatText('hotels.search.form.toDate.placeholder'),
      routerLinkWrapper,
      startId: 'hotels-check-in',
      startInvalid: startDateError && startDateError.text,
      startLabel: startDateLabel,
      startName: 'startDate',
      startPlaceholder: formatText('hotels.search.form.toDate.placeholder')
    };
    const rfrr = {
      end: ':ChangeDatesCheckOut',
      start: ':ChangeDatesCheckIn'
    };
    return React.createElement(EGDSSpacing, {
      margin: {
        small: {
          block: 'half'
        },
        medium: {
          block: 'unset'
        }
      }
    }, React.createElement(EGDSLayoutGridItem, Object.assign({}, getGridItemProps('dateRange', multiItem)), React.createElement("div", null, React.createElement(NewDateField, Object.assign({
      rfrr: rfrr,
      dateFieldProps: dateFieldProps,
      fetchDatePickerRates: fetchDatePickerRates
    }, commonDateFieldProps)))));
  }
  registerFocusMethod(fieldName, focusMethod) {
    this.setState({
      [`${fieldName}Focus`]: focusMethod
    });
  }
  validate(key) {
    const {
      l10n,
      model
    } = this.props;
    const {
      startDateFocus,
      endDateFocus
    } = this.state;
    const {
      formatText
    } = l10n;
    const {
      endDate,
      startDate
    } = model;
    if (key === 'startDate') {
      const start = startDate && new Date(startDate);
      const message = 'hotels.search.validation.invalidFromDate.text';
      if ((!start || !this.isValidDate(start)) && !model.isDateless) {
        return {
          text: formatText(message),
          focus: startDateFocus
        };
      }
    }
    if (key === 'endDate') {
      const start = startDate ? new Date(startDate) : undefined;
      const end = endDate ? new Date(endDate) : undefined;
      const message = 'hotels.search.validation.invalidToDate.text';
      if ((!end || !this.isValidDate(end)) && !model.isDateless) {
        return {
          text: formatText(message),
          focus: endDateFocus
        };
      }
      if (start && this.isValidDate(start)) {
        const maxEndDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 28);
        if (start && end && end < start) {
          return {
            text: formatText('hotels.search.validation.invalidDateRange.text'),
            focus: endDateFocus
          };
        }
        if (maxEndDate) {
          maxEndDate.setHours(0, 0, 0, 0);
        }
        end === null || end === void 0 ? void 0 : end.setHours(0, 0, 0, 0);
        if (maxEndDate !== null && end && end > maxEndDate) {
          return {
            text: formatText('hotels.search.validation.invalidDateDateRangeLength.text'),
            focus: endDateFocus
          };
        }
      }
    }
  }
  isValidDate(date) {
    return date.toString() !== 'Invalid Date';
  }
};
DateRange = __decorate([withLocalization, FormFieldBinding, inject('currentHotel', 'multiItem', 'experiment')], DateRange);
export { DateRange };