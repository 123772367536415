import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { Header as HeaderBernie } from '@gc/global-components';
import { GlobalNavigationLineOfBusiness } from '__generated__/api/types';
import { adaptSearchCriteria } from 'src/common/stores/adapters/bex-api/common/search-criteria-adapter';
import { useSearchCriteriaContext } from 'src/common/stores/contexts';
import { usePlatformContext } from 'src/common/stores/contexts/platformContext';
import { MarketingChannels } from 'constants/marketing-channels';
export const Header = ({
  className
}) => {
  const searchCriteria = useSearchCriteriaContext();
  const context = usePlatformContext();
  return React.createElement(ErrorBoundary, {
    label: "hotel.bernie.header"
  }, React.createElement(HeaderBernie, {
    className: className,
    lineOfBusiness: GlobalNavigationLineOfBusiness.HOTEL,
    customJourneyCriterias: customJourneyCriterias(searchCriteria),
    marketing: customGoogleMarketingCriteria(context, searchCriteria)
  }));
};
Header.displayName = 'Header';
const customJourneyCriterias = searchCriteria => {
  let customJourneyCriteria;
  const {
    regionId,
    startDate,
    endDate
  } = searchCriteria || {};
  if (Number(regionId)) {
    customJourneyCriteria = [{
      destination: {
        regionId: regionId
      },
      dateRange: {
        startDate: startDate,
        endDate: endDate
      }
    }];
  }
  return customJourneyCriteria;
};
const customGoogleMarketingCriteria = (context, searchCriteria) => {
  var _a, _b, _c, _d, _e, _f;
  const adaptedSearchCriteriaSelections = context && searchCriteria && ((_b = (_a = adaptSearchCriteria(context, searchCriteria)) === null || _a === void 0 ? void 0 : _a.secondary) === null || _b === void 0 ? void 0 : _b.selections);
  const gclid = (_c = adaptedSearchCriteriaSelections === null || adaptedSearchCriteriaSelections === void 0 ? void 0 : adaptedSearchCriteriaSelections.find(selection => selection.id.toLowerCase() === 'gclid')) === null || _c === void 0 ? void 0 : _c.value;
  const mdpdtl = (_d = adaptedSearchCriteriaSelections === null || adaptedSearchCriteriaSelections === void 0 ? void 0 : adaptedSearchCriteriaSelections.find(selection => selection.id.toLowerCase() === 'mdpdtl')) === null || _d === void 0 ? void 0 : _d.value;
  const icmdtl = (_e = adaptedSearchCriteriaSelections === null || adaptedSearchCriteriaSelections === void 0 ? void 0 : adaptedSearchCriteriaSelections.find(selection => selection.id.toLowerCase() === 'icmdtl')) === null || _e === void 0 ? void 0 : _e.value;
  const semcid = (_f = adaptedSearchCriteriaSelections === null || adaptedSearchCriteriaSelections === void 0 ? void 0 : adaptedSearchCriteriaSelections.find(selection => selection.id.toLowerCase() === 'semcid')) === null || _f === void 0 ? void 0 : _f.value;
  let marketingChannelInfo = mdpdtl;
  if (mdpdtl) {
    marketingChannelInfo = `${MarketingChannels.mdpdtl}=${mdpdtl}`;
  }
  if (icmdtl) {
    marketingChannelInfo = `${MarketingChannels.icmdtl}=${icmdtl}`;
  }
  if (semcid) {
    marketingChannelInfo = `${MarketingChannels.semcid}=${semcid}`;
  }
  return {
    metaDistributionPartnersDetails: marketingChannelInfo,
    googleClickIdentifier: gclid
  };
};