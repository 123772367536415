/* eslint-disable max-lines */
import { updateSearch } from 'bernie-core';
import { PropertyFilterAmenity } from 'src/common/__generated__/api';
import { HOUSE_RULES_GROUP } from 'src/common/constants/filters';
import { SortAndFilterOptions } from 'src/common/stores/adapters/bex-api/search/request-fields-adapter';
import { isVrbo } from 'src/common/stores/context-store-utils';
import { DateUtils } from 'src/common/utils/date-utils';
import { getAdultsAndChildrenFromRooms } from 'src/common/utils/room-utils';
import { SortAndFiltersParamsKeys, SortAndFiltersRangeParamKey } from '@shared-ui/retail-sort-and-filter-lodging';
import { evaluateAndLogExperiment } from 'stores/utils';
import { MarketingChannels, MarketingChannelsTypeCodes } from 'constants/marketing-channels';
/* eslint-disable complexity */
export const updateResultsWithNewCriteria = ({
  propertySearchCriteriaInput: criteria
}, location, history, searchResults, context, experiment, l10n, analyticsSearch, analyticsStore) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
  searchResults.clearDefaultRequestFields();
  searchResults.clearPagination();
  searchResults.shouldFetch = true;
  const {
    primary,
    secondary
  } = criteria || {};
  const {
    regionName: destination,
    regionId,
    coordinates,
    pinnedPropertyId
  } = (primary === null || primary === void 0 ? void 0 : primary.destination) || {};
  const dateStrings = (primary === null || primary === void 0 ? void 0 : primary.dateRange) ? getDateStringsFromDateRangeInput(primary.dateRange, l10n) : undefined;
  const startDate = dateStrings === null || dateStrings === void 0 ? void 0 : dateStrings.startDate;
  const endDate = dateStrings === null || dateStrings === void 0 ? void 0 : dateStrings.endDate;
  const adultsAndChildren = (primary === null || primary === void 0 ? void 0 : primary.rooms) ? getAdultsAndChildrenFromRooms(primary.rooms) : undefined;
  const adults = adultsAndChildren === null || adultsAndChildren === void 0 ? void 0 : adultsAndChildren.adults;
  const children = adultsAndChildren === null || adultsAndChildren === void 0 ? void 0 : adultsAndChildren.children;
  const latLong = coordinates ? [coordinates.latitude, coordinates.longitude].join(',') : '';
  const params = new URLSearchParams(location.search);
  const searchParamStartDate = params.get('startDate');
  const searchParamEndDate = params.get('endDate');
  const searchParamRegionId = params.get('regionId');
  const vacationRentalsOnly = params.get('vacationRentalsOnly');
  const flexibility = ((_a = primary === null || primary === void 0 ? void 0 : primary.flexSearchCriteria) === null || _a === void 0 ? void 0 : _a.flexibility) ? getDateFlexibility(primary.flexSearchCriteria.flexibility) : undefined;
  const searchRange = ((_b = primary === null || primary === void 0 ? void 0 : primary.flexSearchCriteria) === null || _b === void 0 ? void 0 : _b.searchRange) ? getSearchRange(primary.flexSearchCriteria.searchRange, l10n) : undefined;
  const flexibleSearchRequirement = (_d = (_c = primary === null || primary === void 0 ? void 0 : primary.flexSearchCriteria) === null || _c === void 0 ? void 0 : _c.flexibleSearchRequirement) !== null && _d !== void 0 ? _d : undefined;
  const overrides = {};
  const hasChangedDestination = regionId !== searchParamRegionId;
  const universalSortFilterKeys = [...Object.values(SortAndFiltersParamsKeys), ...Object.values(SortAndFiltersRangeParamKey)];
  if (isVrbo(context)) {
    if (hasChangedDestination) {
      // eslint-disable-next-line camelcase
      overrides.neighborhood_group = undefined;
    }
    overrides.amenities = getAmenities(searchResults, secondary === null || secondary === void 0 ? void 0 : secondary.selections);
    // eslint-disable-next-line camelcase
    overrides.house_rules_group = getHouseRules(searchResults, secondary === null || secondary === void 0 ? void 0 : secondary.selections);
  } else if (hasChangedDestination) {
    if (vacationRentalsOnly !== 'true') {
      overrides.lodging = undefined;
    }
    overrides.neighborhood = undefined;
    overrides.poi = undefined;
    overrides.hotelName = undefined;
    overrides.amenities = undefined;
    overrides.price = undefined;
    overrides.star = undefined;
    overrides.accessibility = undefined;
    overrides.mealPlan = undefined;
    overrides.paymentType = undefined;
    overrides.travelerType = undefined;
    overrides.rewards = undefined;
    overrides.guestRating = undefined;
    // eslint-disable-next-line camelcase
    overrides.hotel_brand = undefined;
    // eslint-disable-next-line camelcase
    overrides.stay_options_group = undefined;
    // eslint-disable-next-line camelcase
    overrides.multi_neighborhood_group = undefined;
    overrides.bedroomFilter = undefined;
  }
  const filtersFromSelectionOverrides = (_f = (_e = secondary === null || secondary === void 0 ? void 0 : secondary.selections) === null || _e === void 0 ? void 0 : _e.reduce((acc, selection) => {
    if (filterOptionsValueType[selection.id] === 'array') {
      return Object.assign(Object.assign({}, acc), {
        [selection.id]: [selection.value]
      });
    }
    if (filterOptionsValueType[selection.id] === 'value') {
      return Object.assign(Object.assign({}, acc), {
        [selection.id]: selection.value
      });
    }
    return acc;
  }, {})) !== null && _f !== void 0 ? _f : {};
  if (pinnedPropertyId) {
    overrides.regionId = null;
  }
  if (startDate !== searchParamStartDate || endDate !== searchParamEndDate) {
    // setting the total_price property of the newParams object explicitly to undefined so that updateSearch()
    // removes the total_price url parameter. nightly_price should remain unaffected by date changes.
    if (((_h = (_g = experiment === null || experiment === void 0 ? void 0 : experiment.exposures) === null || _g === void 0 ? void 0 : _g.Vrbex_NEW_Price_withTextEntry) === null || _h === void 0 ? void 0 : _h.bucket) === 0) {
      // eslint-disable-next-line camelcase
      overrides.total_price = undefined;
    }
  }
  // EGTnl 52629, if a user changes their destination,
  // the behavior for Vrbo brands has been modified to automatically clear filters & sort
  if (((_k = (_j = experiment === null || experiment === void 0 ? void 0 : experiment.exposures) === null || _j === void 0 ? void 0 : _j.Vrbo_filter_persistence_on_destination_change) === null || _k === void 0 ? void 0 : _k.bucket) === 1 && hasChangedDestination) {
    params.forEach((value, key) => {
      // The goal is to persist the 'house_rules_group' key in the 'overrides' object, but only when its value is 'pets_allowed'.
      // If the value of 'house_rules_group' is anything other than 'pets_allowed', it should not be persisted.
      // All other keys in the 'overrides' object should not be persisted.
      if (universalSortFilterKeys.includes(key)) {
        if (key === 'house_rules_group') {
          if (value === 'pets_allowed') {
            // eslint-disable-next-line camelcase
            overrides.house_rules_group = ['pets_allowed'];
          } else {
            // eslint-disable-next-line camelcase
            overrides.house_rules_group = overrides.house_rules_group.filter(rule => rule === 'pets_allowed');
          }
        } else {
          overrides[key] = undefined;
        }
      }
    });
  }
  if ((_l = searchResults.searchCriteria) === null || _l === void 0 ? void 0 : _l.categorySearch) {
    // EGTnl 54737, if a user changes search criteria, the selected category must be cleared
    overrides.categorySearch = '';
  }
  const newParams = Object.assign(Object.assign({
    destination,
    latLong,
    regionId,
    startDate,
    endDate,
    adults,
    children,
    pwaDialog: null,
    selected: pinnedPropertyId,
    mapBounds: '',
    allowPreAppliedFilters: hasChangedDestination,
    flexibility,
    searchRange,
    flexibleSearchRequirement
  }, overrides), filtersFromSelectionOverrides);
  /*
    HACK: sadly this setTimeout has to be reinstated after it was recently removed. Without this setTimeout, when the search
    form's traveler selector input is updated and submitted on mWeb an incorrect search is fired off (ie. the prior number
    oftravelers searched form). The updateSearch() call made below runs and completes first, properly updating the adults/
    children url params with new values as selected. However, since this bug affects mWeb, the traveler search form input is
    opened as a dialog and when it is submitted, bernie also calls updateSearch() to remove the pwaDialog url parameters added
    by opening the traveler selector input as a dialog. When this bernie updateSearch() call occurs, for some reason the
    location it is passed is stale (or the prior traveler input values) - cause the properly updated url to be reverted to the
    state it was prior to the traveler selector dialog opening. A new search is then triggered, but with incorrect values for
    the number of travelers.
  */
  setTimeout(() => analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analyticsStore, experiment)({
    location,
    history,
    newParams,
    replace: false
  }), 25);
};
/* eslint-enable complexity */
export const getHouseRules = (searchResults, selections) => {
  var _a;
  let rules = (_a = searchResults.searchCriteria) === null || _a === void 0 ? void 0 : _a.house_rules_group;
  if (selections) {
    rules = rules === null || rules === void 0 ? void 0 : rules.filter(rule => rule !== HOUSE_RULES_GROUP.PETS_ALLOWED);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const selectionsHasPets = selections.some(sel => sel.value === String(HOUSE_RULES_GROUP.PETS_ALLOWED));
    if (selectionsHasPets) {
      rules === null || rules === void 0 ? void 0 : rules.push(HOUSE_RULES_GROUP.PETS_ALLOWED);
    }
  }
  return rules;
};
export const getAmenities = (searchResults, selections) => {
  var _a;
  let amenities = (_a = searchResults.searchCriteria) === null || _a === void 0 ? void 0 : _a.amenities;
  if (selections) {
    amenities = amenities === null || amenities === void 0 ? void 0 : amenities.filter(amenity => amenity !== PropertyFilterAmenity.PETS);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const selectionsHasPets = selections.some(sel => sel.value === String(HOUSE_RULES_GROUP.PETS_ALLOWED));
    if (selectionsHasPets) {
      amenities === null || amenities === void 0 ? void 0 : amenities.push(PropertyFilterAmenity.PETS);
    }
  }
  return amenities === null || amenities === void 0 ? void 0 : amenities.filter(Boolean).join();
};
export const getDateStringsFromDateRangeInput = (dateRange, l10n) => {
  if (dateRange === null) {
    return {
      startDate: undefined,
      endDate: undefined
    };
  }
  const {
    checkInDate,
    checkOutDate
  } = dateRange;
  const startDateObj = new Date(checkInDate.year, checkInDate.month - 1, checkInDate.day);
  const endDateObj = new Date(checkOutDate.year, checkOutDate.month - 1, checkOutDate.day);
  return {
    startDate: l10n.formatDate(startDateObj, {
      raw: 'yyyy-MM-dd'
    }),
    endDate: l10n.formatDate(endDateObj, {
      raw: 'yyyy-MM-dd'
    })
  };
};
export const getDateFlexibility = flexibility => flexibility.map(({
  length,
  unit,
  bound
}) => {
  const params = bound ? [length, unit, bound] : [length, unit];
  return params.join('_');
});
export const getSearchRange = (searchRange, l10n) => searchRange.map(({
  start,
  end
}) => {
  const startDate = start && [start.year, start.month, start.day];
  const endDate = end && [end.year, end.month, end.day];
  return `${DateUtils.formatDateStringToIsoDate(startDate.join('-'), 'yyyy-MM-dd', l10n)}_${DateUtils.formatDateStringToIsoDate(endDate.join('-'), 'yyyy-MM-dd', l10n)}`;
});
export const isMetaChannelDefaultDatesSearch = searchQueryParams => {
  var _a, _b, _c, _d;
  const marketingChannelTypeCode = searchQueryParams.get('mctc');
  switch (marketingChannelTypeCode) {
    case MarketingChannelsTypeCodes.TRIVAGO:
      return Boolean((_a = searchQueryParams.get(MarketingChannels.mdpdtl)) === null || _a === void 0 ? void 0 : _a.includes('defdate=1'));
    case MarketingChannelsTypeCodes.TRIPADVISOR:
      return Boolean((_b = searchQueryParams.get(MarketingChannels.icmdtl)) === null || _b === void 0 ? void 0 : _b.includes('defdate.1')) || Boolean((_c = searchQueryParams.get(MarketingChannels.icmdtl.toUpperCase())) === null || _c === void 0 ? void 0 : _c.includes('defdate.1'));
    case MarketingChannelsTypeCodes.GOOGLE_HA:
      return Boolean((_d = searchQueryParams.get(MarketingChannels.mdpdtl.toUpperCase())) === null || _d === void 0 ? void 0 : _d.includes('DDT'));
    default:
      return false;
  }
};
export const isMetaChannel = searchQueryParams => {
  const marketingChannelTypeCode = searchQueryParams.get('mctc');
  switch (marketingChannelTypeCode) {
    case MarketingChannelsTypeCodes.TRIVAGO:
      return Boolean(searchQueryParams.get(MarketingChannels.mdpdtl));
    case MarketingChannelsTypeCodes.TRIPADVISOR:
      return Boolean(searchQueryParams.get(MarketingChannels.icmdtl)) || Boolean(searchQueryParams.get(MarketingChannels.icmdtl.toUpperCase()));
    case MarketingChannelsTypeCodes.GOOGLE_HA:
      return Boolean(searchQueryParams.get(MarketingChannels.mdpdtl)) || Boolean(searchQueryParams.get(MarketingChannels.mdpdtl.toUpperCase()));
    default:
      return false;
  }
};
export const isSEMChannelDefaultDatesSearch = searchQueryParams => searchQueryParams.get(MarketingChannels.semcid) !== null;
export const isAlternateDestinationFeatureGate = (experiment, context) => evaluateAndLogExperiment('Alternate_Destination_Recs_SRP_Lodging_Feature_Gate', context, experiment) === 1;
const filterOptionsValueType = {
  [SortAndFilterOptions.ACCESSIBILITY]: 'array',
  [SortAndFilterOptions.AGENCY_BUSINESS_MODELS]: 'array',
  [SortAndFilterOptions.AMENITIES]: 'array',
  [SortAndFilterOptions.BED_TYPE_GROUP]: 'array',
  [SortAndFilterOptions.BEDROOM_FILTER]: 'array',
  [SortAndFilterOptions.CHAIN]: 'value',
  [SortAndFilterOptions.CLEANLINESS]: 'array',
  [SortAndFilterOptions.COMMISSION_TIERS]: 'array',
  [SortAndFilterOptions.DEALS]: 'array',
  [SortAndFilterOptions.HOTEL_BRAND]: 'array',
  [SortAndFilterOptions.MEAL_PLANS]: 'array',
  [SortAndFilterOptions.REGION_ID]: 'value',
  [SortAndFilterOptions.PAYMENT_TYPE]: 'array',
  [SortAndFilterOptions.POI]: 'value',
  [SortAndFilterOptions.PROPERTY_NAME]: 'value',
  [SortAndFilterOptions.PROPERTY_STYLES]: 'array',
  [SortAndFilterOptions.REVIEW_SCORE]: 'value',
  [SortAndFilterOptions.REWARDS]: 'array',
  [SortAndFilterOptions.STAR]: 'array',
  [SortAndFilterOptions.STAY_OPTIONS_GROUP]: 'array',
  [SortAndFilterOptions.STAY_TYPE]: 'array',
  [SortAndFilterOptions.STRUCTURE_TYPE]: 'array',
  [SortAndFilterOptions.TRAVELER_TYPE]: 'array',
  [SortAndFilterOptions.MULTI_NEIGHBORHOOD_GROUP]: 'array',
  [SortAndFiltersParamsKeys.PROPERTY_STYLE]: 'array',
  [SortAndFiltersParamsKeys.NEIGHBORHOOD]: 'value',
  [SortAndFiltersParamsKeys.MIN_BEDROOMS]: 'value',
  [SortAndFiltersParamsKeys.MIN_BATHROOMS]: 'value',
  [SortAndFiltersParamsKeys.PROPERTY_TYPE]: 'array',
  [SortAndFiltersParamsKeys.LOCATION]: 'array',
  [SortAndFiltersParamsKeys.ACCESSIBILITY_FEATURES]: 'array',
  [SortAndFiltersParamsKeys.BOOKING_OPTIONS]: 'array',
  [SortAndFiltersParamsKeys.DISCOUNTS_GROUP]: 'array',
  [SortAndFiltersParamsKeys.AMENITIES_FACILITIES]: 'array',
  [SortAndFiltersParamsKeys.FREE_CANCELLATION_GROUP]: 'array',
  [SortAndFiltersParamsKeys.HOUSE_RULES]: 'array',
  [SortAndFiltersParamsKeys.NEARBY_ACTIVITIES]: 'array',
  [SortAndFiltersParamsKeys.NEIGHBORHOOD_GROUP]: 'value',
  [SortAndFiltersParamsKeys.PREMIER_HOST]: 'array',
  [SortAndFiltersParamsKeys.PROPERTIES_GOOD]: 'array',
  [SortAndFiltersParamsKeys.PROPERTY_REVIEWS]: 'array',
  [SortAndFiltersParamsKeys.ROOMS_SPACES]: 'value',
  [SortAndFiltersParamsKeys.SAFETY]: 'array',
  [SortAndFiltersParamsKeys.VIRTUAL_TOUR_GROUP]: 'array',
  [SortAndFiltersParamsKeys.PRICING_GROUP]: 'array',
  [SortAndFiltersParamsKeys.PROPERTY_NAME]: 'value',
  [SortAndFiltersParamsKeys.AVAILABLE_FILTER]: 'array'
};