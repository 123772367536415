export const COMPRESSION_BANNER_DISMISS = 'compressionBannerDismiss';
export function isCompressionBannerMessageDismissed() {
  // Check if storage and dismiss exists and return dismiss value
  if (typeof window !== 'undefined' && localStorage) {
    const storedDismiss = localStorage.getItem(COMPRESSION_BANNER_DISMISS);
    if (!storedDismiss) {
      return false;
    }
    return JSON.parse(storedDismiss);
  }
  return false;
}
export function setCompressionBannerDismiss(state) {
  // Check if storage exists
  if (typeof window !== 'undefined' && localStorage) {
    // Update the data in localStorage
    localStorage.setItem(COMPRESSION_BANNER_DISMISS, JSON.stringify(state));
  }
}