import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ClickTracker } from 'bernie-plugin-analytics';
import { EGDSSelect } from '@egds/react-core/input';
import { EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { FormFieldBinding } from 'src/common/components/common/search-form/fields/form-field-binding';
import { ALASKA_SITE_ID, PAL_AU_SITE_ID, PAL_CA_SITE_ID, PAL_US_SITE_ID } from 'src/common/constants/cabin-class-override-by-siteid';
import { useLocalization } from '@shared-ui/localization-context';
const TrackedSelect = ClickTracker(EGDSSelect);
const FlightCabinClass = inject('context')(observer(({
  context,
  modelKey,
  model,
  updateModel
}) => {
  var _a;
  const {
    formatText
  } = useLocalization();
  const preferredClassOptions = React.useMemo(() => {
    var _a;
    switch ((_a = context === null || context === void 0 ? void 0 : context.site) === null || _a === void 0 ? void 0 : _a.id) {
      case ALASKA_SITE_ID:
        return [{
          label: formatText('hotels.preferredClass.multiitem.dialog.economy.label'),
          value: 'COACH'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.firstClass.label'),
          value: 'FIRST'
        }];
      case PAL_US_SITE_ID:
        return [{
          label: formatText('hotels.preferredClass.multiitem.dialog.economy.label'),
          value: 'COACH'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.premiumEconomy.label'),
          value: 'PREMIUM_ECONOMY'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.business.label'),
          value: 'BUSINESS'
        }];
      case PAL_CA_SITE_ID:
        return [{
          label: formatText('hotels.preferredClass.multiitem.dialog.economy.label'),
          value: 'COACH'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.premiumEconomy.label'),
          value: 'PREMIUM_ECONOMY'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.business.label'),
          value: 'BUSINESS'
        }];
      case PAL_AU_SITE_ID:
        return [{
          label: formatText('hotels.preferredClass.multiitem.dialog.economy.label'),
          value: 'COACH'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.premiumEconomy.label'),
          value: 'PREMIUM_ECONOMY'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.business.label'),
          value: 'BUSINESS'
        }];
      default:
        return [{
          label: formatText('hotels.preferredClass.multiitem.dialog.economy.label'),
          value: 'COACH'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.premiumEconomy.label'),
          value: 'PREMIUM_ECONOMY'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.business.label'),
          value: 'BUSINESS'
        }, {
          label: formatText('hotels.preferredClass.multiitem.dialog.firstClass.label'),
          value: 'FIRST'
        }];
    }
  }, [(_a = context === null || context === void 0 ? void 0 : context.site) === null || _a === void 0 ? void 0 : _a.id, formatText]);
  return React.createElement(EGDSLayoutGridItem, {
    colSpan: 1
  }, React.createElement("div", null, React.createElement(TrackedSelect, {
    id: modelKey,
    name: modelKey,
    label: formatText('hotels.preferredClass.multiitem.label'),
    icon: "airline_seat_recline_extra",
    onChange: updateModel,
    value: model.cabinClass,
    rfrr: "CabinClass",
    linkName: "Select flight cabin class"
  }, preferredClassOptions.map(option => React.createElement("option", {
    value: option.value,
    key: option.label
  }, option.label)))));
}));
const FormFieldBoundComponent = FormFieldBinding(FlightCabinClass);
export { FormFieldBoundComponent as FlightCabinClass };