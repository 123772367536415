import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updateSearch } from 'bernie-core';
import { useStores } from 'src/common/stores/use-stores';
import { CustomerNotification, CustomerNotificationLocation } from '@shared-ui/customer-notifications';
const handleClick = (history, location) => isChecked => {
  updateSearch({
    history,
    location,
    newParams: {
      useRewards: String(isChecked)
    },
    replace: true
  });
};
export const CustomerNotifications = React.memo(props => {
  var _a;
  const {
    page,
    context,
    searchResults
  } = useStores();
  const {
    location: notificationLocation,
    regionId,
    startDate,
    endDate,
    spacingProps
  } = props;
  const location = useLocation();
  const history = useHistory();
  const historyLocation = history.location;
  const searchCriteria = searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria;
  const useRewards = Boolean(searchCriteria && searchCriteria.useRewards === 'true');
  const customerNotificationInputs = React.useMemo(() => Number(regionId) ? {
    notificationLocation,
    xPageId: page.pageId,
    optionalContext: {
      journeyCriterias: [{
        destination: {
          regionId: regionId
        },
        dateRange: {
          start: startDate,
          end: endDate
        }
      }]
    }
  } : {
    notificationLocation,
    xPageId: page.pageId
  }, [notificationLocation, page.pageId, regionId, startDate, endDate]);
  if (!page || !page.pageId) {
    return null;
  }
  let referer;
  if ((_a = context === null || context === void 0 ? void 0 : context.site) === null || _a === void 0 ? void 0 : _a.domain) {
    const {
      domain
    } = context.site;
    const search = location.search || '';
    const path = location.pathname || '';
    referer = `https://${domain}${path}${search}`;
  }
  // refactored to allow custom margin and padding props to be set and passed through via the spacingProps object
  // or set in the <CustomerNotification spacingProps={{ margin... }} component itself
  // Example of customSpacingProps object can be found in src/common/components/search/property-search-listings/property-search-listings.tsx:
  if (notificationLocation === CustomerNotificationLocation.IN_PAGE) {
    return React.createElement("div", {
      "data-stid": "one-key-customer-notification"
    }, React.createElement(CustomerNotification, {
      inputs: {
        xPageId: page.pageId,
        notificationLocation,
        optionalContext: {
          referer
        }
      },
      skipSsr: true,
      switchHandler: handleClick(history, historyLocation),
      useRewards: useRewards,
      spacingProps: spacingProps
    }));
  }
  return React.createElement("div", {
    "data-stid": "customer-notification"
  }, React.createElement(CustomerNotification, {
    inputs: customerNotificationInputs,
    spacingProps: {
      margin: {
        blockstart: 'two'
      }
    }
  }));
});