import { comparer } from 'mobx';
import { isEqual } from 'lodash';
import { merge } from 'bernie-core';
import { i18nKeys } from '@eg-maps/core';
const {
  withoutKeys,
  flattenFields,
  allKeys
} = merge;
export const calculateNewMapInputs = (prevMapInputs, newMapInputs) => {
  var _a, _b, _c, _d, _e, _f, _g;
  // Comparing all non-destination fields
  const [prevMapInputsWithoutDestination, newMapInputsWithoutDestination] = [withoutKeys(untrackedDestinationKeys).merge({}, flattenFields(prevMapInputs)), withoutKeys(untrackedDestinationKeys).merge({}, flattenFields(newMapInputs))];
  if (!isEqual(prevMapInputsWithoutDestination, newMapInputsWithoutDestination)) {
    return newMapInputs;
  }
  // Comparing all destination fields
  const [prevDestination, newDestination] = [(_a = prevMapInputs === null || prevMapInputs === void 0 ? void 0 : prevMapInputs.criteria) === null || _a === void 0 ? void 0 : _a.primary.destination, (_b = newMapInputs.criteria) === null || _b === void 0 ? void 0 : _b.primary.destination];
  const coordsAreEqual = comparer.structural(allKeys().merge({}, (_c = prevDestination === null || prevDestination === void 0 ? void 0 : prevDestination.coordinates) !== null && _c !== void 0 ? _c : {}), allKeys().merge({}, (_d = newDestination === null || newDestination === void 0 ? void 0 : newDestination.coordinates) !== null && _d !== void 0 ? _d : {}));
  const areRegionNamesDifferent = (prevDestination === null || prevDestination === void 0 ? void 0 : prevDestination.regionName) !== (newDestination === null || newDestination === void 0 ? void 0 : newDestination.regionName);
  const isNewRegionIdAvailable = ((_g = (_f = (_e = newMapInputs.criteria) === null || _e === void 0 ? void 0 : _e.primary) === null || _f === void 0 ? void 0 : _f.destination) === null || _g === void 0 ? void 0 : _g.regionId) !== null;
  /*
    1)We only want to initialize a render whenever the regionId is calculated by property listing
    to prevent the map from re-rendering twice on map pan.
       2)Due to the way dialog context and the universal search form compete to update the URL we should only render
    the map whenever we see that the regionNames are different; preventing the double render of the map caused by the universal search form's destination input changing.
       3)Third party links might hit SRP without regionId or regionName. Shared-ui query migration doesn't add regionId to URL
  */
  if (coordsAreEqual && (isNewRegionIdAvailable || areRegionNamesDifferent)) {
    return prevMapInputs;
  }
  return newMapInputs;
};
export const untrackedDestinationKeys = ['criteria_primary_destination_regionName', 'criteria_primary_destination_regionId', 'criteria_primary_destination_coordinates', 'criteria_primary_destination_pinnedPropertyId', 'criteria_primary_destination_propertyIds', 'criteria_primary_destination_mapBounds', 'criteria_primary_destination_coordinates_latitude', 'criteria_primary_destination_coordinates_longitude'];
export const checkPropertyClusterLabelKey = (key, l10n, ...args) => key === i18nKeys.defaultClusterPropertyLabel ? l10n.formatText('egmaps.markers.cluster.available.text', args[0]) : undefined;