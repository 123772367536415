import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { packageTypeMap } from 'src/common/stores/adapters';
import { useStores } from 'src/common/stores/use-stores';
import { getPropertySearchCriteriaFromQueryParams } from '@shared-ui/retail-search-tools-product';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
export const useSortAndFilterInput = urlQueryString => {
  var _a, _b, _c, _d, _e;
  const [prevSearchId, setPrevSearchId] = useState('');
  const {
    multiItem,
    analyticsSearch,
    searchResults
  } = useStores();
  const history = useHistory();
  const location = useLocation();
  const queryInputs = useProductShoppingCriteria();
  const {
    property
  } = (_a = queryInputs === null || queryInputs === void 0 ? void 0 : queryInputs.primarySearchCriteria) !== null && _a !== void 0 ? _a : {};
  const categorySearch = (_b = searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria) === null || _b === void 0 ? void 0 : _b.categorySearch;
  const getCriteria = useMemo(() => {
    var _a, _b, _c;
    if (!((_a = queryInputs === null || queryInputs === void 0 ? void 0 : queryInputs.primarySearchCriteria) === null || _a === void 0 ? void 0 : _a.property)) {
      return null;
    }
    const {
      secondaryCriteria
    } = queryInputs;
    const showFilterNotification = new URLSearchParams(location.search).get('showFilterNotification');
    const allowPreAppliedFiltersParam = new URLSearchParams(location.search).get('allowPreAppliedFilters');
    const shouldAllowPreAppliedFilters = allowPreAppliedFiltersParam === 'true' || allowPreAppliedFiltersParam === null;
    const shouldShowFilterNotification = showFilterNotification === 'true';
    const selectionsWithoutSearchId = (_b = secondaryCriteria === null || secondaryCriteria === void 0 ? void 0 : secondaryCriteria.selections) === null || _b === void 0 ? void 0 : _b.filter(selection => selection.id !== 'searchId');
    const selections = [...(selectionsWithoutSearchId !== null && selectionsWithoutSearchId !== void 0 ? selectionsWithoutSearchId : []), {
      id: 'searchId',
      value: analyticsSearch.searchId
    }, ...(categorySearch ? [{
      id: 'categorySearch',
      value: categorySearch
    }] : [])];
    const booleans = [...((_c = secondaryCriteria === null || secondaryCriteria === void 0 ? void 0 : secondaryCriteria.booleans) !== null && _c !== void 0 ? _c : []), {
      id: 'allowPreAppliedFilters',
      value: shouldAllowPreAppliedFilters
    }, {
      id: 'showFilterNotification',
      value: shouldShowFilterNotification
    }];
    return {
      primary: queryInputs.primarySearchCriteria.property,
      secondary: Object.assign(Object.assign({}, queryInputs.secondaryCriteria), {
        selections,
        booleans
      })
    };
  }, [analyticsSearch.searchId, (_c = queryInputs === null || queryInputs === void 0 ? void 0 : queryInputs.primarySearchCriteria) === null || _c === void 0 ? void 0 : _c.property, location.search]);
  const [inputs, setInputs] = useState({
    destination: (_d = property === null || property === void 0 ? void 0 : property.destination) !== null && _d !== void 0 ? _d : {},
    criteria: getCriteria,
    shoppingContext: {
      multiItem: getMultiItem(multiItem, packageTypeMap)
    }
  });
  const filterSheetFooterInputs = useMemo(() => {
    var _a, _b;
    const {
      propertySearchCriteriaInput
    } = getPropertySearchCriteriaFromQueryParams(new URLSearchParams(urlQueryString === '' ? history.location.search : urlQueryString));
    const selections = [...((_b = (_a = propertySearchCriteriaInput === null || propertySearchCriteriaInput === void 0 ? void 0 : propertySearchCriteriaInput.secondary) === null || _a === void 0 ? void 0 : _a.selections) !== null && _b !== void 0 ? _b : []), {
      id: 'searchId',
      value: analyticsSearch.searchId
    }, ...(categorySearch ? [{
      id: 'categorySearch',
      value: categorySearch
    }] : [])];
    return Object.assign(Object.assign({}, inputs), {
      criteria: Object.assign(Object.assign({}, propertySearchCriteriaInput), {
        secondary: Object.assign(Object.assign({}, propertySearchCriteriaInput === null || propertySearchCriteriaInput === void 0 ? void 0 : propertySearchCriteriaInput.secondary), {
          selections
        })
      })
    });
  }, [urlQueryString, analyticsSearch.searchId, (_e = searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria) === null || _e === void 0 ? void 0 : _e.categorySearch]);
  useEffect(() => {
    var _a;
    if (prevSearchId !== analyticsSearch.searchId) {
      setInputs({
        destination: (_a = property === null || property === void 0 ? void 0 : property.destination) !== null && _a !== void 0 ? _a : {},
        criteria: getCriteria,
        shoppingContext: {
          multiItem: getMultiItem(multiItem, packageTypeMap)
        }
      });
      setPrevSearchId(analyticsSearch.searchId);
    }
  }, [analyticsSearch.searchId]);
  return {
    inputs,
    filterSheetFooterInputs
  };
};
const getMultiItem = (multiItem, packageTypeMap) => {
  var _a;
  if ((_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext) === null || _a === void 0 ? void 0 : _a.id) {
    return {
      id: multiItem.multiItemContext.id,
      packageType: multiItem.multiItemContext.packageType ? packageTypeMap[multiItem.multiItemContext.packageType] : undefined
    };
  }
};