import { useEffect, useMemo, useState } from 'react';
import { comparer } from 'mobx';
import { inject } from 'mobx-react';
import { evaluateAndLogExperiment } from 'src/common/stores/utils';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
import { SearchCriteriaModel } from 'stores/models/search-criteria-model';
import { InfositePageName } from 'constants/pages';
const ADS_HOLD_OUT_TEST_NAME = 'MeSo_HSR_Display_Ad_Hold_Out_AB_Test';
const ADS_HOLD_OUT_FEATURE_GATE_NAME = 'MeSo_HSR_Holdout_Feature_Gate';
const initializeMesoExperiments = (context, experiment, page) => {
  window.meso = window.meso || {};
  window.meso.experiments = window.meso.experiments || [];
  const featureGateBucket = evaluateAndLogExperiment(ADS_HOLD_OUT_FEATURE_GATE_NAME, context, experiment, page);
  const experimentBucket = featureGateBucket ? evaluateAndLogExperiment(ADS_HOLD_OUT_TEST_NAME, context, experiment, page) : 0;
  window.meso.experiments.push({
    type: 'update',
    experiments: {
      // eslint-disable-next-line camelcase
      MeSo_HSR_Holdout_Feature_Gate: featureGateBucket || 0,
      // eslint-disable-next-line camelcase
      MeSo_HSR_Display_Ad_Hold_Out_AB_Test: experimentBucket || 0
    }
  });
};
const buildTargetingEvent = searchCriteria => {
  var _a, _b, _c;
  const {
    regionId,
    startDate,
    endDate,
    adults,
    children,
    star,
    amenities,
    lodging,
    origin,
    cruiseDest
  } = getCorrectSearchContext(searchCriteria);
  const targetingEvent = {
    type: 'update',
    targeting: Object.assign(Object.assign(Object.assign(Object.assign({
      dateEnd: endDate !== null && endDate !== void 0 ? endDate : '',
      dateStart: startDate !== null && startDate !== void 0 ? startDate : '',
      adults,
      kids: children
    }, cruiseDest && {
      cruiseDest: cruiseDest
    }), regionId && {
      dest: regionId
    }), origin && {
      origin
    }), {
      locResolver: 'gaia',
      ha: (_a = amenities === null || amenities === void 0 ? void 0 : amenities.toString()) !== null && _a !== void 0 ? _a : '',
      hpt: (_b = lodging === null || lodging === void 0 ? void 0 : lodging.toString()) !== null && _b !== void 0 ? _b : '',
      sr: (_c = star === null || star === void 0 ? void 0 : star.toString()) !== null && _c !== void 0 ? _c : ''
    })
  };
  return targetingEvent;
};
const getCorrectSearchContext = searchCriteria => {
  var _a, _b, _c, _d, _e;
  if (searchCriteria instanceof SearchCriteriaModel) {
    return searchCriteria;
  }
  const cruiseCriteria = (_a = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.primarySearchCriteria) === null || _a === void 0 ? void 0 : _a.cruise;
  // page defaults to 2 adults and 0 children
  const adults = (_b = cruiseCriteria === null || cruiseCriteria === void 0 ? void 0 : cruiseCriteria.adultCount) !== null && _b !== void 0 ? _b : 2;
  const children = (_c = cruiseCriteria === null || cruiseCriteria === void 0 ? void 0 : cruiseCriteria.childCount) !== null && _c !== void 0 ? _c : 0;
  const cruiseDest = (_d = cruiseCriteria === null || cruiseCriteria === void 0 ? void 0 : cruiseCriteria.destinations) !== null && _d !== void 0 ? _d : null;
  // end and start are objects with year, month, day, need to concatenate them to get the date used in targeting
  const {
    end,
    start
  } = (_e = cruiseCriteria === null || cruiseCriteria === void 0 ? void 0 : cruiseCriteria.departureDateRange) !== null && _e !== void 0 ? _e : {
    end: {},
    start: {}
  };
  const startDate = `${start === null || start === void 0 ? void 0 : start.year}-${start === null || start === void 0 ? void 0 : start.month}-${start === null || start === void 0 ? void 0 : start.day}`;
  const endDate = `${end === null || end === void 0 ? void 0 : end.year}-${end === null || end === void 0 ? void 0 : end.month}-${end === null || end === void 0 ? void 0 : end.day}`;
  // these are the default values for the rest of the fields
  const regionId = '';
  const origin = '';
  const star = '';
  const amenities = '';
  const lodging = '';
  return {
    regionId,
    startDate,
    endDate,
    adults,
    children,
    star,
    amenities,
    lodging,
    origin,
    cruiseDest
  };
};
const removeFiltersFromTargeting = targetingEvent => {
  var _a;
  return Object.assign(Object.assign({}, targetingEvent), {
    targeting: Object.assign(Object.assign({}, (_a = targetingEvent === null || targetingEvent === void 0 ? void 0 : targetingEvent.targeting) !== null && _a !== void 0 ? _a : {}), {
      sr: ''
    })
  });
};
const hasTargetingChanged = (currentTargeting, targetingEvent) => {
  var _a, _b;
  if ((_b = (_a = window === null || window === void 0 ? void 0 : window.meso) === null || _a === void 0 ? void 0 : _a.adblock) === null || _b === void 0 ? void 0 : _b.acceptableads) {
    return !comparer.structural(removeFiltersFromTargeting(currentTargeting), removeFiltersFromTargeting(targetingEvent));
  }
  return !comparer.structural(currentTargeting, targetingEvent);
};
const pushTargetingEvent = targetingEvent => {
  window.meso = window.meso || {};
  window.meso.targeting = window.meso.targeting || [];
  window.meso.targeting.push(targetingEvent);
};
export const MesoScripts = inject('advertising', 'page', 'experiment', 'context', 'searchResults', 'currentHotel')(({
  advertising,
  page,
  experiment,
  context,
  searchResults,
  currentHotel
}) => {
  const [currentTargeting, setCurrentTargeting] = useState(null);
  const productShoppingCriteriaInput = useProductShoppingCriteria();
  const processTargeting = searchCriteria => {
    const targetingEvent = buildTargetingEvent(searchCriteria);
    if (hasTargetingChanged(currentTargeting, targetingEvent)) {
      setCurrentTargeting(targetingEvent);
      pushTargetingEvent(targetingEvent);
    }
  };
  const isPdpPath = useMemo(() => {
    if (!(advertising === null || advertising === void 0 ? void 0 : advertising.triggeredPageName)) return false;
    if (typeof window !== 'undefined') {
      // whenever the page changes, we should send a clear event
      pushTargetingEvent({
        type: 'clear'
      });
    }
    return Object.values(InfositePageName).some(page => page === (advertising === null || advertising === void 0 ? void 0 : advertising.triggeredPageName));
  }, [advertising === null || advertising === void 0 ? void 0 : advertising.triggeredPageName]);
  // Do not perform this on the server side where window does not exist.
  if (typeof window !== 'undefined') {
    useEffect(() => {
      initializeMesoExperiments(context, experiment, page);
    }, [context, experiment, page]);
    if ((page === null || page === void 0 ? void 0 : page.pageName) === 'page.Cruise-Search') {
      useEffect(() => {
        if (productShoppingCriteriaInput) {
          processTargeting(productShoppingCriteriaInput);
        }
      }, [productShoppingCriteriaInput]);
    } else {
      useEffect(() => {
        if (isPdpPath && (currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria)) {
          processTargeting(currentHotel.searchCriteria);
        } else if (searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria) {
          processTargeting(searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria);
        }
      }, [isPdpPath, searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria, currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.searchCriteria]);
    }
  }
  // This component does not render anything to the DOM.
  return null;
});
MesoScripts.displayName = 'MesoScripts';