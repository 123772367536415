import * as React from 'react';
import { Experiment, ExperimentControl, ExperimentVariant } from '@shared-ui/experiment-context';
import { Origin } from './origin';
export const OriginWrapper = props => React.createElement(Experiment, {
  name: "Optimize_Number_Of_Typeahead_Suggestion"
}, React.createElement(ExperimentControl, null, React.createElement(Origin, Object.assign({}, props, {
  maxTypeaheadResults: 10
}))), React.createElement(ExperimentVariant, {
  bucket: 1
}, React.createElement(Origin, Object.assign({}, props, {
  maxTypeaheadResults: 5
}))), React.createElement(ExperimentVariant, {
  bucket: 2
}, React.createElement(Origin, Object.assign({}, props, {
  maxTypeaheadResults: 6
}))));